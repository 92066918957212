.file-table {
  @media (max-width: 1140px) {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }

  width: 100%;
  .file-table-header {
    height: 50%;

  }
  .file-table-row{
    height:40px;
    .check-box{
      text-align: end;
    }
    .file-name {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 40px;
      .image-wrapper {
        display: flex;
        width: 50px;
        height: 20px;
        .zoom-image {
          width: 50px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
    .remove-file {
      background-color: #FC544B;
      border: none;
      border-radius: 4px;
      .fas {
        color:#FFFFFF;
        height: 20px;
        width: 30px;
        border: none;
      }
    }
    .note-file {
      background-color: #0E72ED;
      border: none;
      margin-right: 5px;
      border-radius: 4px;
      .fas{
        color:#FFFFFF;
        height: 20px;
        width: 30px;
        border: none;
      }
    }
    .link-file {
      background-color: #EFEFEF;
      border: none;
      margin-right: 5px;
      border-radius: 4px;
      .fas {
        color:#dddddd;
        height: 20px;
        width: 30px;
        border: none;
      }
    }
    .time,.size{
        color: #999;
        font-size: 16px;
      //@media (max-width: 768px) {
      //  font-size: 8px;
      //}
    }

  }

}





.folder-icon {
  font-size: 20px;
  color: #ccc;
}


.file-actions {
  // Styles for actions buttons
}

.remove-file {
  background-color: #FC544B;
  border: none;
  border-radius: 4px;
}

.note-file {
  background-color: #0E72ED;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}

.link-file {
  background-color: #EFEFEF;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}

.file-time {
  color: #999;
  font-size: 16px;
}

.file-size {
  color: #999;
  font-size: 16px;
}
