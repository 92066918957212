.TransferTable .ant-modal-content {
  width: 600px;
  padding: 10px 30px 20px;
  margin: auto;
  position: relative;
}
.TransferTable .header {
  font-size: 24px;
  color: #0e72ed;
}
.TransferTable .line {
  height: 1px;
  background-color: #E9F4FF;
}
.TransferTable .line2 {
  height: 1px;
  background-color: #E9F4FF;
  margin-top: 30px;
}
.TransferTable .transfer-table-content .transfer-table-content__input {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.TransferTable .transfer-table-content .transfer-table-content__input .transfer-title {
  font-size: 16px;
}
.TransferTable .transfer-table-content .transfer-table-content__input .ant-input-outlined {
  height: 40px;
}
.TransferTable .transfer-table-content .transfer-table-content__input .ant-select-single {
  height: 40px;
}
.TransferTable .transfer-table-content .transfer-table-content__input .ant-select-single .ant-select-selection-search-input {
  height: 40px;
}
.TransferTable .ant-modal-footer {
  margin-top: 20px;
  display: flex;
  text-align: center;
  align-items: center;
}
.TransferTable .ant-modal-footer button {
  width: 50%;
}
.TransferTable .ant-modal-footer .ant-btn-default {
  height: 50px;
  border-radius: 10px;
  background-color: #BABABA;
  color: #FFFFFF;
  font-size: 20px;
}
.TransferTable .ant-modal-footer .ant-btn-default:hover {
  background-color: #9e9e9e;
  color: #f0f0f0;
}
.TransferTable .ant-modal-footer .ant-btn-primary {
  height: 50px;
  border-radius: 10px;
  background-color: #0e72ed;
  font-size: 20px;
}
.TransferTable .ant-modal-footer .ant-btn-primary:hover {
  background-color: #0056b3;
  color: #e6f7ff;
}
.TransferTable .ant-modal .ant-modal-close {
  top: -31px;
  right: 8px;
}

