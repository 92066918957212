.FormPaymentSettings{
    background-color: #FFF;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    &-switch{
        margin-left: 4px;
      h1{
        color: #494C5D;
        font-weight: 500;
        font-size: 14px;
      }
    }
    .updateForm{
        width: 120px;
        padding: 12px 6px;
        border-radius: 8px;
        background: #0E72ED;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.2800000012px;
        text-align: center;
        color: #FFFFFF;
        border: unset;
        margin-top: 20px;
    }
    :where(.css-dev-only-do-not-override-14i19y2).ant-select-single .ant-select-selector .ant-select-selection-search{
      left: 0;
  }
}