@import "../../../../public/assets/css/variables.scss";
.btnAction {
    gap: 18px;
    padding-top: 25px;
    button {
        outline: none;
        border: none;
        box-shadow: 0 2px 6px #acb5f6;
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        padding: 0.3rem 0.8rem;
        letter-spacing: 0.5px;
        &:hover {
            background-color: #394eea;
            border-color: transparent;
        }
    }
}

.print {
    width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 25px;
    padding: 38px;
    border: 1px solid #ccc;
    background-color: #fff;
    .info {
        width: 100%;
        ul {
            list-style-type: none;
        }
    }

    table {
        width: 100%;
        tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 8px 0;
        }
    }
}

.printWrapper {
    height: 100vh;
}
