.LayoutRefundOrder{
    background-color: #FFF;
    .title{
        line-height: 44px;
        font-size: 25px;
        font-weight: 600;
      }
      &-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f4f6f9;
        padding: 12px 32px;
        margin-bottom: 16px;
      }
      &-name{
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0;
        color: #061020;
      }
      &-back{
        background-color: #FFF;
        padding: 10px 14px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            margin-top: 2px;
        }
      }
      .filter-search{
        width: 310px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        input{
          width: calc(100% - 36px);
          border: unset;
          border: unset;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: -0.2800000011920929px;
          text-align: left;
          color: #061020;
          padding-left: 10px;
          &:focus{
            outline: unset;
          }
        }
        button{
          width: 36px;
          background: #0E72ED;
          height: 36px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
        }
    }
    &-body{
        height: calc(100vh - 228px);
        padding: 8px 16px;
    }
}