.RefundOrderDetailsPage{
    .table-responsive{
        padding: 0px;
    }
    &-container{
        height: 100%;
        display: flex;
    }
    &-orders{
        width: calc(100% - 520px);
        height: 100%;
        .heightTable{
            height: calc(100% - 67.5px);
            overflow: auto;
        }
    }
    .code_order{
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        text-align: left;
        color: #000000;
        margin-bottom: 2px;
    }
    .name_customer{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #BABABA;
        margin-bottom: 8px;
    }
    .price{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: #6D727C;
        margin-bottom: 0px;
    }
    .textSuccess {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.4px;
        text-align: center;
        color: #fff;
        padding: 6px 4px;
        border-radius: 8px;
        background: #58C35A;
        display: block;
        width: 110px;
    }
    tbody tr{
        &:hover{
            background: #ECF2F7 !important;
            cursor: pointer;
        }
        &.active{
            background: #ECF2F7 !important;
        }
    }
    .staff{
        img{
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }

      &-thead{
        position: sticky;
        top: 0px;
        left: 0px;
        width: 100%;
        border-bottom: 0px;
    }
}
@media screen and (max-width:1140px){
    .RefundOrderDetailsPage-orders{
        width: calc(100% - 420px);
    }
}