.ConfirmModalCombineTable {
  .ant-modal-content {
    width: 600px;
    height: 410px;
    margin: auto;
    position: relative;

    .ant-modal-header{
      .ant-modal-title{
        margin-left: 15px;
      }
    }

    .ant-modal-body {
      height: 220px;
      .line {
        height: 1px;
        background-color: #E9F4FF;
      }

      .images-container {
        display: flex;
        justify-content: center;
        margin: 40px 0 30px 0;
      }
      .modal-text {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      margin-top: 40px;
      .ant-btn {
        width: 260px;
        height: 52px;
        border-radius: 100px;

      }
      .ant-btn-default {
        background-color: #bababa;
        color: #ffffff;
        font-size: 20px;
      }

      .ant-btn-default:hover {
        background-color: #9e9e9e;
        color: #f0f0f0;
      }

      .ant-btn-primary {
        background-color: #0e72ed;
        color: #ffffff;
        font-size: 20px;
      }

      .ant-btn-primary:hover {
        background-color: #0056b3;
        color: #e6f7ff;
      }

    }
  }
  .ant-modal .ant-modal-close{
    top: -31px ;
    right: 7px ;
  }
}