.AdminPage{
    background-color: #FFF;
    padding: 12px;
    border-radius: 8px;
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (min-width: 400px) and (max-width: 1280px) {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
        }
        .title{
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: #0E72ED ;
            margin-bottom: 0;
        }

        .box-filter{
            display: flex;
            align-items: center;
            column-gap: 12px;
            height: 44px;

            @media (min-width: 400px) and (max-width: 996px) {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                row-gap: 20px;
                height: unset;
            }
            .search{
                display: flex;
                align-items: center;
                height: 44px;
                border: 1px solid #ECF2F7;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 8px;
                width: 300px;
                background-color: #F6F9FC;
                input{
                    width: 100%;
                    border: none;
                    outline: none;
                    font-size: 14px;
                    background-color: #F6F9FC;     
                }
            }
            .ant-select-selection-search-input{
                padding-left: 10px !important;
            }
        }
        .ant-select{
            width: 220px;
            height: 100%;
        }
        .select-status,.select-floor{
            height: 100%;
            .ant-select{
                width: 180px;
                height: 100%;
            }
        }
        .action--add{
            background: #0E72ED;
            border-radius: 8px;
            width: auto;
            display: grid;
            align-items: center;
            border: unset;
            justify-content: center;
            padding: 12px 16px 12px 12px;
            cursor: pointer;
            span{
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000012px;
                text-align: left;
                color: #FFFFFF;
                position: relative;
                padding-left: 26px;
            }
            img{
                position: absolute;
                top: -4px;
                left: 2px;
            }
        }
    }
    &-body{
        &__thead{
            position: sticky;
            top: 0px;
            left: 0px;
            width: 100%;
            background-color: #FFF;
        }
      .table-responsive{
            height: calc(100vh - 100px);
            overflow-y: auto;
        }
        padding-top: 10px;
        .table-responsive{
            height: calc(100vh - 280px);
            overflow-y: auto;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            margin-top: 10px;
            thead{
                z-index: 2;
            }
        }
        .permissionsGroup{
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            background-color: #47c363;
            color: #FFF;
            vertical-align: middle;
            padding: 7px 12px;
            font-weight: 600;
            letter-spacing: .3px;
            border-radius: 6px;
            display: block;
            min-width: 92px;
            max-width: 145px;
        }
        .infoUser{
            display: flex;
            align-items: center;
            column-gap: 8px;
            &-boxImg{
                width: 40px;
                height: 40px;
                img{
                     width: 100%;
                     height: 100%;
                     border-radius: 100%;
                 }
            }
        }
        .infoUser-right{
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            h1{
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.0299999993px;
                text-align: left;
                color: #000000;
                max-width: 250px;
            }
            .phone{
                color: #959393;
                font-size: 14px;
                display: flex;
                align-items: center;
                column-gap: 4px;
            }
            .email{
                font-size: 14px;
                display: flex;
                align-items: center;
                column-gap: 4px;
                max-width: 250px;
            }
        }
        .switch-Status{
            margin-left: 30px;
        }
        .actionUser{
           img{
            cursor: pointer;
           }
        }
    }
    .switch-Status{
        &.disabled{
            pointer-events: none;
        }
    }
}
.popOver-adminPage{
    z-index: 10;
    width: 185px ;
    padding-top: 6px;
    padding-bottom: 6px;
    
    &.hiddenTemp{
        display: none;
    }
    .content-box{
        display: flex;
        align-items: center;
        column-gap: 10px;
       padding: 16px 14px 8px;
       &:last-child{
        padding-bottom: 14px;
       }
       cursor: pointer;
        p{
            color: #061020;
            font-weight: 400;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            border-bottom: 1px solid #ECF2F7;
            padding-bottom: 6px;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 0;
            border-bottom: unset;
            padding-bottom: 0;
        }
        &:hover{
            background-color: #F6F9FC;
            border-radius: 8px;
        }
    }
    .ant-popover-inner{
        padding: 0;
    }
}
@media screen and (max-width:1280px){
    .SidebarSettings-item {
        h1{
            font-size: 14px;
        }
    }
    .AdminPage-header{
        align-items: baseline;
        .box-filter{
            width: 100%;
            gap: 10px;
            height: auto;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            .ant-select{
                width: 100%;
            }
            .search{
                width: 100%;
            }
        }
    }
    .AdminPage-body .table-responsive{
        height: auto;
    }
}
@media screen and (max-width:992px){
    .LayoutSettings {
        .box-sidebar{
            width: 200px;
        }
        .box-body{
            width: calc(100% - 200px);
        }
    }
    .mainContentStoreBranch .header .btn-clear-filter{
        width: 100%;
    }
    .mainContentStoreBranch .header .btn-add-modal{
        width: 100%;
    }
    .AdminPage-body .infoUser-right h1{
        font-size: 14px;
    }
}