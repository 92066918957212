@font-face {
  font-family: "Roboto Mono";
  src: url("../font/Roboto_Mono/RobotoMono-Regular.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mon Bold";
  src: url("../font/Roboto_Mono/RobotoMono-Bold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../font/Roboto_Condensed/RobotoCondensed-Regular.ttf");
  font-style: normal;
}
.success-page .session1 {
  display: flex;
  background: #FFFFFF;
  padding: 14px 32px 14px 32px;
  gap: 20px;
  align-items: center;
  position: relative;
}
.success-page .session1 h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.89px;
  letter-spacing: -1.4400000572px;
  text-align: center;
  color: #061020;
  margin-bottom: 0px;
  line-height: 100%;
}
.success-page .session1 .menupos {
  position: absolute;
  top: 50%;
  right: 38px;
  transform: translate(0, -50%);
}
.success-page .session1 .menupos .contentMenupos {
  position: relative;
}
.success-page .session1 .menupos .contentMenupos button {
  line-height: 100%;
  padding: 0px;
  border: unset;
  background: unset;
  box-shadow: unset;
}
.success-page .session1 .menupos .contentMenupos button:focus {
  background-color: unset !important;
}
.success-page .session1 .menupos .contentMenupos button:hover {
  background-color: unset !important;
}
.success-page .session1 .menupos .contentMenupos button:after {
  content: unset;
}
.success-page .session1 .menupos .contentMenupos button i {
  font-size: 25px;
  color: #000000;
}
.success-page .session1 .menupos .contentMenupos .dropdown-menu {
  top: -23px !important;
  left: -200px !important;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3215686275);
  padding: 24px 20px 32px 20px;
  gap: 16px;
  border-radius: 12px;
  width: 185px;
  transform: unset !important;
}
.success-page .session1 .menupos .contentMenupos .dropdown-menu a {
  padding: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #061020;
  display: block;
  padding-bottom: 16px;
  border-bottom: 1px solid #ECF2F7;
  margin-bottom: 16px;
}
.success-page .session1 .menupos .contentMenupos .dropdown-menu a:hover {
  background: unset;
}
.success-page .session1 .menupos .contentMenupos .dropdown-menu a:last-child {
  padding-bottom: 0px;
  border-bottom: 0px;
  margin-bottom: 0px;
}
.success-page .session2 p {
  font-size: 38px;
  font-weight: 500;
  line-height: 43.7px;
  letter-spacing: -2.2400000095px;
  text-align: center;
  color: #061020;
  margin-bottom: 0px;
  padding: 18px 0px;
}
.success-page .session3 {
  height: calc(100vh - 205.69px);
  display: flex;
  gap: 12px;
}
.success-page .session3 .leftSession3 {
  width: 400px;
}
.success-page .session3 .rightSession3 {
  width: calc(100% - 400px);
  background: #fff;
}
.success-page .session3 .rightSession3 h2 {
  font-size: 38px;
  font-weight: 500;
  line-height: 43.7px;
  letter-spacing: -2.2400000095px;
  text-align: left;
  color: #061020;
  margin: 0px;
  padding: 32px 16px 20px;
}
.success-page .session3 .rightSession3 .print {
  padding: 0px 0px;
}
.success-page .session3 .rightSession3 .print button {
  border: unset;
  width: 100%;
  padding: 12px 0px;
  text-decoration: unset;
  background: #ECF2F7;
  display: block;
  text-align: center;
}
.success-page .session3 .rightSession3 .print button span {
  padding-left: 30px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: left;
  color: #061020;
}
.success-page .session3 .rightSession3 .print button span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.success-page .session3 .rightSession3 .footerRight {
  height: calc(100% - 140.69px);
  display: grid;
  align-items: end;
}
.success-page .session3 .rightSession3 .footerRight a {
  background: #0e72ed;
  text-decoration: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;
}
.success-page .session3 .rightSession3 .footerRight a span {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #FFFFFF;
}
.success-page .session3-debt {
  display: block;
}
.success-page .session3-debt .rightSession3 {
  width: 100%;
  height: 100%;
}

.content-payment-print {
  height: 100%;
  background: #FFFFFF;
  padding: 20px;
}
.content-payment-print .nameStore {
  font-family: "Roboto Mono";
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .addressStore {
  font-family: "Roboto Mono";
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  margin-bottom: 0px;
  color: #000000;
}
.content-payment-print .hotlineStore {
  font-family: "Roboto Mono";
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  margin-bottom: 0px;
  color: #000000;
}
.content-payment-print .nameStore2 {
  color: #000000;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0px;
}
.content-payment-print .time-quay {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.content-payment-print .time-quay .leftTest p {
  font-family: "Roboto Mono";
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  margin-bottom: 0px;
  color: #000000;
}
.content-payment-print .time-quay .rightTest p {
  font-family: "Roboto Mono";
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  margin-bottom: 0px;
  color: #000000;
}
.content-payment-print .titleHd {
  margin-top: 12px;
  font-family: Roboto Mon Bold;
  font-size: 14px;
  line-height: 18.46px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  margin-bottom: 12px;
  color: #000000;
}
.content-payment-print .headerProduct {
  display: flex;
  padding: 4px 0px;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.content-payment-print .headerProduct p {
  font-family: Roboto Mon Bold;
  font-size: 9px;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .headerProduct .dongia {
  width: 30%;
}
.content-payment-print .headerProduct .sl {
  width: 15%;
}
.content-payment-print .headerProduct .ck {
  width: 15%;
}
.content-payment-print .headerProduct .priceTotal {
  width: 40%;
  text-align: right;
}
.content-payment-print .listProduct {
  padding-bottom: 8px;
  border-bottom: 1px dotted #000000;
}
.content-payment-print .listProduct .item {
  padding: 4px 0px;
}
.content-payment-print .listProduct .item .nameProduct {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .listProduct .item .inline {
  display: flex;
}
.content-payment-print .listProduct .item .inline p {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  margin-bottom: 0px;
  color: #000000;
}
.content-payment-print .listProduct .item .inline .text_dongia {
  width: 30%;
}
.content-payment-print .listProduct .item .inline .text_sl {
  width: 15%;
}
.content-payment-print .listProduct .item .inline .text_ck {
  width: 15%;
}
.content-payment-print .listProduct .item .inline .text_priceTotal {
  width: 40%;
  text-align: right;
}
.content-payment-print .contentTotalPrice {
  padding-top: 12px;
  display: grid;
  gap: 4px;
}
.content-payment-print .contentTotalPrice p {
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline1 {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
}
.content-payment-print .contentTotalPrice .inline1 .textLeft {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline1 .textRight {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline2 {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
}
.content-payment-print .contentTotalPrice .inline2 .textLeft {
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline2 .textRight {
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.46px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline3 {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
}
.content-payment-print .contentTotalPrice .inline3 .textLeft {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline3 .textRight {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline4 {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
}
.content-payment-print .contentTotalPrice .inline4 .textLeft {
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .inline4 .textRight {
  font-family: Roboto Mono;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.46px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contentTotalPrice .text {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  color: #000000;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dotted #000000;
}
.content-payment-print .customerOrder {
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dotted #000000;
}
.content-payment-print .customerOrder .textLeft {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .customerOrder .textRight {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contenFooterPrint1 {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dotted #000000;
}
.content-payment-print .contenFooterPrint1 .text1 {
  font-family: Roboto Mon Bold;
  font-size: 12px;
  line-height: 15.83px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contenFooterPrint1 .text2 {
  font-family: Roboto Mon;
  font-size: 9px;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contenFooterPrint2 .text1 {
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: -0.4967532456px;
  text-align: center;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contenFooterPrint2 .inline {
  margin-top: 2px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.content-payment-print .contenFooterPrint2 .inline .textLeft {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: right;
  color: #000000;
  margin-bottom: 0px;
}
.content-payment-print .contenFooterPrint2 .inline .textRight {
  font-family: Roboto Mono;
  font-size: 9px;
  font-weight: 400;
  line-height: 11.87px;
  letter-spacing: -0.4967532456px;
  text-align: left;
  color: #000000;
  margin-bottom: 0px;
}

@media screen and (max-width: 1280px) {
  .success-page .session3 .rightSession3 .print {
    width: 90%;
  }
}
@media screen and (max-width: 992px) {
  .success-page .session3 .rightSession3 h2 {
    font-size: 28px;
  }
  .success-page .session3 .rightSession3 .footerRight {
    height: calc(100% - 170.69px);
  }
}

