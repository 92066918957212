.BoxFilterSessionDetails{
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
        display: flex;
        align-items: center;
        column-gap: 6px;
        .ant-picker{
            padding: 9.5px;
            margin-top: 3px;
        }
    }
    .right{
        .remove-filter{
            background-color: #FFF;
            border: 1px solid rgb(214, 22, 23);;
            color: rgb(214, 22, 23);
            padding: 6px 20px;
            border-radius: 8px;
            cursor: pointer;
             display: flex;
             align-items: center;
             column-gap: 4px;
             img{
              margin-top: 2px;
             }
          }
    }
}