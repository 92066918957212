.modalEditPermission{
    &.disabled-modal{
        pointer-events: none;
    }
    .ant-modal-content{
        width: 409px;
        min-height: 270px;
        margin: auto;
    }
   .ant-select{
         width: 100%;
   }
   .btnCreateModal{
    margin-top: 45px !important; 
   }

   .ant-modal .ant-modal-body form{
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
   }
   :where(.css-dev-only-do-not-override-14i19y2).ant-select-single .ant-select-selector .ant-select-selection-search{
    left: 0;
}
}