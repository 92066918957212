.uploadImage-preview{
    &.disable{
        pointer-events: none;
    }
    .uploadImage-header{
        .action{
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-left{
                display: flex;
                align-items: center;
                column-gap: 10px;
            }
            &-right{
                color: #6C757D;
                font-size: 14px;
                // line-height: 28px;
                margin-bottom: 0;
            }
            .upload{
                width: 100px;
                background: #0E72ED;
                position: relative;
                box-shadow: 0 2px 6px #0E72ED;
                border: none;
                outline: none;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                padding: 6px 12px;
                letter-spacing: .5px;
                border-radius: 6px;
                input{
                    height: 100%;
                    top: 0px;
                    left: 0;
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    cursor: pointer;
                }
                span{
                    margin-left: 5px;
                    color: #FFF;
                    cursor: pointer;
                }
            }
            .create-folders{
                width: 100px;
                position: relative;
                box-shadow: 0 2px 6px #3bb557;
                border: none;
                outline: none;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: #FFF;
                height: 36px;
                background: #3bb557;
                width: 130px;
                letter-spacing: .5px;
                border-radius: 6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                column-gap: 5px;
                
              span{
                display: block;
              }
            }
            .choose-mutiple{
                width: 100px;
                position: relative;
                box-shadow: 0 2px 6px #ffa426;
                border: none;
                outline: none;
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: #FFF;
                height: 36px;
                background: #ffa426;
                width: 90px;
                letter-spacing: .5px;
                border-radius: 6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                column-gap: 5px;
                
              span{
                display: block;
              }
            }
        }
        .uploadImage-path{
            background-color: #f9f9f9;
            font-size: 14px;
            font-weight: 400;
            height: 44px;
            margin-top: 20px;
            border-radius: 2px;
            .ant-breadcrumb {
                line-height: 44px;
            }
            p{
                cursor: pointer;
                &:hover{
                    color: #0E72ED;
                    text-decoration: underline;
                }
                &.active{
                    color: #0E72ED;
                    text-decoration: underline;
                }
            }
            
        }
    }
    .uploadImage-body{
        .listFile{
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 12px;
            margin-top: 10px;
            height: 450px;
            overflow: auto;
            .file-item{
                height: 280px;
                width: auto;
                border: 1px solid #e0e0e0;
                border-radius: 8px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
            
                .uploadImage-check{
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    input{
                        width: 14px;
                        height: 14px;
                        cursor: pointer;
                    }
                }
                .file-boximg{
                    height: 160px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    img{
                        max-width: 100%;
                        max-height: 100%;
                        scale: 1;
                        transition: all 0.2s linear;
                    }
                    img:hover{
                        scale: 1.1;
                        transition: all 0.2s linear;
                    }

                }
              
                .file-body{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #f4f4f4;
                    row-gap: 10px;
                    padding: 10px;
                    height: 120px;
                    .file-name{
                        color: #666;
                        font-weight: 400;
                        margin-bottom: 0;
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 160px;
                    }
                    .file-size,.file-date{
                        color: #999;
                        font-size: 12px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.modalCreateFolder{
    .ant-modal-content{
        width: 420px;
        margin: auto;
    }
    &.disable{
        pointer-events: none;
    }
}