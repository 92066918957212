.SpacesFloromtab-container {
    height: calc(100vh - 86px);
    .HeaderProductInPos .header-container {
        .header-boxLeft {
            gap: 20px;
            .custom-btn {
                height: 35.2px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
    .SpacesFloromtab-body {
        height: calc(100% - 63px);
        .textNoFloRomTab {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #061020;
        }

        .noFloRomTab {
            width: 350px;
            object-fit: cover;
        }

        .listFloors-container {
            border-top: 1px solid #ecf2f7;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: #ecf2f7;
            gap: 1px;
            @media screen and (max-width: 1024px) {
                overflow-x: auto;
                justify-content: left;
            }
            .itemFloors {
                text-align: center;
                padding: 12px 16px 12px 16px;
                background: #fff;
                cursor: pointer;
                span {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 19.36px;
                    letter-spacing: -0.1000000015px;
                    color: #061020;
                    display: block;
                    text-align: center;
                    text-decoration: unset;
                }
                @media screen and (max-width: 1024px) {
                    padding: 12px 26px;
                }
            }
        }

        .listRoom-container {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            padding: 12px 32px;
            overflow-x: auto;
            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000012px;
                text-align: left;
                padding: 12px 16px 12px 16px;
                border-radius: 100px;
                background: #e6e9e8;
                text-decoration: unset;
                display: block;
                cursor: pointer;
            }
            @media screen and (max-width: 1024px) {
                overflow-x: auto;
            }
        }

        .listTable-container {
            width: 100%;
            height: calc(100vh - 260.16px);
            display: flex;
            flex-wrap: wrap;
            // grid-template-columns: repeat(6, 1fr);
            grid-gap: 24px;
            padding: 16px 32px;
            .itemTable {
                display: flex;
                padding: 8px 12px;
                border-radius: 12px;
                height: 180px;
                text-decoration: unset;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 16px;
                cursor: pointer;
                width: 190px;
                position: relative;
                .orderTable {
                    position: absolute;
                    top: -14px;
                    right: -10px;
                    background-color: red;
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-weight: 600;
                }
                .timeOpenTable {
                    color: white;
                    font-weight: 600;
                    font-size: 16px;
                    gap: 4px;
                }
                .title {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 130%;
                    letter-spacing: -2.2400000095px;
                    text-align: center;
                    color: #ffffff;
                    margin-bottom: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 166px;
                }
                .mergeTables {
                    color: #FAFF00;
                    font-weight: 600;
                    font-size: 16px;
                    gap: 4px;
                }
                .quantity-people {
                    text-align: center;
                    margin-bottom: 0px;
                    border-radius: 4px;
                    padding: 4px 12px 4px 12px;
                    background: rgba(109, 114, 124, 0.2);
                    span {
                        color: white;
                    }
                }
            }

            .noTable {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }
    .box-loadingTable{
        display: flex;
        column-gap: 24px;
        .item-loadTable{
            // background-color: rgb(186, 186, 186);
            background-color: rgb(197 197 197);
            display: flex;
            padding: 8px 12px;
            border-radius: 12px;
            height: 180px;
            text-decoration: unset;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
            cursor: pointer;
            width: 190px;
            position: relative;
            &__body{
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }
        }
    }
}
