.separation-container {
    display: flex;
    flex-direction: column;

    .separation-body {
        .separation-title {
            display: flex;
            padding: 8px 20px;
            align-items: center;

            .button-return {
                .ant-btn {
                    background-color: #FFFFFF;
                    width: 120px;
                    height: 45px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    text-align: center;
                    transition: background-color 0.3s;
                }

                .ant-btn:hover {
                    background-color: #0E72ED;
                }
            }

            .title-page {
                width: calc(100% - 186px);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
                font-weight: 500;
                text-align: center;
            }
        }

        .separation-payment {
            height: calc(100vh - 189px);
            display: grid;
            padding: 0px 20px;
            grid-template-columns: 1fr 1.3fr;
            gap: 8px;

            .left-content {
                width: 100%;
                background: #fff;
                overflow: auto;

                .item {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px 14px;
                    border-bottom: 1px solid #eee;

                    .item-details {
                        max-width: 70%;
                        .item-name {
                            font-weight: bold;
                            font-size: 16px;
                            color: #000000;
                        }

                        .item-desc {
                            font-size: 14px;
                            color: #666;
                        }
                        p {
                            font-size: 14px;
                            color: #000000;
                        }
                    }

                    .item-price {
                        font-weight: bold;
                        font-size: 16px;
                        align-self: center;
                    }
                }

                .item.selected {
                    background-color: #0E72ED;

                    .item-name, .item-desc, .item-price {
                        color: white;
                    }
                    .item-details p {
                        color: white;
                    }
                }
            }

            .right-content {
                background-color: #FFFFFF;

                .priceTotal {
                    color: #088C3D;
                    font-size: 48px;
                    font-weight: 500;
                    line-height: 55.2px;
                    letter-spacing: -1.4400000572px;
                    text-align: center;
                    padding: 42px 0px;
                    border-bottom: 4px solid #ECF2F7;
                }

                .pay {
                    padding: 0px 12px;

                    .ant-btn {
                        width: 100%;
                        height: 80px;
                        margin-top: 15px;
                        border-radius: 12px;
                        display: flex;
                        align-items: center;
                        background: #0E72ED;
                        justify-content: center;
                        text-decoration: unset;
                        color: #FFFFFF;
                        font-size: 24px;

                        p {
                            font-size: 24px;
                            font-weight: 400;
                        }
                    }

                    .ant-btn:hover {
                        background-color: #0C5FB8;
                    }
                }
            }
        }
    }
}

p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}
