.file-table {
  width: 100%;
}
@media (max-width: 1140px) {
  .file-table {
    overflow-x: auto;
    display: block;
    white-space: nowrap;
  }
}
.file-table .file-table-header {
  height: 50%;
}
.file-table .file-table-row {
  height: 40px;
}
.file-table .file-table-row .check-box {
  text-align: end;
}
.file-table .file-table-row .file-name {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
}
.file-table .file-table-row .file-name .image-wrapper {
  display: flex;
  width: 50px;
  height: 20px;
}
.file-table .file-table-row .file-name .image-wrapper .zoom-image {
  width: 50px;
  height: 20px;
  object-fit: contain;
}
.file-table .file-table-row .remove-file {
  background-color: #FC544B;
  border: none;
  border-radius: 4px;
}
.file-table .file-table-row .remove-file .fas {
  color: #FFFFFF;
  height: 20px;
  width: 30px;
  border: none;
}
.file-table .file-table-row .note-file {
  background-color: #0E72ED;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}
.file-table .file-table-row .note-file .fas {
  color: #FFFFFF;
  height: 20px;
  width: 30px;
  border: none;
}
.file-table .file-table-row .link-file {
  background-color: #EFEFEF;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}
.file-table .file-table-row .link-file .fas {
  color: #dddddd;
  height: 20px;
  width: 30px;
  border: none;
}
.file-table .file-table-row .time, .file-table .file-table-row .size {
  color: #999;
  font-size: 16px;
}

.folder-icon {
  font-size: 20px;
  color: #ccc;
}

.remove-file {
  background-color: #FC544B;
  border: none;
  border-radius: 4px;
}

.note-file {
  background-color: #0E72ED;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}

.link-file {
  background-color: #EFEFEF;
  border: none;
  margin-right: 5px;
  border-radius: 4px;
}

.file-time {
  color: #999;
  font-size: 16px;
}

.file-size {
  color: #999;
  font-size: 16px;
}

