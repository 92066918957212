.main-content-info {
    margin-top: 20px;
    display: grid;
    background: #fff;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    .main-content-info-item {
        padding: 20px;
        .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-bottom: 0px;
        }
        .item-form {
            .ant-form-item {
                .ant-form-item-row {
                    .ant-form-item-control {
                        .ant-form-item-control-input {
                            .ant-form-item-control-input-content {
                                span {
                                    box-shadow: none;
                                    border: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .form-info-user {
        display: grid;
        gap: 30px;
        margin-top: 30px;
        .upload-form {
            margin-bottom: 0px;
        }
    }
}
@media screen and (max-width:992px){
    .main-content-info {
        .main-content-info-item:first-child{
            padding-right: 0px;
        }
        .main-content-info-item:last-child{
            padding-left: 0px;
        }
    }
}