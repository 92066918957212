@import "../../../../../public/assets/css/variables.scss";
.ConfigMail-container{
    background-color: #FFF;
    padding: 12px;
    border-radius: 8px;

    &.disable-screen{
        pointer-events: none;
    }
    .ConfigMail{
        &-body{
            padding-top: 10px;
            .form-configMail{
                display: flex;
                flex-direction: column;
                row-gap: 16px;
            }
            .submitForm{
                font-size: 14px !important;
                padding: 12px 24px 12px 28px !important;
                border-radius: 8px;
                background: #0e72ed;
                font-weight: 500 ;
                color: #fff;
                border: none;
                outline: none;
                margin-top: 10px;
                display: flex;
                justify-content: end;
            }
            .ant-select{
                width: 100%;
                height: 44px;
            }
        }
        &-sendMail{
            display: flex;
            align-items: center;
            column-gap: 10px;
            .ant-select{
                width: 100%;
                height: 44px;
            }
            .ant-select-single .ant-select-selector .ant-select-selection-search{
                padding-left: 10px;
            }
            .btn-send{
                background-color: $primary-color;
                padding: 9px 20px;
                border-radius: 4px;
                cursor: pointer;
                &:hover{
                    opacity: 80%;
                }
                i{
                    color: #FFF;
                }
            }
        }
    }
}