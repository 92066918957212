
//Font mới
@font-face {
  font-family: "Roboto Mono";
  src: url('../font/Roboto_Mono/RobotoMono-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mon Bold";
  src: url('../font/Roboto_Mono/RobotoMono-Bold.ttf');
  font-style: normal;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url('../font/Roboto_Condensed/RobotoCondensed-Regular.ttf');
  font-style: normal;
}
.success-page{
  .session1{
    display: flex;
    background: #FFFFFF;
    padding: 14px 32px 14px 32px;
    gap: 20px;
    align-items: center;
    position: relative;
    h1{
      font-size: 28px;
      font-weight: 600;
      line-height: 33.89px;
      letter-spacing: -1.440000057220459px;
      text-align: center;
      color: #061020;
      margin-bottom: 0px;
      line-height: 100%;
    }
    .menupos{
      position: absolute;
      top: 50%;
      right: 38px;
      transform: translate(0,-50%);
      .contentMenupos{
        position: relative;
        button{
          line-height: 100%;
          padding: 0px;
          border: unset;
          background: unset;
          box-shadow: unset;
          &:focus{
            background-color: unset!important;
          }
          &:hover{
            background-color: unset!important;
          }
          &:after{
            content: unset;
          }
          i{
            font-size: 25px;
            color: #000000;
          }
        }
        .dropdown-menu{
          top: -23px !important;
          left: -200px !important;
          box-shadow: 0px 0px 20px 0px #00000052;
          padding: 24px 20px 32px 20px;
          gap: 16px;
          border-radius: 12px;
          width: 185px;
          transform:unset!important;
          a{
            padding: 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.029999999329447746px;
            text-align: left;
            color: #061020;
            display: block;
            padding-bottom: 16px;
            border-bottom: 1px solid #ECF2F7;
            margin-bottom: 16px;
            &:hover{
              background: unset;
            }
            &:last-child{
              padding-bottom: 0px;
              border-bottom: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .session2{
    p{
      font-size: 38px;
      font-weight: 500;
      line-height: 43.7px;
      letter-spacing: -2.240000009536743px;
      text-align: center;
      color: #061020;
      margin-bottom: 0px;
      padding: 18px 0px;
    }
  }
  .session3{
    height: calc(100vh - 205.69px);
    display: flex;
    gap: 12px;
    .leftSession3{
      width: 400px;

    }
    .rightSession3{
      width: calc(100% - 400px);
      background: #fff;
      h2{
        font-size: 38px;
        font-weight: 500;
        line-height: 43.7px;
        letter-spacing: -2.240000009536743px;
        text-align: left;
        color: #061020;
        margin: 0px;
        padding: 32px 16px 20px;
      }
      .print{
        padding: 0px 0px;
        button{
          border: unset;
          width: 100%;
          padding: 12px 0px;
          text-decoration: unset;
          background: #ECF2F7;
          display: block;
          text-align: center;
          span{
            padding-left: 30px;
            position: relative;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.029999999329447746px;
            text-align: left;
            color: #061020;
            img{
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
        }
      }
      .footerRight{
        height: calc(100% - 140.69px);
        display: grid;
        align-items: end;
        a{
          background: #0e72ed;
          text-decoration: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 160px;
          width: 100%;
          span{
            position: relative;
            font-size: 24px;
            font-weight: 400;
            line-height: 29.05px;
            letter-spacing: -1.440000057220459px;
            text-align: left;
            color: #FFFFFF;
            svg{

            }
          }
        }

      }
    }
  }
  .session3-debt{
    display: block;
    .rightSession3{
      width: 100%;
      height: 100%;
    }
  }
}
.content-payment-print{
  height: 100%;
  background: #FFFFFF;
  padding: 20px;
  .nameStore{
    font-family: 'Roboto Mono';
    font-size: 9px;
    font-weight: 400;
    line-height: 11.87px;
    letter-spacing: -0.4967532455921173px;
    text-align: center;
    color: #000000;
    margin-bottom: 0px;
  }
  .addressStore{
    font-family: 'Roboto Mono';
    font-size: 9px;
    font-weight: 400;
    line-height: 11.87px;
    letter-spacing: -0.4967532455921173px;
    text-align: center;
    margin-bottom: 0px;
    color: #000000;
  }
  .hotlineStore{
    font-family: 'Roboto Mono';
    font-size: 9px;
    font-weight: 400;
    line-height: 11.87px;
    letter-spacing: -0.4967532455921173px;
    text-align: center;
    margin-bottom: 0px;
    color: #000000;
  }
  .nameStore2{
    color: #000000;
    font-size: 9px;
    font-weight: 400;
    line-height: 11.87px;
    letter-spacing: -0.4967532455921173px;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 0px;
  }
  .time-quay{
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    .leftTest{
      p{
        font-family: 'Roboto Mono';
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        margin-bottom: 0px;
        color: #000000;
      }
    }
    .rightTest{
      p{
        font-family: 'Roboto Mono';
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        margin-bottom: 0px;
        color: #000000;
      }
    }
  }
  .titleHd{
    margin-top: 12px;
    font-family: Roboto Mon Bold;
    font-size: 14px;
    line-height: 18.46px;
    letter-spacing: -0.4967532455921173px;
    text-align: center;
    margin-bottom: 12px;
    color: #000000;
  }
  .headerProduct{
    display: flex;
    padding: 4px 0px;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    p{
      font-family: Roboto Mon Bold;
      font-size: 9px;

      line-height: 11.87px;
      letter-spacing: -0.4967532455921173px;
      text-align: left;
      color: #000000;
      margin-bottom: 0px;
    }
    .dongia{
      width: 30%;
    }
    .sl{
      width: 15%;
    }
    .ck{
      width: 15%;
    }
    .priceTotal{
      width: 40%;
      text-align: right;
    }
  }
  .listProduct{
    padding-bottom: 8px;
    border-bottom: 1px dotted #000000;
    .item{
      padding: 4px 0px;
      .nameProduct{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
      .inline{
        display: flex;
        p{
          font-family: Roboto Mono;
          font-size: 9px;
          font-weight: 400;
          line-height: 11.87px;
          letter-spacing: -0.4967532455921173px;
          text-align: left;
          margin-bottom: 0px;
          color: #000000;
        }
        .text_dongia{
          width: 30%;
        }
        .text_sl{
          width: 15%;
        }
        .text_ck{
          width: 15%;
        }
        .text_priceTotal{
          width: 40%;
          text-align: right;
        }

      }
    }
  }
  .contentTotalPrice{
    padding-top: 12px;
    display: grid;
    gap: 4px;
    p{
      margin-bottom: 0px;
    }
    .inline1 {
      display: grid;
      gap: 2px;
      grid-template-columns: 1fr 1fr;
      .textLeft{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
      .textRight{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        color: #000000;
        margin-bottom: 0px;
      }
    }
    .inline2{
      display: grid;
      gap: 2px;
      grid-template-columns: 1fr 1fr;
      .textLeft{
        font-family: Roboto Condensed;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
      .textRight{
        font-family: Roboto Mono;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.46px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        color: #000000;
        margin-bottom: 0px;
      }
    }
    .inline3{
      display: grid;
      gap: 2px;
      grid-template-columns: 1fr 1fr;
      .textLeft{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
      .textRight{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        color: #000000;
        margin-bottom: 0px;
      }
    }
    .inline4{
      display: grid;
      gap: 2px;
      grid-template-columns: 1fr 1fr;
      .textLeft{
        font-family: Roboto Condensed;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.41px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
      .textRight{
        font-family: Roboto Mono;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.46px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        color: #000000;
        margin-bottom: 0px;
      }
    }
    .text{
      font-family: Roboto Mono;
      font-size: 9px;
      font-weight: 400;
      line-height: 11.87px;
      letter-spacing: -0.4967532455921173px;
      text-align: center;
      color: #000000;
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px dotted #000000;
    }
  }
  .customerOrder{
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px dotted #000000;
    .textLeft{
      font-family: Roboto Mono;
      font-size: 9px;
      font-weight: 400;
      line-height: 11.87px;
      letter-spacing: -0.4967532455921173px;
      text-align: left;
      color: #000000;
      margin-bottom: 0px;
    }
    .textRight{
      font-family: Roboto Mono;
      font-size: 9px;
      font-weight: 400;
      line-height: 11.87px;
      letter-spacing: -0.4967532455921173px;
      text-align: right;
      color: #000000;
      margin-bottom: 0px;
    }
  }
  .contenFooterPrint1{
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px dotted #000000;
    .text1{
      font-family: Roboto Mon Bold;
      font-size: 12px;
      line-height: 15.83px;
      letter-spacing: -0.4967532455921173px;
      text-align: center;
      color: #000000;
      margin-bottom: 0px;
    }
    .text2{
      font-family: Roboto Mon;
      font-size: 9px;
      line-height: 11.87px;
      letter-spacing: -0.4967532455921173px;
      text-align: center;
      color: #000000;
      margin-bottom: 0px;
    }
  }
  .contenFooterPrint2{
    .text1{
      font-family: Roboto Condensed;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      letter-spacing: -0.4967532455921173px;
      text-align: center;
      color: #000000;
      margin-bottom: 0px;
    }
    .inline{
      margin-top: 2px;
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;

      .textLeft{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: right;
        color: #000000;
        margin-bottom: 0px;
      }
      .textRight{
        font-family: Roboto Mono;
        font-size: 9px;
        font-weight: 400;
        line-height: 11.87px;
        letter-spacing: -0.4967532455921173px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}
@media screen and (max-width:1280px){
  .success-page .session3 .rightSession3 .print{
    width: 90%;
  }
}
@media screen and (max-width:992px){
  .success-page .session3 .rightSession3 {
    h2{
      font-size: 28px;
    }
  }
  .success-page .session3 .rightSession3 .footerRight{
    height: calc(100% - 170.69px);
  }
}