.ModalListCustomer{
    .ant-modal-content{
        padding: 10px 30px 20px;
    }
    .header{
        border-bottom: 1px solid #ECF2F7;
        padding-bottom: 10px;
        button{
            background: #0E72ED;
            border-radius: 8px;
            padding: 11.327px 16px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            text-align: left;
            color: #fff;
            box-shadow: unset;
            outline: none;
            border: none;
        }
        .search{
            display: flex;
            align-items: center;
            height: 44px;
            border: 1px solid #ECF2F7;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 8px;
            width: 350px;
            background-color: #F6F9FC;
            input{
                width: 100%;
                border: none;
                outline: none;
                font-size: 14px;
                background-color: #F6F9FC;     
            }
        }
    }

    .footerListCustomer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 10px 0;
    }
    .custom-ok-button{
        text-decoration: unset;
        width: 300px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        text-align: left;
        color: #FFFFFF;
        background: #0E72ED;
        border-radius: 10px;
        border: none;
        outline: none;
        &.setWidth{
        width: 160px !important;

        }
    }
    .custom-cancel-button{
        text-decoration: unset;
        width: 300px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        text-align: left;
        color: #0E72ED;
        border: 1px solid #0E72ED;
        background: transparent;
        border-radius: 10px;
        outline: none;
    }
    .listCustomer{
        height: 300px;
        overflow: auto;
        .customer-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ECF2F7;
            column-gap: 20px;
            margin-bottom: 20px;
            padding: 10px 10px 15px 10px;
            cursor: pointer;
            &.active{
                background-color: #ECF2F7;
                border-radius: 4px;
            }
            &:hover{
                background-color: #ECF2F7;
                border-radius: 4px;
            }
            &__infor{
                display: flex;
                flex-direction: column;
                row-gap: 8px;
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 19.36px;
                    letter-spacing: -0.1000000015px;
                    text-align: left;
                    margin-bottom: 0;
                    color: #061020;
                }
                p{
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000012px;
                    text-align: left;
                    margin-bottom: 0;
                    color: #6D727C;
                }
                div{
                    display: flex;
                    align-items: flex-start;
                    column-gap: 4px;
                    h4{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        letter-spacing: -0.2800000012px;
                        text-align: left;
                        margin-bottom: 0;
                        color: #6D727C;
                    }
               
                }

            }
            &__action{
                button{
                    width: 180px;
                    background: unset;
                    border: 1px solid #0E72ED;
                    border-radius: 4px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    letter-spacing: -0.0299999993px;
                    text-align: left;
                    color: #0E72ED;
                    box-shadow: unset;
                }
            }
        }
    }
    .ant-modal .ant-modal-close{
        top: -31px;
        right: 8px;
    }
}