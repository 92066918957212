.HeaderProductInPos{
    .header-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 14px 32px 14px 32px;
        gap: 20px;
        align-items: center;
        @media screen and (max-width:768px){
            flex-direction: column;
            align-items: start;
        }
    }
    .filter-search{
        width: 436px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        input{
            border: 1px solid #ECF2F7;
            background: #F6F9FC;
            padding-left: 12px;
            padding-right: 12px;
            width: 100%;
            height: 44px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            letter-spacing: -0.2800000012px;
            text-align: left;
            border-radius: 8px;
            margin-left: 20px;
          &:focus{
            outline: unset;
          }
        }
        button{
            background: #0E72ED;
            width: 44px;
            height: 44px;
            border-radius: 8px;
            display: grid;
            align-items: center;
            justify-content: center;
            border: unset;
            margin-left: 8px;
            margin-right: 8px;
        }
    }
    .header-bars{
        background-color: transparent;
        border: none;
        outline: none;
        i{
            font-size: 25px;
            color: #000000;
        }
    }
    .header-boxLeft{
        display: flex;
        align-items: center;
        h1{
            font-size: 28px;
            font-weight: 600;
            line-height: 33.89px;
            letter-spacing: -1.4400000572px;
            text-align: center;
            color: #061020;
            margin-bottom: 0px;
            line-height: 100%;
        }
        &__reset{
            display: grid;
            align-items: center;
            justify-content: center;
            text-decoration: unset;
            padding: 0px 16px;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #061020;
            font-size: 14px;
            font-weight: 400;
            line-height: 100%;
            text-align: center;
            color: #061020;
            margin-bottom: 0px;
            cursor: pointer;
        }
    }
    .header-boxRight{
        display: flex;
        align-items: center;
        column-gap: 10px;
        @media screen and (max-width:768px){
            display: flex;
            align-self: end;
        }
        .refresh-data{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 22px 12px 22px;
            width: 170px;
            height: 40px;
            gap: 16px;
            border-radius: 8px;
            border: 1px solid #0e72ed;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            letter-spacing: -0.2800000012px;
            text-decoration: unset;
            background-color: transparent;
            color: #0e72ed;
            font-weight: 500;
            transition: 0.3s;
            &__content{
                display: flex;
                align-items: center;
                column-gap: 4px;
                img{
                    width: 20px;
                    height: 20px;
                }
                .refresh-white{
                    display: none;
                }
            }
            &:hover{
                background-color: #0e72ed;
                color: #FFF;
                transition: 0.3s;
                .refresh-blue{
                    display: none;
                }
                .refresh-white{
                    display: block;
                }
            }
            &.hasLoad:hover{
                border: 1px solid #0e72ed;
                color: #0e72ed;
                background-color: #fff;
            }
        }
    }
}
.header-boxPopoverProductInPos{
    width: 185px ;
    padding-top: 6px;
    padding-bottom: 6px;
    p{
        color: #061020;
        font-weight: 400;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        border-bottom: 1px solid #ECF2F7;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        &:hover{
            font-weight: 500;
        }
    }
    p:last-child{
        margin-bottom: 0;
        border-bottom: unset;
        padding-bottom: 0;
    }
}