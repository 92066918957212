@import "../../../../../public/assets/css/variables.scss";
.hidden-barcode{
    display: none;
}
#html5-qrcode-button-camera-stop{
    display: none!important;
}
#html5-qrcode-button-camera-stop{
    display: none!important;
}
#html5-qrcode-anchor-scan-type-change {
    display: none !important;
}
#html5qr-code-full-region{
    width: calc(100% - 520px);
    height: 100%;
    #html5qr-code-full-region__scan_region{
        height: 100%;
        video{
            width: 100%;
            height: 100% !important;
        }
        img{
            display: none!important;
        }
    }
}
#html5-qrcode-button-camera-permission{
    background: #0e72ed;
    padding: 10px 20px;
    border-radius: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -1.4400000572px;
    text-align: left;
    color: #FFFFFF;
    border: unset;
}
.ProductInPosPage-container{
    height: calc(100vh - 165px); /* Chiếm toàn bộ chiều cao màn hình */
    display: flex;
    flex-direction: column;
    .backToPage {
        padding: 12px 10px;
        background: #FFFFFF;
        border: 1px solid #ECF2F7;
        border-radius: 8px;
        text-decoration: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        gap: 4px;
    }
    .ProductInPosPage-body{
        height: 100%;
        height: calc(100vh - 165px);
        display: flex;
        @media screen and (max-width:900px){
            justify-content: space-between;
        }
        //grid-template-columns: 2fr 1fr;
        //flex: 1;
        .ProductInPosPage-left{
            width: calc(100% - 520px);
            height: calc(100vh - 165px);
            @media screen and (max-width:900px){
                width: calc(100% - 420px);
            }
            .pagination-ProInPos{
                padding: 0px 32px 0px 32px;
            }
            .list-category{
                display: flex;
                align-items: start;
                column-gap: 10px;
                background-color: #FFF;
                padding: 20px 32px 0px 32px;
                @media screen and (max-width: 992px) {
                    padding: 12px;
                }
                &.disable{
                    pointer-events: none;
                }
                // overflow-x: auto;
                //chỉ ẩn scroll mà mình muốn nhưng vẫn hoạt động scroll được
                &.scroll-hidden {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                }
                &.scroll-hidden::-webkit-scrollbar {
                    display: none;
                }
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                  }
                  
                  /* Track */
                  &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px #C1C1C1;
                    border-radius: 10px;
                  }
                  
                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: #adb5bd;
                    border-radius: 10px;
                  }
                  
                  /* Handle on hover */
                  &::-webkit-scrollbar-thumb:hover {
                    background-color: #0E72ED;
                    cursor: pointer;
                  }
                button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 12px 22px 12px 22px;
                    width: 100px;
                    height: 40px;
                    gap: 16px;
                    border-radius: 8px;
                    border: 1px solid $primary-color;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000012px;
                    margin-right: 12px;
                    margin-bottom: 12px;
                    text-decoration: unset;
                    background-color: transparent;
                    color: $primary-color;
                    &.active{
                        background-color: $primary-color;
                            color: #FFF;
                            font-weight: 500;
                    }
                    &:hover{
                        background-color: $primary-color;
                        color: #FFF;
                    }
                }
                .category_body{
                    // display: flex;
                    // align-items: center;
                    // flex: 1;
                    display: -webkit-inline-box;
                    column-gap: 10px;
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 4px;
                      }
                      
                      /* Track */
                      &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px #C1C1C1;
                        border-radius: 10px;
                      }
                      
                      /* Handle */
                      &::-webkit-scrollbar-thumb {
                        background: #adb5bd;
                        border-radius: 10px;
                      }
                      
                      /* Handle on hover */
                      &::-webkit-scrollbar-thumb:hover {
                        background-color: #0E72ED;
                        cursor: pointer;
                      }
                    .category_item{
                        gap: 16px;
                        border-radius: 8px;
                        border: 1px solid #0e72ed;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 19.6px;
                        letter-spacing: -0.2800000012px;
                        margin-right: 12px;
                        margin-bottom: 12px;
                        text-decoration: unset;
                        background-color: transparent;
                        color: #0e72ed;
                        cursor: pointer;
                        text-align: center;
                        width: auto;
                        height: 40px;
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.active{
                            background-color: $primary-color;
                            color: #FFF;
                            font-weight: 500;
                        }
                        &:hover{
                            background-color: $primary-color;
                            color: #FFF;
                        }
                    }
                }
            }
        }
        .listProduct{
            padding: 20px 32px 20px 32px;
            height: calc(100vh - 324px);
            overflow-y: auto;
            //
            .skeleton-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                // padding: 12px;
                overflow: hidden;
                border: 1px solid #f0f0f0;
                border-radius: 8px;
                background-color: #fff;
                margin: 8px;
                width: 100%;
                box-sizing: border-box;
                height: 180px!important;
                @media screen and (max-width:1265px) {
                    margin: 0;
                }

                .product-item__boxImg {
                    width: 100%;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 12px;
                    .boxImg-item {
                        width: 100%;
                    }
                }

                .product-item__body {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    padding: 0 8px;
                    .ant-skeleton {
                        overflow: hidden;
                        border-radius: 4px !important; 
                    }
                }
            }
            //
            &-box{
                display: grid;
                grid-template-columns: repeat(6, minmax(0, 1fr));
                gap: 12px;

                @media (min-width: 901px) and (max-width: 1280px) {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                    gap: 12px;
                }
            }
            .product-item{
                cursor: pointer;
                background-color: #FFF;
                border-radius: 8px;
                border: 1px solid #fff;
                height: 180px;
                &__body{
                    padding: 8px 0 8px 8px;
                }
                &__boxImg{
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f4f3f2;
                    img{
                        max-height: 100%;
                        max-width: 100%;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        padding: 2px;
                    }
                }
                h2{
                    color: #061020;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19.6px;
                    letter-spacing: -0.28px;
                    margin-bottom: 4px;
                    max-width: 120px;

                    &.multiline-ellipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /* start showing ellipsis when 2rd line is reached */
                        white-space: pre-wrap; /* let the text wrap preserving spaces */
                      }
                }
                h3{
                    color: #061020;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19.6px;
                    letter-spacing: -0.21px;
                    margin-bottom: 0;
                }   
            }
        }
    }
}
@media screen and (max-width:992px){
    .ProductInPosPage-container .ProductInPosPage-body .listProduct{
        padding: 12px;
        .product-item {
            h2{
                font-size: 12px;
            }
            h3{
                font-size: 12px;
            }
        }
    }
}
@media screen and (max-width:900px){
    .ProductInPosPage-container .ProductInPosPage-body {
        .listProduct{
            width: 100%;
            .listProduct-box{
                grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
            }
        }
        .ProductInPosControl{
            width: 420px;
        }
    }
    #html5qr-code-full-region{
        width: calc(100% - 420px);
    }
}
@media screen and (max-width:600px){
    .HeaderProductInPos .header-boxLeft{
        display: none;
    }
    .HeaderProductInPos .header-container{
        padding: 14px 10px 14px 10px;
    }
    .HeaderProductInPos .header-bars{
        display: none;
    }
}
