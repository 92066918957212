.mainContentGroupProduct{
  padding-top: 20px;
  display: block;
  .filter{
    display: flex;
    width: 100%;
    gap: 20px;
    .filter-left{
      width: calc(100% - 138px);
      display: flex;
      gap: 100px;
      h1{
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 0px;
        padding: 10px 0px;
      }
      .filter-search{
        width: 440px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        input{
          width: calc(100% - 36px);
          border: unset;
          border: unset;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: -0.2800000011920929px;
          text-align: left;
          color: #061020;
          padding-left: 10px!important;
          &:focus{
            outline: unset;
          }
        }
        button{
          width: 36px;
          background: #0E72ED;
          height: 36px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
        }
      }
    }
    .btnCreate{
      border-radius: 8px;
      width: 130px;
      text-align: center;
      background: #0E72ED;
      border: unset;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.2800000011920929px;
        text-align: left;
        color: #FFFFFF;
        position: relative;
        padding-left: 25px;
        img{
          top: -3px;
          left: 0px;
          position: absolute;
        }
      }

    }

    .filter-plus{
      width: 121px!important;
      background: #0E72ED;
      border-radius: 8px;
      width: auto;
      display: grid;
      align-items: center;
      border: unset;
      justify-content: center;
      padding: 0px 16px 0px 12px;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.2800000011920929px;
        text-align: left;
        color: #FFFFFF;
        position: relative;
        padding-left: 26px;
        img{
          position: absolute;
          top: -4px;
          left: 0px;
        }
      }
    }
  }
  .content-table{
    margin-top: 20px;
    .dropdown{
      display: flex;
      justify-content: center;
    }
  }
}
.removeVariant{
  padding: 5px 0px;
  margin-top: 10px;
  text-align: right;
  a{
    padding: 5px 20px;
    background-color: #fc544b !important;
    color: #fff;
    border: unset;
    border-radius: 8px;
    font-size: 20px;
    text-decoration: unset;
    line-height: 0px;
  }
}

