.main-setting-qrcode-page {
  background: #fff;
  margin-top: 12px;
  padding: 20px;
  border-radius: 8px;
}
.main-setting-qrcode-page .title {
  font-size: 28px;
  text-align: left;
  font-weight: 600;
  line-height: 130%;
  border-bottom: 1px solid #D0D6E5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-setting-qrcode-page .content-main-setting-page {
  display: flex;
  gap: 20px;
}
.main-setting-qrcode-page .content-main-setting-page .title-item {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  line-height: 130%;
  border-bottom: 1px solid #D0D6E5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-setting-qrcode-page .content-main-setting-page .left-content-main-setting-page {
  width: calc(100% - 500px);
}
.main-setting-qrcode-page .content-main-setting-page .left-content-main-setting-page .list-form {
  display: grid;
  gap: 20px;
}
.main-setting-qrcode-page .content-main-setting-page .left-content-main-setting-page .list-form .btn-qrcode {
  padding: 12px 0px;
  text-align: right;
}
.main-setting-qrcode-page .content-main-setting-page .left-content-main-setting-page .list-form .btn-qrcode span {
  background: #0E72ED;
  border-radius: 8px;
  border: unset;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #FFFFFF;
  position: relative;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page {
  width: 500px;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .title-item {
  position: relative;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .title-item span {
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px 20px;
  border: 1px solid #0E72ED;
  border-radius: 8px;
  font-size: 16px;
  color: #0E72ED;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .image-qr-code {
  border: 1px dotted #D0D6E5;
  padding: 12px;
  text-align: center;
  display: grid;
  gap: 8px;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .image-qr-code .text1 {
  font-size: 16px;
}
.main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .image-qr-code .text2 {
  font-size: 16px;
  font-weight: 600;
}
@media screen and (max-width: 700px) {
  .main-setting-qrcode-page .title {
    font-size: 24px;
  }
  .main-setting-qrcode-page .content-main-setting-page {
    display: block;
  }
  .main-setting-qrcode-page .content-main-setting-page .left-content-main-setting-page {
    width: 100%;
  }
  .main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page {
    width: 100%;
  }
  .main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .image-qr-code {
    width: 100% !important;
  }
  .main-setting-qrcode-page .content-main-setting-page .right-content-main-setting-page .title-item span {
    display: none;
  }
}

