.BoxFilterDiningTables{
    display: flex;
    align-items: center;
    column-gap: 20px;
    @media (min-width: 400px) and (max-width: 1410px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 10px;
  }
    .filter-search{
        width: 310px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        border: 1px solid #ECF2F7;
        background-color: #F6F9FC;
        input{
          width: calc(100% - 36px);
          border: unset;
          border: unset;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: -0.2800000011920929px;
          text-align: left;
          color: #061020;
          padding-left: 10px;
          background-color: #F6F9FC;
          &:focus{
            outline: unset;
          }
        }
        button{
          width: 36px;
          background: #0E72ED;
          height: 36px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
        }
    }
    .search{
      display: flex;
      align-items: center;
      height: 44px;
      border: 1px solid #ECF2F7;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 8px;
      width: 300px;
      background-color: #F6F9FC;
      @media (min-width: 400px) and (max-width: 1410px) {
        width: 100%;
      }
      input{
          width: 100%;
          border: none;
          outline: none;
          font-size: 14px;
          background-color: #F6F9FC;     
      }
  }
    .ant-select{
        height: 42px;
        width: 210px;
        @media (min-width: 400px) and (max-width: 1410px) {
          width: 100%;
        }
    }
    .remove-filter{
      background-color: #FFF;
      border: 1px solid rgb(214, 22, 23);;
      color: rgb(214, 22, 23);
      padding: 6px 20px;
      border-radius: 8px;
      cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
       column-gap: 4px;
       img{
        margin-top: 2px;
       }
    }
    .ant-select-selection-search{
      padding-left: 10px;
    }
    .addTablet{
      @media screen and (min-width: 1440px) {
        display: none ;
    }
      @media (min-width: 400px) and (max-width: 1410px) {
        display: block;
        height: 42px;
        line-height: 16px;
    }
      background: #0E72ED;
      border-radius: 8px;
      width: auto;
      display: grid;
      align-items: center;
      border: unset;
      justify-content: center;
      padding: 12px 16px 12px 12px;
      cursor: pointer;
      text-align: center;
      span{
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.2800000011920929px;
        text-align: left;
        color: #FFFFFF;
        position: relative;
        padding-left: 26px;
        img{
          position: absolute;
          top: -4px;
          left: 0px;
        }
      }
    }
}
