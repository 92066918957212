.main-requests-page {
  height: calc(100vh - 85.58px);
  padding: 20px 0px;
}
.main-requests-page .content-main-requests-page {
  height: 100%;
  display: flex;
  gap: 20px;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page {
  width: 250px;
  background: #fff;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .filter-requests {
  padding: 12px;
  border-bottom: 1px solid #e6f4ff;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .filter-requests .input-group {
  width: 100%;
  min-height: 42px !important;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .filter-requests .input-group .ant-select {
  height: 42px;
  width: 100%;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .filter-requests .ant-select-selector {
  height: 42px;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom {
  height: calc(100% - 67px);
  overflow-y: scroll;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .item-floor-room {
  cursor: pointer;
  padding: 12px;
  border-left: 4px solid #fff;
  border-bottom: 1px solid #e6f4ff;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .item-floor-room .title {
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .item-floor-room .count {
  font-size: 14px;
  line-height: 130%;
  margin-top: 8px;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .item-floor-room:hover {
  border-left: 4px solid #0e72ed;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .item-floor-room-child {
  padding-left: 30px;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .active {
  border-left: 4px solid #0e72ed;
}
.main-requests-page .content-main-requests-page .left-content-main-requests-page .content-flooroom .active .title {
  color: #0e72ed;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page {
  width: calc(100% - 250px);
  background: #fff;
  padding: 20px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests {
  height: 100%;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .header-content-requests {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .header-content-requests .item-header {
  border-bottom: 4px solid #ffffff;
  cursor: pointer;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .header-content-requests .item-header p {
  padding: 15px 0px;
  text-align: center;
  font-weight: 600;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .header-content-requests .item-header:hover {
  border-bottom: 4px solid #0e72ed;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .header-content-requests .active {
  border-bottom: 4px solid #0e72ed;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .loadding {
  height: 100%;
  display: grid;
  align-items: center;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests {
  height: calc(100% - 110px);
  border: 1px solid #e6f4ff;
  overflow-y: auto;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests {
  display: flex;
  border-bottom: 1px solid #e6f4ff;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests:last-child {
  border-bottom: 0px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests p {
  font-size: 14px;
  line-height: 130%;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .name-table {
  width: 130px;
  padding: 12px;
  display: grid;
  align-items: center;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .name-table p {
  font-weight: bold;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item {
  width: calc(100% - 130px);
  display: flex;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .date {
  display: grid;
  align-items: center;
  width: 180px;
  padding: 12px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .date p {
  margin-bottom: 8px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .date span {
  font-size: 12px;
  padding: 4px 4px;
  border: 1px solid #0e72ed;
  border-radius: 8px;
  text-align: center;
  color: #0e72ed;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .date .staff {
  color: #58c35a;
  border: 1px solid #58c35a;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .date .pay {
  color: #f9a501;
  border: 1px solid #f9a501;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .name-customer {
  width: 180px;
  padding: 12px;
  display: grid;
  align-items: center;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .name-customer p {
  font-weight: 600;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd {
  display: grid;
  align-items: center;
  width: calc(100% - 540px);
  padding: 12px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd ul {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd ul li {
  padding: 0px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd ul li span {
  font-size: 13px;
  display: block;
  white-space: unset;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd ul li .note-item-order {
  font-size: 12px;
  font-style: italic;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .nd .note-order-all {
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item {
  padding: 12px;
  display: flex;
  gap: 10px;
  width: 180px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-cancel {
  width: 60px;
  cursor: pointer;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-cancel p {
  padding: 12px 0px;
  text-align: center;
  border: 1px solid red;
  border-radius: 8px;
  color: red;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-completed {
  width: 98px;
  cursor: pointer;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-completed p {
  padding: 12px 5px;
  text-align: center;
  border: 1px solid #0e72ed;
  color: #0e72ed;
  border-radius: 8px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-completed .staff {
  border: 1px solid #58c35a;
  color: #58c35a;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item .btn-item .btn-completed .pay {
  border: 1px solid #f9a501;
  color: #f9a501;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-completed .date {
  width: 240px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-completed .nd {
  width: calc(100% - 420px);
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-canceled .date {
  width: 190px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-canceled .date p {
  font-size: 12px;
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-canceled .nd {
  width: calc(100% - 600px);
}
.main-requests-page .content-main-requests-page .right-content-main-requests-page .content-requests .table-content-requests .item-requests .content-item-canceled .reason {
  width: 230px;
  display: grid;
  align-items: center;
  padding: 12px;
}

.main-requests-page-mobile {
  display: none;
}

.ant-modal-content .form-group-radio-home .ant-radio-group {
  width: 100%;
  display: block;
  display: grid;
  gap: 15px;
}
.ant-modal-content .form-group-radio-home .ant-radio-group .ant-radio-wrapper {
  width: 100%;
}
.ant-modal-content .form-group-radio-home .ant-radio-group .ant-radio-wrapper span {
  font-weight: 600;
  font-size: 16px;
}
.ant-modal-content .form-group-radio-home .ant-radio-group .ant-radio-wrapper span .noti {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  color: #6D727C;
}
.ant-modal-content .form-group-select .input-group {
  min-height: 44px !important;
}
.ant-modal-content .form-group-select .input-group .ant-select {
  height: 44px;
  width: 100%;
}
.ant-modal-content .form-group-select .input-group .ant-select input {
  padding-left: 10px;
}
.ant-modal-content .form-group-select .input-group .error {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.5714285714;
  color: #ff4d4f;
}
.ant-modal-content .note {
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
}
.ant-modal-content .form-group-input {
  margin-top: 8px;
}
.ant-modal-content .form-group-input .input-group {
  margin-bottom: 0px;
}
.ant-modal-content .form-group-input .input-group .ant-input-affix-wrapper {
  border: 1px solid #D0D6E5;
  border-radius: 8px;
  padding: 12px;
}
.ant-modal-content .btn-cancel {
  margin-top: 20px;
}
.ant-modal-content .btn-cancel button {
  width: 100%;
  padding: 12px 16px 12px 12px;
  background: #0E72ED;
  border-radius: 8px;
  color: #fff;
  border: unset;
}
.ant-modal-content .btn-cancel button:focus {
  outline: unset;
}

@media screen and (max-width: 768px) {
  .main-requests-page {
    display: none;
  }
  .container-requests {
    background: #fff;
    border-top: 1px solid #ecf2f7;
  }
  .ant-modal-content .form-group-radio-home .ant-radio-group .ant-radio-wrapper span .noti {
    white-space: break-spaces;
  }
  .main-requests-page-mobile {
    display: block;
    background: #fff;
    padding: 12px 0px;
  }
  .main-requests-page-mobile .filter-requests {
    gap: 10px;
    display: grid;
  }
  .main-requests-page-mobile .filter-requests .input-group .ant-select {
    width: 100%;
    height: 48px;
    background: #0E72ED;
  }
  .main-requests-page-mobile .filter-requests .input-group .ant-select .ant-select-selector {
    background: #0E72ED;
  }
  .main-requests-page-mobile .filter-requests .input-group .ant-select .ant-select-selector span {
    color: #fff;
  }
  .main-requests-page-mobile .filter-requests .input-group .ant-select .ant-select-selector:after {
    color: #fff;
  }
  .main-requests-page-mobile .filter-requests .input-group .ant-select svg path {
    fill: #fff;
  }
  .main-requests-page-mobile .filter-requests .input-group:last-child .ant-select {
    width: 100%;
    height: 48px;
    background: rgba(14, 114, 237, 0.1019607843);
  }
  .main-requests-page-mobile .filter-requests .input-group:last-child .ant-select .ant-select-selector {
    background: rgba(14, 114, 237, 0.1019607843);
  }
  .main-requests-page-mobile .filter-requests .input-group:last-child .ant-select .ant-select-selector span {
    color: #0E72ED;
  }
  .main-requests-page-mobile .filter-requests .input-group:last-child .ant-select .ant-select-selector:after {
    color: #0E72ED;
  }
  .main-requests-page-mobile .filter-requests .input-group:last-child .ant-select svg path {
    fill: #0E72ED;
  }
  .main-requests-page-mobile .content-requests .header-content-requests {
    display: grid;
    grid-template-columns: 0.6fr 1fr 1fr 1fr;
    gap: 8px;
    padding: 14px 0px;
  }
  .main-requests-page-mobile .content-requests .header-content-requests .item-header {
    background: #D0D6E5;
    border-radius: 6px;
    text-align: center;
  }
  .main-requests-page-mobile .content-requests .header-content-requests .item-header p {
    padding: 6px 0px;
    font-size: 12px;
  }
  .main-requests-page-mobile .content-requests .header-content-requests .active {
    background: #005CF7;
  }
  .main-requests-page-mobile .content-requests .header-content-requests .active p {
    color: #fff;
  }
  .main-requests-page-mobile .content-requests .header-content-requests-2 {
    padding-top: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests {
    border-bottom: 1px solid #f2f2f7;
    padding: 12px 0px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .name-table p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .reason {
    margin-top: 6px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .reason p {
    color: red;
    font-size: 14px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .name-customer-date {
    margin-top: 6px;
    display: flex;
    gap: 10px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .name-customer-date p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #6F767E;
    position: relative;
    padding-left: 15px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .name-customer-date p img {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .nd {
    margin-top: 5px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .nd ul {
    margin-bottom: 0px;
    list-style-type: none;
    padding-left: 0px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .nd ul li span {
    white-space: unset;
    font-size: 13px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .nd ul li .note-item-order {
    display: block;
    font-size: 12px;
    font-style: italic;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .nd p {
    font-size: 14px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests {
    margin-top: 6px;
    display: flex;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests .type-item-requests {
    width: calc(100% - 106px);
    display: grid;
    align-items: center;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests .type-item-requests span {
    display: unset;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #242133;
    border: 0.5px solid #005CF7;
    background: rgba(0, 92, 247, 0.1019607843);
    border-radius: 4px;
    padding: 3px 6px;
    width: 150px;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests .type-item-requests .pay {
    background: rgba(240, 189, 6, 0.1019607843);
    border: 0.5px solid #F0BD06;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests .type-item-requests .staff {
    background: rgba(88, 195, 90, 0.1019607843);
    border: 0.5px solid #58C35A;
  }
  .main-requests-page-mobile .content-requests .table-content-requests .item-requests .bottom-requests .btn-item {
    width: 106px;
    display: flex;
    gap: 20px;
  }
}

