.PaymentUpdateProductPage{
    .backToPage {
        padding: 12px 10px;
        background: #FFFFFF;
        border: 1px solid #ECF2F7;
        border-radius: 8px;
        text-decoration: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        gap: 4px;
    }
    &-body{
        .title-page{
            padding: 16px 20px;
            text-align: center;
            font-size: 30px;
            font-weight: 500;
        }
        .box-body{
            padding: 0 20px;
            height: calc(100vh - 189px);
            display: grid;
            padding: 0px 20px;
            grid-template-columns: 1fr 1.3fr;
            gap: 8px;
        }
        // .left-content{
        //     width: 100%;
        //     background: #fff;
        //     overflow: auto;
        //     padding: 8px;
        //     border-radius: 6px;
        // }

        .left-content {
            width: 100%;
            background: #fff;
            overflow: auto;
            border-radius: 6px;
            .item {
                display: flex;
                justify-content: space-between;
                padding: 8px 14px;
                border-bottom: 1px solid #eee;
                .item-details {
                    max-width: 70%;
                    .item-name {
                        font-weight: bold;
                        font-size: 16px;
                        color: #000000;
                    }

                    .item-desc {
                        font-size: 14px;
                        color: #666;
                    }
                    p {
                        font-size: 14px;
                        color: #000000;
                    }
                }

                .item-price {
                    font-weight: bold;
                    font-size: 16px;
                    align-self: center;
                }
            }

            .item.selected {
                background-color: #0E72ED;

                .item-name, .item-desc, .item-price {
                    color: white;
                }
                .item-details p {
                    color: white;
                }
            }
        }
        .right-content{
            background-color: #fff;
            .box-total{
                color: #088C3D;
                font-size: 48px;
                font-weight: 500;
                line-height: 55.2px;
                letter-spacing: -1.4400000572px;
                text-align: center;
                padding: 42px 0px;
                border-bottom: 4px solid #ECF2F7;
            }
            .box-pay{
                padding: 0px 12px;
                .btn-pay{
                    width: 100%;
                    height: 80px;
                    margin-top: 15px;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    background: #0E72ED;
                    justify-content: center;
                    text-decoration: unset;
                    color: #FFFFFF;
                    font-size: 24px;
                    border: none;
                    outline: none;
                }
            }
        }
    }
}