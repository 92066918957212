.PaymentsSettingPage{
    background-color: #FFF;
    padding: 10px;
    border-radius: 8px;
    &-body{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .dividerPayment{
        border-bottom: 1px solid #ECF2F7;
    }
}