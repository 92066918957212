@import "../../../../../public/assets/css/variables.scss";
.mainContentInventory {
    margin-top: 20px;
    display: flex;
    gap: 12px;

    .filter {
        position: relative;

        h1 {
            width: 232px;
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-bottom: 0px;
            padding: 10px 0px;
        }

        &__wrapper {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: sticky;
            top: 20px;
            max-height: calc(100vh - 40px);
            overflow-y: auto;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            ::-webkit-scrollbar {
                display: none;
            }

            .filter-tab {
                width: 232px;
                height: fit-content;
                background-color: white;
                padding: 8px 0px;
                border-radius: 8px;

                button.filter-collapse-btn {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    display: flex;
                    justify-content: space-between;
                    color: black;

                    span {
                        color: black;
                        font-weight: 600;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                input[type="radio"] {
                    appearance: none;
                    background: transparent;
                    position: relative;
                }

                input[type="radio"]::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    text-align: center;
                    background: #fff;
                    display: block;
                    pointer-events: none;
                    opacity: 1;
                    color: black;
                    border: 1.5px solid #6d727c;
                }

                input[type="radio"] + label {
                    line-height: 24px;
                    margin: 0 8px 0 8px;
                }

                input[type="radio"]:hover::after {
                    //content: '';
                    //background: #1E80EF;
                    //opacity: .3;
                }

                input[type="radio"]:checked::after {
                    content: "\2713";
                    color: #fff;
                    background: $primary-color;
                    border: 1.5px solid $primary-color;
                    font-size: 12px;
                }

                //input[type=checkbox]:checked:hover::after {
                //    opacity: 1;
                //}
                input[type="radio"],
                input[type="radio"]::after {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    border-radius: 50%;
                    line-height: 14px;
                    vertical-align: middle;
                }

                .filter-tab__body {
                    padding: 0px 10px;

                    .filter-tab__option {
                        padding: 8px 0px;
                        display: flex;
                        align-items: center;

                        label,
                        input {
                            cursor: pointer;
                        }
                    }
                }

                .filter-action-btn {
                    margin-left: 10px;
                }
            }
        }

        &__tab {
            background-color: white;
            color: black;
            border: none;

            .ant-collapse-item {
                .ant-collapse-header {
                    padding: 10px 12px;
                }

                .ant-collapse-content {
                    border: none;

                    .ant-collapse-content-box {
                        padding: 0px;

                        .option {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            padding: 10px 12px;

                            label,
                            input {
                                cursor: pointer;
                            }

                            input[type="checkbox"] {
                                appearance: none;
                                background: transparent;
                                position: relative;
                            }

                            input[type="checkbox"]::after {
                                position: absolute;
                                top: 0;
                                left: 0;
                                content: "";
                                text-align: center;
                                background: #fff;
                                display: block;
                                pointer-events: none;
                                opacity: 1;
                                color: black;
                                border: 1.5px solid #6d727c;
                            }

                            input[type="checkbox"] + label {
                                line-height: 24px;
                                margin: 0 15px 0 15px;
                            }

                            input[type="checkbox"]:checked::after {
                                content: "\2713";
                                color: #fff;
                                background: $primary-color;
                                border: 1.5px solid $primary-color;
                                font-size: 12px;
                            }

                            input[type="checkbox"]:disabled {
                                cursor: wait;

                                & + label {
                                    cursor: wait;
                                }
                            }

                            input[type="checkbox"]::after:disabled {
                                cursor: wait;

                                & + label {
                                    cursor: wait;
                                }
                            }

                            input[type="checkbox"],
                            input[type="checkbox"]::after {
                                width: 16px;
                                height: 16px;
                                font-size: 16px;
                                line-height: 14px;
                                vertical-align: middle;
                                border-radius: 4px;
                            }
                        }

                        .ant-radio-group {
                            .ant-space {
                                gap: 0px;

                                .ant-space-item {
                                    padding: 10px 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-page {
        flex: 1;

        &-header {
            display: flex;
            justify-content: stretch;
            gap: 12px;
            align-items: center;

            .dropdown {
                //width: 210px;
                background-color: #ffffff;
                height: 44px;
                padding: 12px;
                border-radius: 8px;
                line-height: 20px;
                font-weight: 600;
                font-size: 16px;
                text-align: left;
                color: $primary-color;
                display: flex;
                align-items: center;
                gap: 10px;

                &__location {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 600;
                    color: $primary-color;

                    .ant-select-selector {
                        width: 250px;
                        @media screen and (max-width: 1000px) {
                            width: 120px;
                        }
                    }
                }
            }

            form {
                background-color: white;
                border-radius: 8px;
                padding: 4px;
                display: flex;
                align-items: center;
                gap: 24px;
                width: 440px;
                height: 44px;

                input {
                    border: none;
                    margin-left: 4px;
                    flex: 1;
                    font-size: 14px;

                    &:focus {
                        border: none;
                        outline: none;
                    }
                }

                button {
                    border-radius: 8px;
                    background-color: #0e72ed;
                    outline: none;
                    border: none;
                    width: 36px;
                    height: 36px;
                    margin: 0px;
                    padding: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        display: block;
                    }
                }
            }

            button {
                height: 100%;
            }
        }

        .content-table {
            margin-top: 20px;

            .table-responsive {
                table.table {
                    thead {
                        tr {
                            th {
                                padding: 12px 10px;
                                align-content: center;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                // padding: 12px 15px;
                                font-size: 14px;
                                img.product-thumbnail {
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 10px;
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }
                        }

                        .action__container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 12px;

                            .ant-space-compact {
                                gap: 0px;

                                input {
                                    padding: 0px 12px;
                                    text-align: center;
                                    @media screen and (max-width: 1250px) {
                                      padding: 0 7px;
                                    }
                                    @media screen and (max-width: 1200px) {
                                      width: 35px;
                                    }
                                }

                                button {
                                    padding: 10px 12px;
                                }
                            }

                            > button {
                                padding: 0px 12px;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 1050px) {
            overflow: hidden;
        }
    }

    .pagination {
        margin: 0px !important;

        li {
            padding: 0px;
            width: 32px;
            height: 32px;
            overflow: hidden;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.disabled {
                pointer-events: none;

                a {
                    color: #d0d6e5;
                    border-color: #d0d6e5;
                }
            }

            a {
                text-decoration: none;
            }

            &.previous {
                a {
                    i::before {
                        margin-right: 1px;
                    }
                }
            }

            &.next {
                a {
                    i::before {
                        margin-left: 1px;
                    }
                }
            }

            i {
                &::before {
                    display: block;
                }
            }
        }

        &__container {
            margin: 20px 0px 12px;
            display: flex;
            gap: 20px;
            align-items: center;
        }

        &__info {
            font-size: 14px;
            line-height: 19.6px;
            font-weight: 400;
        }
    }
}
