.LayoutSettings {
    display: flex;
    column-gap: 16px;
    padding: 20px;
    
    .box-sidebar {
      width: 246px;
      flex-shrink: 0; /* Giữ sidebar không bị co lại */
    }
    
    .box-body {
      width: calc(100% - 246px);
    }
  }
  