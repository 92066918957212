.OrdersSessionMachineContainer{
  &.disableScreen{
    pointer-events: none;
  }
  .ant-select-selection-search{
    padding-left: 10px;
  }
    .status{
        padding: 4px 6px;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        &--open{
          background-color: #0E72ED;
          color: #FFF;
        }
        &--close{
          background-color: red;
          color: #FFF;
        }
      }

      .tableSession-order{
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            text-align: left;
            color: #000000;
            margin-bottom: 2px;
        }
        h4{
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
            color: #BABABA;
            margin-bottom: 5px;
        }
        h2{
            font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: left;
        color: #000000;
        }
    }
    .watch{
        font-size: 12px;
        font-weight: 500;
        line-height: 14.4px;
        text-align: center;
        color: #fff;
        padding: 4px 14px 4px 14px;
        gap: 4px;
        border-radius: 12px;
        white-space: nowrap;
        background-color: #0e72ed;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
      .orderSession-price{
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        &--plus{
          color: #00BD40;
        }
        &--minus{
          color: #ED3724;
        }
      }
        .filter-search{
            width: 310px;
            border-radius: 8px;
            background: #fff;
            display: flex;
            padding: 4px;
            input{
              width: calc(100% - 36px);
              border: unset;
              border: unset;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #061020;
              padding-left: 10px;
              &:focus{
                outline: unset;
              }
            }
            button{
              width: 36px;
              background: #0E72ED;
              height: 36px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: unset;
            }
        }
        .ant-select{
          width: 160px;
          height: 41px;
          margin-top: 4px;
        }
        .staff{
          img{
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        .data-row{
          cursor: pointer;
        }
        tr{
            th{
                padding-left: 10px !important;
            }
        }
        .orderType{
            font-size: 12px;
            font-weight: 500;
            line-height: 14.4px;
            text-align: center;
            color: #fff;
            padding: 4px 10px 4px 10px;
            gap: 4px;
            border-radius: 12px;
            width: 85px;
            white-space: nowrap;

            &--payDebt{
                background-color: #58C35A;
            }
            &--simple{
                background-color: #00A3FF;
            }
            &--refund{
                background-color: #f0bd06;
            }
        }

        .StatusOrder{
            font-size: 12px;
            font-weight: 500;
            line-height: 14.4px;
            text-align: center;
            color: #fff;
            padding: 4px 10px 4px 10px;
            gap: 4px;
            border-radius: 12px;
            width: 85px;
            white-space: nowrap;

            &--debt{
                background-color: #F32E1B;
            }
            &--success{
                background-color: #00A3FF;
            }
        }
}
@media screen and (max-width:1200px){
  .OrdersSessionMachineContainer .ant-select{
    width: 100%;
  }
}