.diningTable-container{
  gap: 16px;
  background-color: #FFF;
  padding: 12px;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (min-width: 1440px) {
    display: flex ;
}

  .diningTable-filter{
    max-width: 232px;
    width: 100%;
  }
  .diningTable-boxFilter{
    @media (min-width: 400px) and (max-width: 1410px) {
      width: 100%;
    }
  }
  .diningTable-content{
    flex: 1;
    .title{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: #0E72ED;
      margin-bottom: 0;
      margin-top: 0;
    }
    .boxAction{
      @media (min-width: 400px) and (max-width: 1410px) {
        display: flex;
        flex-direction: column;
        align-items: start !important;
    }
    }
  }
  &.disabledScreen{
    pointer-events: none;
  }
    // padding: 20px;
    .title{
      line-height: 44px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 10px;
    }
    .diningTable-action{
      @media (min-width: 400px) and (max-width: 1410px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0px 20px;
    }
       padding: 10px 0px 10px;
        column-gap: 10px;
        &--delete{
            background: rgb(214,22,23);
            border-radius: 8px;
            width: auto;
            display: flex;
            align-items: center;
            border: unset;
            justify-content: center;
            padding: 12px 16px 12px 12px;
            cursor: pointer;
            span{
              font-size: 14px;
              font-weight: 500;
              line-height: 16.94px;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #FFFFFF;
              position: relative;
              padding-left: 10px;

        }
        i{
         color: #FFFFFF;
        }
        }

        &--add{
          @media (min-width: 400px) and (max-width: 1410px) {
            display: none;
        }
            background: #0E72ED;
            border-radius: 8px;
            width: auto;
            display: grid;
            align-items: center;
            border: unset;
            justify-content: center;
            padding: 12px 16px 12px 12px;
            cursor: pointer;
            span{
              font-size: 14px;
              font-weight: 500;
              line-height: 16.94px;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #FFFFFF;
              position: relative;
              padding-left: 26px;
              img{
                position: absolute;
                top: -4px;
                left: 0px;
              }
            }
        }
    }
    .check-diningTables{
      cursor: pointer;
    }
    .tableName{
      max-width: 300px
    }
    .table-responsive tbody tr td p{
      font-size: 16px;
    }
    .table-responsive{
      padding: 0;
      height: calc(100vh - 280px);
      overflow-y: auto;
      @media (min-width: 400px) and (max-width: 1410px) {
        overflow-x: auto !important;
        height: auto;
    }
    }
    .quantity_people{
      margin-top: 4px;
      color: #959393;
      font-size: 14px !important;
    }
}