.LayoutSessionDetails{
    padding: 20px;
    .title{
        line-height: 44px;
        font-size: 25px;
        font-weight: 600;
      }
      &-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: 12px 10px;
        border-radius: 8px;
        margin-bottom: 16px;
      }
      &-name{
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 0;
        color: #061020;
      }
      &-back{
        background-color: #F4F6F9;
        padding: 4px 10px;
        border-radius: 4px;
        cursor: pointer;
      }
}