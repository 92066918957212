.ModalOption {
  .ant-modal-content {
    border-radius: 10px;
    background-color: #ffffff;
  }
  .ant-modal .ant-modal-close {
    top: -31px;
    right: 8px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0px;
    justify-content: flex-start;
    //padding: 20px;
  }

  .modal-button {
    @media (max-width: 850px) {
      width: 165px;
    }
    @media (max-width: 768px) {
      width: 157px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #007bff;
    border-radius: 8px;
    padding: 12px 10px 12px 10px;
    margin: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background-color: #fff;
    width: 235px;
    text-align: center;
    transition: background-color 0.3s ease;
    gap: 2px;

    &:hover {
      background-color: #e6f7ff;
    }

    .icon {
      margin-right: 8px;
    }

    .quantity-customer {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.1799999923px;
      color: #ffffff;
      background: #061020;
      border-radius: 100%;
      margin-right: 4px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}