.NumberGuest {
  .ant-modal .ant-modal-close {
    top: -31px;
    right: 8px;
  }

  .ant-modal-content {
    width: 600px;
    padding: 10px 30px 20px;
    margin: auto;
    position: relative;
  }

  .header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  .line {
    height: 1px;
    background-color: #E9F4FF;
  }

  .line2 {
    height: 1px;
    background-color: #E9F4FF;
    margin-top: 30px;
  }

  .guest-table-content {
    .input-box {
      display: flex;
      margin-bottom: 20px;
      align-items: center;  /* Căn giữa theo chiều dọc */
      justify-content: flex-start;
      height: 40px;  /* Đặt chiều cao của ô input */
      border: 1px solid #d9d9d9;
      background-color: #f0f5ff;
      border-radius: 4px;
      font-size: 18px;
      padding-left: 10px
    }


    .keypad {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 16px;

      .key {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        border: 1px solid rgba(244, 246, 249);
        font-size: 18px;
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        transition: background-color 0.3s;
        &:hover {
          background-color: rgba(244, 246, 249);
        }
      }

      .backspace {
        grid-column: 3;
        font-size: 30px;
      }
      .zero {
        grid-column: 2 ;
      }
      .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        border: 1px solid rgba(244, 246, 249);
        font-size: 18px;
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        transition: background-color 0.3s;
        grid-column: 1 ;
      }
    }
  }

  .ant-modal-footer {
    margin-top: 20px;
    display: flex;
    text-align: center;
    align-items: center;

    button {
      width: 50%;
    }

    .ant-btn-default {
      height: 50px;
      border-radius: 10px;
      background-color: #BABABA;
      color: #FFFFFF;
      font-size: 20px;
    }

    .ant-btn-primary {
      height: 50px;
      border-radius: 10px;
      background-color: #0e72ed;
      font-size: 20px;
    }
  }
}