.main-start-page{
  background: #fff;
  margin-top: 12px;
  padding: 20px;
  border-radius: 8px;
  height: calc(100vh - 109.58px);
  .title{
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    line-height: 130%;
  }
  .summary{
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }
  .content-main-start-page{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .left-content-main-start-page{
      text-align: center;
      display: grid;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        max-width: 100%;
      }
    }
    .right-content-main-start-page{
      display: grid;
      align-items: center;
      .item-right{
        .title-item-right{
          text-align: left;
          color: #0e72ed;
          font-size: 18px;
          font-weight: 600;
          line-height: 130%;
        }
        ul{
          margin-top: 10px;
          margin-bottom: 20px;
          display: grid;
          gap: 5px;
          li{
            line-height: 130%;
          }
        }
      }
      .btn-start{
        padding: 12px 0px;
        a{
          background: #0e72ed;
          border-radius: 8px;
          padding: 12px 20px;
          span{
            color: #fff;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .main-start-page .content-main-start-page{
    grid-template-columns: 1fr;
  }
  .main-start-page .title{
    font-size: 24px;
  }
  .main-start-page .content-main-start-page .right-content-main-start-page{
    margin-top: 16px;
  }
}