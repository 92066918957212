.mainContentTagProduct {
    padding-top: 20px;
    display: block;
    .filter {
        display: flex;
        width: 100%;
        gap: 20px;
        height: 44.19px;
        .filter-left {
            width: calc(100% - 138px);
            display: flex;
            gap: 100px;
            @media screen and (max-width: 850px) {
                gap: 0;
                justify-content: space-between;
            }
            h1 {
                font-size: 20px;
                font-weight: 600;
                line-height: 24.2px;
                letter-spacing: -0.02em;
                text-align: left;
                margin-bottom: 0px;
                padding: 10px 0px;
            }
            .filter-search {
                width: 440px;
                border-radius: 8px;
                background: #fff;
                display: flex;
                align-items: center;
                padding: 4px;
                padding-right: 2px;
                input {
                    width: calc(100% - 36px);
                    border: unset;
                    border: unset;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000011920929px;
                    text-align: left;
                    color: #061020;
                    padding-left: 10px;
                    &:focus {
                        outline: unset;
                    }
                }
                button {
                    width: 36px;
                    background: #0e72ed;
                    height: 36px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: unset;
                }
                @media screen and (max-width: 850px) {
                    width: 400px;
                }
            }
        }

        .btnCreate {
            border-radius: 8px;
            width: 130px;
            text-align: center;
            background: #0e72ed;
            border: unset;
            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #ffffff;
                position: relative;
                padding-left: 25px;
                img {
                    top: -3px;
                    left: 0px;
                    position: absolute;
                }
            }
        }
    }

    .content-table {
        margin-top: 20px;
        .table-responsive {
            thead {
                tr {
                    th {
                        padding: 12px 10px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        @media screen and (max-width: 850px) {
                            &:first-child {
                                text-align: center;
                            }
                            .info .title-code {
                                width: 100%;
                            }
                        }

                        .hot,
                        .status {
                            background-color: #58c35a;
                            padding: 3px 10px;
                            border-radius: 12px;
                            color: #ffff;
                            font-size: 12px;
                        }
                        .info .title-code .code {
                            max-width: 150px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.item-selected {
    .ant-select-selector {
        cursor: pointer !important;
        input {
            pointer-events: none !important;
            caret-color: transparent !important;
        }
    }
}
