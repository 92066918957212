.CrmIntegration{
    background-color: #FFF;
    padding: 30px;
    border-radius: 8px;
    height: 100%;
    &-body{
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .Box{
            display: flex;
            column-gap: 20px;
            @media (min-width: 768px) and (max-width: 1024px) {
                flex-direction: column;
            }
            &-infor{
                display: flex;
                align-items: start;
                column-gap: 20px;
                padding: 20px;
                img{
                    width: 80px;
                    height: 80px;
                    border-radius: 4px;
                    object-fit: contain;
                }
                &__content{
                    h1{
                        color: #000;
                        font-size: 20px;
                    }
                    p{
                        line-height: 28px;
                        width: 272px;
                        color: #6C757D;
                        font-size: 14px;
                        text-align: left;
                        margin-bottom: 0;
                        @media (min-width: 768px) and (max-width: 1024px) {
                            width: 100%;
                        }
                    }
                }
            }
            &-form{
                width: calc(100% - 372px);
                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                }
                .updateForm{
                    width: 120px;
                    padding: 12px 6px;
                    border-radius: 8px;
                    background: #0E72ED;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    letter-spacing: -0.2800000012px;
                    text-align: center;
                    color: #FFFFFF;
                    border: unset;
                    margin-top: 20px;
                }
            }
            &-form--crm{
                @media (min-width: 768px) and (max-width: 1024px) {
                    padding-left: 20px;
                }
            }
        }
    }
    .dividerCrm{
        border-bottom: 1px solid #ECF2F7;
    }
}