.page-files {
  display: flex;
}
@media (max-width: 1100px) {
  .page-files {
    flex-direction: column;
  }
}
.page-files .mailbox-controls {
  width: calc(100% - 300px);
  display: flex;
  gap: 10px;
}
@media (max-width: 768px) {
  .page-files .mailbox-controls {
    width: 100%;
    margin-bottom: 20px;
  }
}
.page-files .mailbox-controls button {
  height: 46px;
  width: 46px;
  border: unset;
  border-radius: 8px;
}
.page-files .mailbox-controls button i {
  color: #fff;
}
.page-files .mailbox-controls button span {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.page-files .mailbox-controls .reload {
  background: #ffa426;
}
.page-files .mailbox-controls .remove-file {
  background: #fc544b;
}
.page-files .mailbox-controls .upload {
  width: 100px;
  background: #0E72ED;
  position: relative;
}
.page-files .mailbox-controls .upload input {
  cursor: pointer !important;
  height: 100%;
  top: 0px;
  left: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
}
.page-files .mailbox-controls .upload span {
  margin-left: 5px;
}
.page-files .mailbox-controls .create-folders {
  width: 130px;
  background: #3bb557;
}
.page-files .mailbox-controls .create-folders span {
  margin-left: 5px;
}
.page-files .mailbox-controls .structure {
  display: flex;
  gap: 10px;
}
.page-files .mailbox-controls .structure button {
  height: 46px;
  width: 46px;
  border: unset;
  border-radius: 8px;
}
.page-files .mailbox-controls .structure button i {
  color: #333;
}
.page-files .mailbox-controls .structure button span {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.page-files .search {
  width: 300px;
}
@media (max-width: 1100px) {
  .page-files .search {
    margin-top: 15px;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .page-files .search {
    width: 100%;
  }
}
.page-files .search form {
  width: 100%;
  border: #ecf2f7 solid 1px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  padding: 4px;
}
.page-files .search form input {
  width: calc(100% - 36px);
  border: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  padding-left: 10px !important;
}
.page-files .search form input:focus {
  outline: unset;
}
.page-files .search form button {
  width: 36px;
  background: #0E72ED;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 35px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 400;
}
.breadcrumb .ant-breadcrumb {
  display: flex;
  align-items: center;
}
.breadcrumb .ant-breadcrumb li {
  display: flex;
  align-items: center;
}
.breadcrumb .ant-breadcrumb-link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #0e72ed;
}
.breadcrumb .ant-breadcrumb-separator {
  margin: 0 8px;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
}
.breadcrumb .breadcrumb-item i {
  margin-left: 5px;
}

.body-information .list-item .card-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
@media (max-width: 1150px) {
  .body-information .list-item .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .body-information .list-item .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
.body-information .list-item .loading_data {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-information .list-item .file-card {
  width: 100%;
  height: 310px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}
.body-information .list-item .file-card .file-image {
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.body-information .list-item .file-card .file-image .image-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body-information .list-item .file-card .file-image .zoom-image {
  transition: transform 0.3s ease;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: block;
}
.body-information .list-item .file-card .file-image .zoom-image:hover {
  transform: scale(1.1);
}
.body-information .list-item .file-card .file-image .file-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
.body-information .list-item .file-card .file-content {
  background-color: #f4f4f4;
  padding: 10px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.body-information .list-item .file-card .file-content .file-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.body-information .list-item .file-card .file-content .file-info .file-name {
  color: #666;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.body-information .list-item .file-card .file-content .file-info .file-name:hover {
  text-decoration: underline;
  color: #0e72ed;
}
.body-information .list-item .file-card .file-content .file-info .file-size {
  color: #999;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-information .list-item .file-card .file-content .file-info .file-date {
  color: #999;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-information .list-item .file-card .file-content .file-actions {
  position: relative;
  display: inline-block;
  text-align: center;
  align-items: center;
}
.body-information .list-item .file-card .file-content .file-actions .action-button {
  border: none;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  background-color: #0e72ed;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body-information .list-item .file-card .file-content .file-actions .action-button:hover {
  background-color: #5566e6;
}
.body-information .list-item .file-card .file-content .file-actions .dropdown-menu {
  position: relative;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 5px;
  z-index: 1000;
  width: 100%;
}
.body-information .list-item .file-card .file-content .file-actions .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}
.body-information .list-item .file-card .file-content .file-actions .dropdown-menu .dropdown-item:hover {
  background-color: #f0f0f0;
}

.ant-dropdown-menu {
  width: 100%;
}

