header .headerTop .headerContent {
  display: flex;
  gap: 20px;
}
header .headerTop{
  background: #fff;
}
header .headerTop .headerContent .headerLogo {
  width: 150px;
  display: grid;
  align-items: center;
}
header .headerTop .headerContent .headerRight {
  padding: 9.5px 0px;
  display: grid;
  justify-content: end;
  align-items: center;
  width: calc(100% - 150px);
}
header .headerTop .headerContent .header-right-mobile {
  display: none;
}
header .headerTop .headerContent .headerRight .headerRightContent {
  gap: 20px;
  display: flex;
  align-items: center;
}
header .headerTop .headerContent .headerRight .headerRightContent .headerNoti div{
  cursor: pointer;
}
header .headerTop .headerContent .headerRight .headerRightContent .headerPlan i {
  background: #fff;
  border: 1px solid #D0D6E5;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: center;
  color: #6D727C;
  padding: 2px 30px 2px 12px;
  border-radius: 4px;
  position: relative;
}
header .headerTop .headerContent .headerRight .headerRightContent .headerPlan i svg {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0%, -50%);
}
header .headerTop .headerContent .headerRight .headerRightContent .headerUser a {
  text-decoration: unset;
  position: relative;
  padding-left: 27.5px;
  padding-right: 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  letter-spacing: -0.2800000012px;
  text-align: center;
  color: #061020;
}
header .headerTop .headerContent .headerRight .headerRightContent .headerUser a .icon_header_user {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translate(0%, -50%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  /*max-height: 100%;*/
}
header .headerTop .headerContent .headerRight .headerRightContent .headerUser a .icon_header_bottom {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(0%, -50%);
}
header .headerTop .headerContent .headerRight .headerRightContent .headerUser a:hover {
  color: #061020;
}
header .navbar {
  padding: 0px;
  background: #054A9F;
  display: flex;
}
header .navbar .navbar-list {
  margin-bottom: 0px;
  width: calc(100% - 138px);
  display: flex;
  list-style-type: none;
  padding-left: 0px;
}
header .navbar .navbar-list li {
  position: relative;
  padding: 8px 23px;
  @media screen and (max-width: 830px) {
    padding: 8px 14px;
  }
}
header .navbar .navbar-list li a {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: unset;
}
header .navbar .navbar-list li a span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
}
header .navbar .navbar-list li .dropdown-list {
  display: none;
  list-style-type: none;
  position: absolute;
  top: 40px;
  left: 0px;
  width: 280px;
  background: #054A9F;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px 0px;
  z-index: 10000;
}
header .navbar .navbar-list li .dropdown-list .dropdown-list-item {
  position: relative;
  padding: 8px 23px;
}
header .navbar .navbar-list li .dropdown-list .dropdown-list-item a {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: unset;
}
header .navbar .navbar-list li .dropdown-list .dropdown-list-item a span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
}
header .navbar .navbar-list li .dropdown-list .dropdown-list-item:hover {
  background: #0E72ED;
}
header .navbar .navbar-list li:hover {
  background: #0E72ED;
}
header .navbar .navbar-list li:hover .dropdown-list {
  display: block;
}
header .navbar .navbar-list .active {
  background: #0E72ED;
}
header .navbar .navbarSell {
  width: 138px;
  margin-bottom: 0px;
  list-style-type: none;
  padding-left: 0px;
  height: 100%;
}
header .navbar .navbarSell li {
  width: 100%;
  height: 100%;
  background: #FF6B00;
  display: grid;
  justify-content: center;
}
header .navbar .navbarSell li a {
  text-decoration: unset;
  padding: 10px 0px;
  display: flex;
  gap: 8px;
  align-items: center;
}
header .navbar .navbarSell li a span {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #fff;
}
.ant-dropdown-menu-title-content a{
  text-decoration: unset;
}
.ant-dropdown-menu-title-content a:hover{
  text-decoration: unset;
}
.dropdown-header-menu{
  display: block;
  padding: 8px 10px;
}
.noti-pos{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  overflow: auto;
  background: #fff;
}
.menu-mobile{
  position: fixed;
  top: 56.28px;
  left: 0px;
  width: 100%;
  height: calc(100vh - 56.28px);
  background: #fff;
  z-index: 10;
  display: none;
  ul{
    padding-left: 0px;
    list-style-type: none;
    li{
      padding: 0 10px;
      a{
        padding: 10px 0px;
        border-bottom: 1px solid #ecf2f7;
        position: relative;
        display: block;
        img{
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translate(0,-50%);
        }
      }
      div{
        padding: 10px 0px;
        border-bottom: 1px solid #ecf2f7;
        position: relative;
        color: #0d6efd;
        img{
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translate(0,-50%);
        }
      }
      .dropdown-list{
        li{
          padding: 0px;
          a{
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }
    li:first-child{
      a{
        border-top: 1px solid #ecf2f7;
      }
    }
  }
  .hidden{
    display: none;
  }
}
@media screen and (max-width: 800px) {
  header .navbar .navbar-list{
    overflow-x: scroll;
  }
  .main-start-page{
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .container{
    padding: 0px 10px;
  }
  .menu-mobile{
    display: none;
  }
  .menu-mobile-active{
    display: block;
    overflow: auto;
  }
  header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
    .headerTop{
      padding: 15px 0px;
      .headerContent{
        .headerRight{
          display: none;
        }
        .header-right-mobile{
          width: calc(100% - 150px);
          display: grid;
          justify-content: end;
        }
      }
    }
    .navbar{
      display: none;
    }
  }
}
/*# sourceMappingURL=header.css.map */
