@import "../../../../public/assets/css/variables.scss";
.badge {
    padding: 7px 12px;
    font-weight: 600;
    letter-spacing: 0.3px;
    border-radius: 30px;
    font-size: 12px;
    color: #fff;
}
.badge-pending {
    background-color: #f0bd06;
}
.badge-success {
    background-color: #47c363;
}
.badge-refund {
    background-color: #cdd3d8;
}
.badge-cancel {
    background-color: #888888;
}
.badge-failed {
    background-color: #f32e1b;
}

.container {
    .mainContentDetailOrder {
        margin-top: 20px;
        .labelLeft {
            background-color: #ffffff;
            border-radius: 4px;
            .labelLeft-body,
            .labelLeft-footer {
                padding: 20px 25px;
            }

            .labelLeft-body {
                .table {
                    thead {
                        width: 100%;
                        display: inline-block;
                        tr {
                            width: 100%;
                            display: flex;
                            border-bottom: 1px solid #ccc;
                            th {
                                padding: 15px 0px;
                                font-size: 14px;
                                font-weight: 600;
                                text-transform: uppercase;
                                line-height: 19.6px;
                                letter-spacing: -0.2099999934px;
                                text-align: left;
                                color: #757575;
                                border-bottom-width: 0px;
                            }
                        }
                    }
                    tbody {
                        width: 100%;
                        display: inline-block;
                        tr {
                            width: 100%;
                            display: flex;
                            background-color: #fff;
                            td {
                                img {
                                    width: 80px;
                                    height: 80px;
                                    object-fit: cover;
                                }
                            }
                            .title {
                                a {
                                    text-decoration: none;
                                }
                                strong {
                                    color: #6c657d;
                                }
                            }

                            // &:nth-child(odd) {
                            //     background-color: #fff;
                            // }
                        }
                    }
                }

                .list {
                    list-style-type: none;
                    padding-left: 0;
                    .list-item {
                        display: inline-block;
                        width: 100%;
                        padding: 0.75rem 1.25rem;
                        background-color: #fff;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                        .total-price,
                        .total-title {
                            font-size: 22px;
                            font-weight: 600;
                            color: #6c657d;
                        }
                        .total-price {
                            color: #333;
                        }

                        .text-left {
                            text-align: right;
                        }
                        .text-right {
                            text-align: right;
                        }
                    }
                }
            }

            .labelLeft-footer {
                .optionsStatus {
                    width: 100%;
                    gap: 12px;
                    .orderStatus,
                    .paymentStatus {
                        width: 280px;
                        label {
                            font-size: 12px;
                            margin-bottom: 8px;
                        }
                    }
                }

                .optionsBtn {
                    text-align: right;
                    margin-top: 20px;
                    button {
                        outline: none;
                        border: none;
                        box-shadow: 0 2px 6px #acb5f6;
                        background-color: $primary-color;
                        border-color: $primary-color;
                        color: #fff;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 24px;
                        padding: 0.3rem 0.8rem;
                        letter-spacing: 0.5px;
                        &:hover {
                            background-color: #394eea;
                            border-color: transparent;
                        }
                    }
                    .btnPrint,
                    .btnSave {
                        margin-left: 12px;
                    }
                }
            }
        }

        .labelRight {
            gap: 30px;
            .labelStatus {
                padding: 20px 25px;
                border-radius: 4px;
                background-color: #ffffff;
                &-title {
                    color: $primary-color;
                    font-weight: 600;
                }
                &-info {
                    padding-top: 25px;
                    background-color: #ffffff;
                    gap: 12px;
                    span {
                        &:first-child {
                            color: #6c657d;
                        }
                    }
                }
            }
            .labelInfoUser {
                padding: 20px 25px;
                background-color: #ffffff;
                border-radius: 4px;
                border-top: 2px solid $primary-color;
                gap: 20px;
                p {
                    color: #6c657d;
                    a {
                        text-decoration: none;
                    }
                }
            }
        }

        .ant-row {
            .ant-col {
                .loadding {
                    position: fixed;
                    top: 50%;
                    left: 50%;
                    transform: translate(50%, 50%);
                }
            }
        }
    }
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #fff;
}
