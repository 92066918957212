@import "../../../../public/assets/css/variables.scss";
.mainContentAttribute {
    margin-top: 20px;
    .topbar {
        display: flex;
        gap: 20px;
        align-items: center;
        &__filter {
            display: flex;
            gap: 100px;
            width: calc(100% - 138px);
        }
        &__title {
            // width: 232px;
            line-height: 44px;
            font-size: 20px;
            font-weight: 600;
        }
        &__search {
            background-color: white;
            border-radius: 8px;
            padding: 4px;
            display: flex;
            align-items: center;
            gap: 24px;
            width: 440px;
            height: 44px;
            font-size: 14px;
            input {
                border: none;
                margin-left: 4px;
                flex: 1;
                &:focus {
                    border: none;
                    outline: none;
                }
            }
            button {
                border-radius: 8px;
                background-color: #0e72ed;
                outline: none;
                border: none;
                width: 36px;
                height: 36px;
                margin: 0px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                }
            }
            @media screen and (max-width: 850px) {
                width: 400px;
            }
        }
        .btn-add-modal {
            align-self: stretch;
            margin-left: auto;
            margin-right: 2px;
            width: 130px;
        }
        @media screen and (max-width: 850px) {
            .topbar__filter {
                gap: 0;
                width: 100%;
                justify-content: space-between;
            }
            .btn-add-modal {
                white-space: nowrap;
                width: 153px;
            }
        }
    }
    .body-page {
        display: flex;
        margin-top: 20px;
        gap: 16px;
        .filter-tab {
            width: 232px;
            height: fit-content;
            background-color: white;
            padding: 8px 0px;
            border-radius: 8px;
            button {
                width: 100%;
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: space-between;
                color: black;
                span {
                    color: black;
                    font-weight: 600;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            input[type="checkbox"] {
                appearance: none;
                background: transparent;
                position: relative;
            }
            input[type="checkbox"]::after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                text-align: center;
                background: #fff;
                display: block;
                pointer-events: none;
                opacity: 1;
                color: black;
                border: 1.5px solid #6d727c;
            }
            input[type="checkbox"] + label {
                line-height: 24px;
                margin: 0 8px 0 8px;
            }
            input[type="checkbox"]:hover::after {
                //content: '';
                //background: #1E80EF;
                //opacity: .3;
            }
            input[type="checkbox"]:checked::after {
                content: "\2713";
                color: #fff;
                background: $primary-color;
                border: 1.5px solid $primary-color;
                font-size: 12px;
            }
            //input[type=checkbox]:checked:hover::after {
            //    opacity: 1;
            //}
            input[type="checkbox"],
            input[type="checkbox"]::after {
                width: 24px;
                height: 24px;
                font-size: 24px;
                line-height: 20px;
                vertical-align: middle;
                border-radius: 4px;
            }
            .filter-tab__body {
                padding: 0px 10px;
                .filter-tab__option {
                    padding: 8px 0px;
                }
            }
        }
        .content-table {
            flex: 1;
            table {
                th {
                    padding: 12px 10px;
                }
                tbody {
                    tr {
                        border-bottom: 1px solid #ecf2f7;
                    }
                    td {
                        padding: 16px 10px;
                        border-bottom: none;
                    }
                    .slug {
                        max-width: 400px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        @media screen and (max-width: 1080px) {
                            max-width: 112px;
                        }
                    }
                    .attr {
                        &__container {
                            display: flex;
                            gap: 5px;
                            flex-wrap: wrap;
                        }
                        &__item {
                            padding: 5px 10px;
                            border-radius: 30px;
                            background-color: #51bd36;
                            color: white;
                            @media screen and (max-width: 1000px) {
                                max-width: 112px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    .name {
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 8px;
                            object-position: center;
                            object-fit: cover;
                        }
                        span {
                            font-size: 16px;
                            font-weight: 600;
                            display: inline-block;
                            max-width: 400px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            @media screen and (max-width: 1080px) {
                                max-width: 112px;
                            }
                        }
                    }
                    .action-table {
                        button.ant-btn-text {
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            &:hover {
                                background-color: white;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1000px) {
            overflow-x: auto;
        }
    }
    .pagination {
        margin: 0px !important;
        li {
            padding: 0px;
            width: 32px;
            height: 32px;
            overflow: hidden;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disabled {
                pointer-events: none;
                a {
                    color: #d0d6e5;
                    border-color: #d0d6e5;
                }
            }
            a {
                text-decoration: none;
            }
            &.previous {
                a {
                    i::before {
                        margin-right: 1px;
                    }
                }
            }
            &.next {
                a {
                    i::before {
                        margin-left: 1px;
                    }
                }
            }
            i {
                &::before {
                    display: block;
                }
            }
        }
        &__container {
            margin: 20px 0px 12px;
            display: flex;
            gap: 20px;
            align-items: center;
        }

        &__info {
            font-size: 14px;
            line-height: 19.6px;
            font-weight: 400;
        }
    }
}
.attribute-item-field {
    margin: 10px 0px;
    .item-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.1000000015px;
        text-align: left;
        color: #061020;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .item-form {
        max-height: 450px;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
        .input-item {
            padding: 5px 10px;
            &__label {
            }
            &__content {
                display: flex;
                padding: 5px 0px;
                gap: 10px;
                input {
                    border: 1px solid #d0d6e5;
                    border-radius: 8px;
                    padding: 20px 10px !important;
                }
                button.deleteItem {
                    padding: 10px 14px;
                    border-radius: 8px;
                    background: #ff0d0d;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    letter-spacing: -0.2800000012px;
                    text-align: center;
                    color: #ffffff;
                    border: none;
                }
            }
        }
    }
}
button.addItem {
    width: fit-content;
    padding: 10px 12px;
    gap: 4px;
    border-radius: 8px;
    background: #0e72ed;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    letter-spacing: -0.2800000012px;
    text-align: center;
    color: #ffffff;
    border: none;
    display: flex;
    gap: 5px;
    align-items: center;
}
.error-text {
    color: red;
    margin: 6px 0px;
}
