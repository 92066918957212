.SidebarSettings{
    height: calc(100vh - 125px);
    overflow-y: auto;
    background-color: #FFF;
    box-shadow: 5px 4px 14px 0px #2C53640F;
    border-radius: 8px;
    position: sticky;
    top: 10px;
    
    &-body{
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }
    &-item{
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 22px 12px;
        h1{
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.03px;
            color: #061020;
            font-size: 16px;
            margin-bottom: 0;
        }
        img{
            width: 24px;
            height: 24px;
        }
        &:hover{
            background-color: #ECF2F7;
            cursor: pointer;
            h1{
                color: #0E72ED !important;
            }
        }
        &.active{
            background-color: #ECF2F7;
           h1{
               font-weight: 600;
               color: #0E72ED !important;
           }
        }
    }
    .ant-menu{
        width: 100% !important;
        border-radius: 8px;
        .ant-menu-title-content{
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.03px;
            color: #061020;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    .ant-menu-item,.ant-menu-submenu-title{
        padding-left: 8px !important;
        padding-top: 28px;
        padding-bottom: 28px;
    }
    .ant-menu-submenu{
        .ant-menu-item{
            padding-left: 42px !important;
        }
    }
    .ant-menu-item-selected{
        .ant-menu-title-content{
            font-weight: 600;
            color: #0E72ED !important;
        }
    }
}