body {
    height: 100vh;
}

.root {
    height: 100vh;
}

.login {
    height: 100vh;
    background-image: url("/public/assets/images/bg_img_login_2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        display: flex;
        justify-content: center;
        align-items: center;

        .loginForm {
            color: #333;
            width: 1000px;
            margin-bottom: 10px;
            .loginFormSignIn {
                .loginFormSigninLeft {
                    width: 50%;
                    height: 500px;
                    background-color: #fff;
                    padding: 50px 50px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                    .back {
                        cursor: pointer;
                        text-decoration: underline;
                        margin-bottom: 10px;
                    }

                    .icon {
                        text-align: left;
                        img {
                            width: 135px;
                        }
                    }

                    .heading {
                        color: #333;
                        margin-bottom: 24px;
                        p {
                            margin-bottom: 0;
                            &:first-child {
                                font-size: 24px;
                                font-weight: 600;
                            }
                        }
                    }

                    .idTenant {
                        .formIdTenant {
                            height: 45px;
                            border: 1px solid #e4e4e4;
                            background: #f8f8f8;
                            border-radius: 4px;
                            padding: 2px 12px;
                            margin-bottom: 10px;
                            margin-bottom: 24px;
                            input {
                                color: #333;
                                &:focus {
                                    &::placeholder {
                                        opacity: 0.7;
                                    }
                                }
                                &::placeholder {
                                    color: #aab2bb;
                                }
                            }
                            &.--forgot{
                                margin-bottom: 12px !important;
                            }
                        }

                        input {
                            background-color: unset !important;
                            border: none;
                            outline: none;
                            &:focus {
                                box-shadow: none;
                                border-color: #fff;
                            }
                        }

                        .btnSubmit {
                            height: 45px;
                            background-color: #0e72ed;
                            width: 100%;
                            border: none;
                            outline: none;
                            border-radius: 6px;
                            padding: 6px 12px;
                            color: #fff;
                            transition: 0.2s ease-in;
                            font-weight: 600;

                            &:hover {
                                background-color: #054a9f;
                                border: 1px solid #0e72ed;
                                color: #fff;
                            }

                            .loadding {
                                span {
                                    font-size: 5px;
                                }
                            }
                        }
                    }

                    .account {
                        text-align: right;
                        input {
                            background-color: unset !important;
                            border: none;
                            outline: none;
                            &:focus {
                                box-shadow: none;
                                border-color: #fff;
                            }
                        }

                        .formUser {
                            height: 45px;
                            border: 1px solid #e4e4e4;
                            background: #f8f8f8;
                            border-radius: 4px;
                            padding: 2px 12px;
                            margin-bottom: 10px;
                            input {
                                color: #333;
                                &:focus {
                                    &::placeholder {
                                        opacity: 0.7;
                                    }
                                }
                                &::placeholder {
                                    color: #aab2bb;
                                }
                            }
                        }

                        .formPassword {
                            height: 45px;
                            border: 1px solid #e4e4e4;
                            background: #f8f8f8;
                            border-radius: 4px;
                            padding: 2px 12px;
                            margin-bottom: 10px;
                            color: #316cf4;
                            margin-bottom: 24px;
                            input {
                                color: #333;
                                &:focus {
                                    &::placeholder {
                                        opacity: 0.7;
                                    }
                                }
                                &::placeholder {
                                    color: #aab2bb;
                                }
                            }
                        }

                        .btnSubmit {
                            height: 45px;
                            background-color: #0e72ed;
                            width: 100%;
                            border: none;
                            outline: none;
                            border-radius: 6px;
                            padding: 6px 12px;
                            color: #fff;
                            transition: 0.2s ease-in;
                            font-weight: 600;

                            &:hover {
                                background-color: #054a9f;
                                border: 1px solid #0e72ed;
                                color: #fff;
                            }

                            .loadding {
                                span {
                                    font-size: 5px;
                                }
                            }
                        }

                        .forgetPassword {
                            padding-top: 12px;
                            cursor: pointer;
                        }
                    }

                    .formForgetPass {
                        width: 100%;
                        text-align: right;
                        input {
                            background-color: unset !important;
                            border: none;
                            outline: none;
                            &:focus {
                                box-shadow: none;
                                border-color: #fff;
                            }
                        }

                        .formUser {
                            width: 100%;
                            height: 45px;
                            border: 1px solid #e4e4e4;
                            background: #f8f8f8;
                            border-radius: 4px;
                            padding: 2px 12px;
                            margin-bottom: 10px;
                            color: #316cf4;
                            input {
                                color: #333;
                                &:focus {
                                    &::placeholder {
                                        opacity: 0.7;
                                    }
                                }
                                &::placeholder {
                                    color: #aab2bb;
                                }
                            }
                        }

                        .btnSubmit {
                            height: 45px;
                            background-color: #0e72ed;
                            width: 100%;
                            border: none;
                            outline: none;
                            border-radius: 6px;
                            padding: 6px 12px;
                            color: #fff;
                            transition: 0.2s ease-in;
                            font-weight: 600;

                            &:hover {
                                background-color: #054a9f;
                                border: 1px solid #0e72ed;
                                color: #fff;
                            }

                            .loadding {
                                span {
                                    font-size: 6px;
                                }
                            }
                        }

                        span {
                            padding-top: 12px;
                            cursor: pointer;
                        }
                    }
                }

                .loginFormSigninRight {
                    width: 50%;
                    height: 500px;
                    background-color: white;
                    padding: 50px 50px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-left: 2px solid #f2f2f2;
                    .imgWrapper {
                        img {
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                    .imgHeading {
                        text-align: center;
                        gap: 16px;

                        p {
                            font-weight: bold;
                            margin-bottom: 0;
                        }

                        span {
                            font-size: 14px;
                        }

                        button {
                            border: none;
                            outline: none;
                            background-color: #ecf7fe;
                            padding: 6px 18px;
                            color: #316cf4;
                            border-radius: 100px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: unset;
    background-color: unset;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
@media screen and (max-width:650px){
    .login .container .loginForm .loginFormSignIn {
        .loginFormSigninLeft{
            height: auto;
            width: 100%;
            padding: 20px;
        }
        .loginFormSigninRight{
            display: none!important;
        }
    }
}