.modalChangePassword{
    &.disabled-modal{
        pointer-events: none;
    }
    .ant-modal{
        display: flex;
    justify-content: center;
    }
    .ant-modal .ant-modal-content{
        width: 567px;
    }
    .ant-input-affix-wrapper{
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }
    .ant-input{
        font-size: 16px !important;
    }
   
}