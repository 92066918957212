@import "../../../../../public/assets/css/variables.scss";
.mainContentBrand {
    margin-top: 20px;
    .header {
        display: flex;
        gap: 20px;
        align-items: center;
        &__filter {
            width: calc(100% - 138px);
            display: flex;
            gap: 100px;
            @media screen and (max-width: 850px) {
                width: 100%;
                gap: 0;
                justify-content: space-between;
            }
        }
        &__title {
            line-height: 44px;
            font-size: 20px;
            font-weight: 600;
        }
        &__search {
            background-color: #fff;
            border-radius: 8px;
            padding: 4px;
            display: flex;
            align-items: center;
            gap: 24px;
            width: 440px;
            height: 44px;
            input {
                border: none;
                margin-left: 4px;
                flex: 1;
                &:focus {
                    border: none;
                    outline: none;
                }
                &::placeholder {
                    font-size: 14px;
                }
            }
            button {
                border-radius: 8px;
                background-color: #0e72ed;
                outline: none;
                border: none;
                width: 36px;
                height: 36px;
                margin: 0px;
                padding: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                }
            }
            @media screen and (max-width: 850px) {
                width: 400px;
            }
        }
        .btn-add-modal {
            align-self: stretch;
            margin-left: auto;
            margin-right: 2px;
            span {
                white-space: nowrap;
            }
            @media screen and (max-width: 850px) {
                width: 153px !important;
            }
        }
    }
    .body-page {
        display: flex;
        margin-top: 20px;
        gap: 16px;
        .filter-tab {
            width: 232px;
            height: fit-content;
            background-color: white;
            padding: 8px 0px;
            border-radius: 8px;
            button {
                width: 100%;
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: space-between;
                color: black;
                span {
                    color: black;
                    font-weight: 600;
                }
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            input[type="radio"] {
                appearance: none;
                background: transparent;
                position: relative;
            }
            input[type="radio"]::after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                text-align: center;
                background: #fff;
                display: block;
                pointer-events: none;
                opacity: 1;
                color: black;
                border: 1.5px solid #6d727c;
            }
            input[type="radio"] + label {
                line-height: 24px;
                margin: 0 8px 0 8px;
            }
            input[type="radio"]:hover::after {
                //content: '';
                //background: #1E80EF;
                //opacity: .3;
            }
            input[type="radio"]:checked::after {
                content: "\2713";
                color: #fff;
                background: $primary-color;
                border: 1.5px solid $primary-color;
                font-size: 12px;
            }
            //input[type=checkbox]:checked:hover::after {
            //    opacity: 1;
            //}
            input[type="radio"],
            input[type="radio"]::after {
                width: 16px;
                height: 16px;
                font-size: 16px;
                border-radius: 50%;
                line-height: 14px;
                vertical-align: middle;
            }
            .filter-tab__body {
                padding: 0px 10px;
                .filter-tab__option {
                    padding: 8px 0px;
                    display: flex;
                    align-items: center;
                    label,
                    input {
                        cursor: pointer;
                    }
                }
            }
        }
        .content-table {
            flex: 1;
            table {
                tbody {
                    .name {
                        display: flex;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 8px;
                            object-position: center;
                            object-fit: cover;
                        }
                        span {
                            margin-left: 12px;
                            font-size: 16px;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 300px;
                            overflow: hidden;
                            flex: 1;
                            display: inline-block;
                            @media screen and (max-width: 850px) {
                                max-width: 90px;
                            }
                        }
                    }
                    .action-table {
                        button.ant-btn-text {
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            &:hover {
                                background-color: white;
                            }
                        }
                    }
                    tr {
                        td {
                            span {
                                display: inline-block;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 900px) {
            overflow-y: auto;
        }
    }
    .pagination {
        margin: 0px !important;
        li {
            padding: 0px;
            width: 32px;
            height: 32px;
            overflow: hidden;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disabled {
                pointer-events: none;
                a {
                    color: #d0d6e5;
                    border-color: #d0d6e5;
                }
            }
            a {
                text-decoration: none;
            }
            &.previous {
                a {
                    i::before {
                        margin-right: 1px;
                    }
                }
            }
            &.next {
                a {
                    i::before {
                        margin-left: 1px;
                    }
                }
            }
            i {
                &::before {
                    display: block;
                }
            }
        }
        &__container {
            margin: 20px 0px 12px;
            display: flex;
            gap: 20px;
            align-items: center;
        }

        &__info {
            font-size: 14px;
            line-height: 19.6px;
            font-weight: 400;
        }
    }
}

.ant-modal-body {
    > .item-form {
        margin-top: 12px;
        margin-bottom: 20px;
    }
    > .upload-form {
        margin-top: 10px;
    }
}
form {
    .field-title {
        padding: 12px 0px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
    }
    .ant-form-item {
        border-radius: 8px;
        margin-bottom: 0px;
        .ant-form-item-row {
            display: block;
            .ant-form-item-label {
                text-align: start;
                label {
                    height: auto;
                    color: #494c5d;
                    font-weight: 500;
                    &.ant-form-item-required {
                        &::before {
                            display: none;
                        }
                        &::after {
                            content: "*";
                            color: #ff0d0d;
                        }
                    }
                }
            }
            .ant-form-item-control {
                text-align: start;
                .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                        textarea,
                        input {
                            border: none;
                            margin: 0;
                            outline: 0px;
                        }
                        .ant-select-selector {
                            width: 100%;
                            .ant-select-selection-search {
                                input {
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ant-form-item-control-input-content {
    height: fit-content;
}
.msg-error {
    color: #ff0d0d !important;
}
span.active {
    background-color: #58c35a;
    border-radius: 12px;
    padding: 5.5px 10px 4.5px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
}
span.inactive {
    background-color: #888888;
    border-radius: 12px;
    padding: 5.5px 10px 4.5px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
}

.item-selected {
    .ant-select-selector {
        cursor: pointer !important;
        input {
            pointer-events: none !important;
            caret-color: transparent !important;
        }
    }
}
