.BoxPayment{
    display: flex;
    column-gap: 20px;
    @media (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
    }
    &-infor{
        display: flex;
        align-items: start;
        column-gap: 20px;
        padding: 20px;
        img{
            width: 80px;
            height: 80px;
            border-radius: 4px;
            object-fit: contain;
        }
        &__content{
            h1{
                color: #000;
                font-size: 20px;
            }
            p{
                line-height: 28px;
                width: 272px;
                color: #6C757D;
                font-size: 14px;
                text-align: left;
                margin-bottom: 0;
                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }
    &-form{
        width: calc(100% - 372px);
        @media (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
        }
    }
}