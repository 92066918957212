.DetailMachinePosContainer{
  &.disableScreen{
    pointer-events: none;
  }
  .status{
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    text-align: center;
    color: #fff;
    padding: 4px 10px 4px 10px;
    gap: 4px;
    border-radius: 12px;
    width: 60px;
    white-space: nowrap;
    &--open{
      background-color: #0E72ED;
      color: #FFF;
    }
    &--close{
      background-color: #888888;
      color: #FFF;
    }
  }
    .filter-search{
        width: 310px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        input{
          width: calc(100% - 36px);
          border: unset;
          border: unset;
          font-size: 14px;
          font-weight: 400;
          line-height: 19.6px;
          letter-spacing: -0.2800000011920929px;
          text-align: left;
          color: #061020;
          padding-left: 10px;
          &:focus{
            outline: unset;
          }
        }
        button{
          width: 36px;
          background: #0E72ED;
          height: 36px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: unset;
        }
    }
    .ant-select{
      width: 160px;
      height: 41px;
      margin-top: 4px;
    }
    .staff{
      img{
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
    }
    .data-row{
      cursor: pointer;
    }
  .table-container{
    tr{
      th{
        padding-left: 10px;
      }
    }
  }
  .ant-select-selection-search{
    padding-left: 10px;
  }
}
@media screen and (max-width:1200px){
  .BoxFilterSessionDetails{
    .left{
      gap: 10px;
      padding-right: 20px;
      width: calc(100% - 120px);
      display: grid;
      grid-template-columns: repeat(2,1fr);
      .filter-search{
        width: 100%;
      }
      .ant-picker{
        width: 100%;
      }
      .item-selected .ant-select{
        width: 100%;
      }
    }
    .right{

    }
  }
  .DetailMachinePosContainer .ant-select{
    width: 100%;
  }
}