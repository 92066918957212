body {
  height: 100vh;
}

.root {
  height: 100vh;
}

.login {
  height: 100vh;
  background-image: url("/public/assets/images/bg_img_login_2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.login .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .container .loginForm {
  color: #333;
  width: 1000px;
  margin-bottom: 10px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft {
  width: 50%;
  height: 500px;
  background-color: #fff;
  padding: 50px 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .back {
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 10px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .icon {
  text-align: left;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .icon img {
  width: 135px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .heading {
  color: #333;
  margin-bottom: 24px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .heading p {
  margin-bottom: 0;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .heading p:first-child {
  font-size: 24px;
  font-weight: 600;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .formIdTenant {
  height: 45px;
  border: 1px solid #e4e4e4;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 2px 12px;
  margin-bottom: 10px;
  margin-bottom: 24px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .formIdTenant input {
  color: #333;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .formIdTenant input:focus::placeholder {
  opacity: 0.7;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .formIdTenant input::placeholder {
  color: #aab2bb;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .formIdTenant.--forgot {
  margin-bottom: 12px !important;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant input {
  background-color: unset !important;
  border: none;
  outline: none;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant input:focus {
  box-shadow: none;
  border-color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .btnSubmit {
  height: 45px;
  background-color: #0e72ed;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 6px 12px;
  color: #fff;
  transition: 0.2s ease-in;
  font-weight: 600;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .btnSubmit:hover {
  background-color: #054a9f;
  border: 1px solid #0e72ed;
  color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .idTenant .btnSubmit .loadding span {
  font-size: 5px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account {
  text-align: right;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account input {
  background-color: unset !important;
  border: none;
  outline: none;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account input:focus {
  box-shadow: none;
  border-color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formUser {
  height: 45px;
  border: 1px solid #e4e4e4;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 2px 12px;
  margin-bottom: 10px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formUser input {
  color: #333;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formUser input:focus::placeholder {
  opacity: 0.7;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formUser input::placeholder {
  color: #aab2bb;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formPassword {
  height: 45px;
  border: 1px solid #e4e4e4;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 2px 12px;
  margin-bottom: 10px;
  color: #316cf4;
  margin-bottom: 24px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formPassword input {
  color: #333;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formPassword input:focus::placeholder {
  opacity: 0.7;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .formPassword input::placeholder {
  color: #aab2bb;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .btnSubmit {
  height: 45px;
  background-color: #0e72ed;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 6px 12px;
  color: #fff;
  transition: 0.2s ease-in;
  font-weight: 600;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .btnSubmit:hover {
  background-color: #054a9f;
  border: 1px solid #0e72ed;
  color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .btnSubmit .loadding span {
  font-size: 5px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .account .forgetPassword {
  padding-top: 12px;
  cursor: pointer;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass {
  width: 100%;
  text-align: right;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass input {
  background-color: unset !important;
  border: none;
  outline: none;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass input:focus {
  box-shadow: none;
  border-color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .formUser {
  width: 100%;
  height: 45px;
  border: 1px solid #e4e4e4;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 2px 12px;
  margin-bottom: 10px;
  color: #316cf4;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .formUser input {
  color: #333;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .formUser input:focus::placeholder {
  opacity: 0.7;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .formUser input::placeholder {
  color: #aab2bb;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .btnSubmit {
  height: 45px;
  background-color: #0e72ed;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  padding: 6px 12px;
  color: #fff;
  transition: 0.2s ease-in;
  font-weight: 600;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .btnSubmit:hover {
  background-color: #054a9f;
  border: 1px solid #0e72ed;
  color: #fff;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass .btnSubmit .loadding span {
  font-size: 6px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninLeft .formForgetPass span {
  padding-top: 12px;
  cursor: pointer;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight {
  width: 50%;
  height: 500px;
  background-color: white;
  padding: 50px 50px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 2px solid #f2f2f2;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight .imgWrapper img {
  width: 100%;
  max-width: 100%;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight .imgHeading {
  text-align: center;
  gap: 16px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight .imgHeading p {
  font-weight: bold;
  margin-bottom: 0;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight .imgHeading span {
  font-size: 14px;
}
.login .container .loginForm .loginFormSignIn .loginFormSigninRight .imgHeading button {
  border: none;
  outline: none;
  background-color: #ecf7fe;
  padding: 6px 18px;
  color: #316cf4;
  border-radius: 100px;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: unset;
  background-color: unset;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media screen and (max-width: 650px) {
  .login .container .loginForm .loginFormSignIn .loginFormSigninLeft {
    height: auto;
    width: 100%;
    padding: 20px;
  }
  .login .container .loginForm .loginFormSignIn .loginFormSigninRight {
    display: none !important;
  }
}

