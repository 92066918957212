.ProductInPosControl {
    width: 520px;
    background-color: #fff;
    //grid-column: span 1 / span 1;
    height: 100%; // Đảm bảo chiều cao cha là 100%
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.disabled{
        pointer-events: none;
    }
    .ProductInPosControl-header {
        margin: 10px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ecf2f7;
        border-radius: 8px;
        img {
            padding-left: 8px;
        }
        .camera {
            cursor: pointer;
        }
        input {
            width: 100%;
            margin-left: 6px;
            border: none;
            outline: none;
        }
    }
    .ProductInPosControl-body {
        height: calc(100vh - 550px);
        overflow-y: auto;
        flex: 1;
        .emptyCart {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            p {
                margin-top: 5px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.6px;
                letter-spacing: -0.2099999934px;
                text-align: left;
                color: #bababa;
            }
        }
        .ProductInPosControl-product {
            display: flex;
            align-items: start;
            justify-content: space-between;
            padding: 8px 12px 12px 12px;
            border-bottom: 1px solid #ecf2f7;
            margin-right: 8px;
            margin-left: 8px;
            cursor: pointer;
            flex-wrap: wrap;
            &:hover {
                background-color: #ecf2f7;
            }
            &.active {
                background-color: #ecf2f7;
            }
            .boxProduct-infor {
                display: flex;
                flex-direction: column;
                row-gap: 4px;
                width: 75%;
                h2 {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 130%;
                    letter-spacing: -0.1000000015px;
                    text-align: left;
                    color: #000000;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    height: 20px;
                    margin-bottom: 4px;
                }
                p {
                    margin-bottom: 0px;
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000012px;
                    text-align: left;
                }
            }
            .boxProduct-total {
                width: 25%;
                font-size: 16px;
                font-weight: 600;
                line-height: 19.36px;
                letter-spacing: -0.1000000015px;
                text-align: right;
                color: #000000;
                margin-bottom: 0px;
            }
            .boxProduct-note {
                width: 100%;
                background-color: #FFDAA3;
                padding: 2px 4px;
                gap: 4px;
                display: flex;
                align-items: center;
            }
        }
    }
    .ProductInPosControl-booking {
        justify-content: center;
        align-items: center;
        background-color: #0e72ed;
        width: 100%;
        padding: 12px 0;
        cursor: pointer;
        border: none;
        outline: none;
        span {
            color: white;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .ProductInPosControl-footer {
        background-color: rgb(244, 246, 249);
        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 2px;
            &-item {
                cursor: pointer;
                padding: 14px 20px;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.6px;
                letter-spacing: -0.2099999934px;
                text-align: left;
                color: #061020;
                background-color: #fff;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                .quantity-customer {
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: -0.1799999923px;
                    color: #ffffff;
                    background: #061020;
                    border-radius: 100%;
                    margin-right: 4px;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                img {
                    margin-right: 4px;
                }
                &:hover {
                    background-color: rgb(244, 246, 249);
                    transition: 0.3s;
                }
            }
        }
        .total-price {
            background-color: #fff;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: end;
            column-gap: 8px;
            padding: 10px 10px 14px;
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000012px;
                text-align: left;
                color: #6d727c;
                margin-bottom: 0;
            }
            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 21.78px;
                text-align: left;
                color: #000000;
                margin-bottom: 0;
            }
        }
    }
    .caculator {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        margin-top: 2px;
        &-number {
            display: grid;
            grid-column: span 2 / span 2;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 2px;
            &__Minbox {
                background: #fff;
                font-size: 24px;
                font-weight: 400;
                line-height: 29.05px;
                letter-spacing: -1.4400000572px;
                text-align: left;
                color: #061020;
                height: 53px;
                width: 100%;
                text-decoration: unset;
                display: grid;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: 0.3s;
                &:hover {
                    background-color: rgb(244, 246, 249);
                    transition: 0.3s;
                }
                &.active {
                    background-color: rgb(244, 246, 249);
                    transition: 0.3s;
                }
            }
        }
        &-action {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 2px;
            .caculator-payment {
                width: 100%;
                background: #0e72ed;
                color: white;
                font-size: 16px;
                padding: 8px;
                text-align: center;
                cursor: pointer;
                img {
                    width: 30px;
                }
            }
            .caculator-order {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                // background: #0E72ED;
                font-size: 22px;
                color: white;
                cursor: pointer;
            }
        }

    }
}
@media screen and (max-width: 900px) {
    .ProductInPosControl .caculator-number__Minbox {
        font-size: 18px !important;
    }
    .ProductInPosControl .ProductInPosControl-footer .action-item {
        font-size: 13px !important;
    }
    .ProductInPosControl
        .ProductInPosControl-body
        .ProductInPosControl-product
        .boxProduct-infor {
        h2 {
            margin-bottom: 0px !important;
            height: 17px !important;
            font-size: 13px !important;
        }
        p {
            font-size: 12px !important;
        }
    }
}
@media screen and (max-width:600px){
    .ProductInPosControl .caculator-action{
        display: none;
    }
    .ProductInPosControl .caculator{
        grid-template-columns:1fr
    }
    .ProductInPosControl .ProductInPosControl-footer .action-item{
        font-size: 11px!important;
    }
}