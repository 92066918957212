.main-setting-page {
  background: #fff;
  margin-top: 12px;
  padding: 20px;
  border-radius: 8px;
}
.main-setting-page .title {
  font-size: 28px;
  text-align: left;
  font-weight: 600;
  line-height: 130%;
  border-bottom: 1px solid #D0D6E5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.main-setting-page .content-setting-page .item-setting-page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #D0D6E5;
}
.main-setting-page .content-setting-page .item-setting-page:first-child {
  padding-top: 0px;
  border-top: 0px;
  margin-top: 0px;
}
.main-setting-page .content-setting-page .item-setting-page .left-item-setting-page .title-item {
  font-weight: 600;
}
.main-setting-page .content-setting-page .item-setting-page .left-item-setting-page .summary-item {
  font-weight: 400;
  margin-top: 5px;
}
.main-setting-page .content-setting-page .item-setting-page .right-item-setting-page {
  display: grid;
  gap: 20px;
}
.main-setting-page .content-submitFormPermissions {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 30px;
  gap: 20px;
}

@media screen and (max-width: 768px) {
  .main-setting-page .content-setting-page .item-setting-page {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 550px) {
  .main-setting-page .content-submitFormPermissions {
    grid-template-columns: 1fr;
  }
  .main-setting-page .content-submitFormPermissions div:first-child {
    display: none;
  }
  .main-setting-page .content-submitFormPermissions .submitFormPermissions {
    width: 100%;
  }
}

