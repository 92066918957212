.mainContentOrder {
    padding-top: 20px;
    display: flex;
    gap: 12px;
    .filter {
        flex-basis: 230.31px;
        border-radius: 4px;

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: left;
            margin-bottom: 11px;
            padding: 10px 0px;
        }

        .filterWrapper {
            position: sticky;
            top: 10px;
            left: 0;
            overflow-y: scroll;
            height: calc(100vh - 170px);
            &::-webkit-scrollbar-track {
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar {
                width: 8px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #a8a8a8;
            }

            .filterOrder {
                padding: 12px;
                border-radius: 4px;
                background-color: #ffffff;
                font-size: 16px;
                margin-bottom: 12px;
                &:last-child{
                    margin-bottom: 0px;
                }
                span {
                    font-size: 16px;
                }
                .filterOrder-datePicker {
                    .ant-picker {
                        .ant-picker-input {
                            input {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            .btnClearFilter {
                border: none;
                outline: none;
                width: 100%;
                padding: 4px 12px;
                background-color: #fff;
                border-radius: 4px;
                margin-bottom: 12px;
                text-align: center;
                span {
                    color: red;
                }
            }
        }
    }

    .content {
        flex: 1;
        border-radius: 4px;
        overflow-y: hidden;
        .contentSearch {
            display: grid;
            grid-template-columns: 1fr 1.4fr;
            height: 44.19px;
            width: 100%;
            margin-bottom: 10px;
            .content-page-header-left {
                display: grid;
                gap: 12px;
                grid-template-columns: 1.4fr 1fr;
                .content-page-header-search {
                    border-radius: 4px;
                    background: #ffffff;
                    display: flex;
                    padding: 4px;
                    input {
                        width: calc(100% - 36px);
                        border: unset;
                        border: unset;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.6px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #061020;
                        &:focus {
                            outline: unset;
                        }
                    }
                    button {
                        width: 36px;
                        background: #0e72ed;
                        height: 36px;
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: unset;
                    }
                }
                .content-page-header-sorting {
                    display: grid;
                    align-items: center;
                    button {
                        width: 36px;
                        height: 36px;
                        background: #bbd9ff;
                        border-radius: 6.55px;
                        display: grid;
                        align-items: center;
                        justify-content: center;
                        border: unset;
                    }
                }
            }
            .content-page-header-right {
                display: flex;
                gap: 12px;
                justify-content: end;
                .content-page-header-right-plus {
                    background: #0e72ed;
                    border-radius: 8px;
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: unset;
                    justify-content: center;
                    padding: 0px 16px 0px 12px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #ffffff;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
                .content-page-header-right-import {
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: 1px solid #0e72ed;
                    justify-content: center;
                    background: #0e72ed;
                    background: unset;
                    padding: 0px 16px 0px 12px;
                    border-radius: 8px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #0e72ed;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
                .content-page-header-right-export {
                    width: auto;
                    display: grid;
                    align-items: center;
                    border: 1px solid #0e72ed;
                    justify-content: center;
                    background: #0e72ed;
                    background: unset;
                    padding: 0px 16px 0px 12px;
                    border-radius: 8px;
                    span {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 16.94px;
                        letter-spacing: -0.2800000011920929px;
                        text-align: left;
                        color: #0e72ed;
                        position: relative;
                        padding-left: 26px;
                        img {
                            position: absolute;
                            top: -4px;
                            left: 0px;
                        }
                    }
                }
            }
        }

        .contentListOrder {
            background-color: #ffffff;
            border-radius: 4px;
            .table-responsive {
                thead {
                    tr {
                        th {
                            padding: 12px 10px;
                            align-content: center;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 14px;
                            span {
                                padding: 3px 10px;
                                border-radius: 12px;
                                color: white;
                                font-size: 12px;
                                display: inline-block;
                                white-space: nowrap;
                            }
                            p {
                                text-align: center;
                            }
                            .price {
                                color: #00bd40;
                            }
                            .ordermethod {
                                background-color: #ff8541;
                            }
                            .infoPaymentmethod {
                                background-color: #f0bd06;
                            }
                            .infoPaymentmethod-success {
                                background-color: #58c35a;
                            }
                            .infoPaymentmethod-cancel {
                                background-color: #888888;
                            }
                            .infoPaymentmethod-refund {
                                background-color: #d6d3d3;
                            }
                            .infoPaymentmethod-failed {
                                background-color: #f32e1b;
                            }
                            .orderType-web {
                                background-color: #06c1fd;
                                text-transform: uppercase;
                            }
                            .orderType-pos {
                                background-color: #f0bd06;
                                text-transform: uppercase;
                            }
                            .infoOrdertype-success {
                                background-color: #58c35a;
                            }
                            .infoOrdertype-cancel {
                                background-color: #888888;
                            }
                            .infoOrdertype-refund {
                                background-color: #d6d3d3;
                            }
                            .infoOrdertype-pending {
                                background-color: #f0bd06;
                            }
                            .seeDetail {
                                background-color: #0e72ed;
                                border-radius: 8px;
                                max-height: 40px;
                                text-align: center;
                                line-height: 100%;
                                padding: 12px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
span {
    display: inline-block;
    white-space: nowrap;
}
@media screen and (max-width: 1600px) {
    .mainContentOrder .content .contentSearch .content-page-header-left {
        grid-template-columns: 1fr;
    }
    .mainContentOrder .content .contentListOrder .table-responsive tbody tr td {
        .ordermethod {
            display: block;
        }
        .infoPaymentmethod-success {
            display: block;
        }
        .infoPaymentmethod-success {
            display: block;
        }
        .orderType {
            display: block;
        }
        .infoOrdertype-success {
            display: block;
        }
        .infoPaymentmethod {
            display: block;
        }
        .infoPaymentmethod-failed {
            display: block;
        }
        .info-type {
            display: block;
        }
    }
}
@media screen and (max-width: 1320px) {
    .mainContentOrder {
        .table-responsive {
            overflow: auto;
            table {
                width: 1200px;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .mainContentOrder .content .contentSearch {
        grid-template-columns: 1fr;
    }
}
