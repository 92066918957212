.NotificationInHeader{
    width: 400px;
    height: 100%;
    position: relative;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 14px;
        h1{
            margin-bottom: 0px;
            position: relative;
            font-size: 22px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.1000000015px;
            text-align: left;
            color: #061020;
        }
        .readAll{
            display: flex;
            align-items: center;
            column-gap: 6px;
            outline: none;
            border: none;
            cursor: pointer;
            background-color: transparent;
            p{
                margin-bottom: 0;
            }
            i{
                color: rgb(5,74,159);
            }
            &.unActive{
                color: #d7d7d7;
                cursor: not-allowed;
                i{
                    color: #d7d7d7;
                }
            }
        }
    }
    .body-container{
        display: flex;
        flex-direction: column;
        // row-gap: 10px;
        padding-bottom: 10px;
        height: 430px;
        overflow-y: auto;
        .no-notice{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            justify-content: center;
            align-items: center;
            height: 100%;
            p{
                text-align: center;
            }
        }
    }
    .body{
        &:hover{
            background-color: #F6FAFF;
            border-radius: 8px;
        }
        &.active{
            background-color: #F6FAFF;
        }
        display: flex;
        align-items: start;
        column-gap: 10px;
        cursor: pointer;
        padding-left: 10px;
       padding: 10px;

       border-bottom: 1px solid #F0F2F5;
       &:last-child{
        border: unset;
       }
       
        .itemNotiIcon{
            &.read{
                background: #F0F2F5;
            }
            background: #D5E7FF;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: grid;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
        }
        .content-noti{
            width: 335px;
            p{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.0320000015px;
                text-align: left;
                color: #000000;
                width: 100%;
                span{
                    font-weight: 500;
                  }
            }
            .time{
                display: flex;
                align-items: center;
                column-gap: 6px;
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.41px;
                text-align: left;
                color: #6F767E;
                margin-top: 4px;
                img{
                    margin-top: 2px;
                }
            }
            .branch{
                font-size: 14px;

            }
        }
    }
    .loadNotice{
        &-bottom{
            position: absolute;
            bottom: 0px;
            right: 16px;
        }

        padding: 14px;
        background-color: #FFF;
        border-radius: 8px;
        .ant-skeleton-title{
            width: 100% !important;
            margin-bottom: 0;
        }
        .ant-skeleton-paragraph{
            margin-top: 16px !important;
            li:last-child{
                width: 30% !important;
            }
        }
    }
}