.mainContentGroupProduct {
    padding-top: 20px;
    display: block;
    .filter {
        display: flex;
        width: 100%;
        gap: 20px;
        .filter-left {
            width: calc(100% - 121px);
            display: flex;
            gap: 100px;
            h1 {
                font-size: 20px;
                font-weight: 600;
                line-height: 24.2px;
                letter-spacing: -0.02em;
                text-align: left;
                margin-bottom: 0px;
                padding: 10px 0px;
            }
            .filter-search {
                width: 440px;
                border-radius: 8px;
                background: #fff;
                display: flex;
                padding: 4px;
                input {
                    width: calc(100% - 36px);
                    border: unset;
                    border: unset;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000011920929px;
                    text-align: left;
                    color: #061020;
                    &:focus {
                        outline: unset;
                    }
                }
                button {
                    width: 36px;
                    background: #0e72ed;
                    height: 36px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: unset;
                }
            }
            @media screen and (max-width: 875px) {
                gap: 0 !important;
                justify-content: space-between;
                h1 {
                    white-space: nowrap;
                }
                .filter-search {
                    width: 400px !important;
                }
            }
        }

        .filter-plus {
            width: 121px !important;
            background: #0e72ed;
            border-radius: 8px;
            width: auto;
            display: grid;
            align-items: center;
            border: unset;
            justify-content: center;
            padding: 0px 16px 0px 12px;
            span {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #ffffff;
                position: relative;
                padding-left: 26px;
                img {
                    position: absolute;
                    top: -4px;
                    left: 0px;
                }
            }
        }
    }
    .content-table {
        margin-top: 20px;
    }
}
.modal-form {
    &.modal-form--group-product {
        .item-form {
            &__guide {
                padding: 5px 0px;
                font-style: italic;
            }
        }
        .item-form--custom {
            margin-top: 12px;
            .radio-group {
                &-item {
                    display: flex;
                    justify-items: center;
                    padding: 2px 0px;
                    span {
                        color: black;
                        font-weight: 400;
                    }
                }
            }
            button {
                &.ant-btn-primary {
                    span {
                        color: white;
                    }
                }
            }
        }
        .product-table {
            width: 100%;
            &.condition-filtering-product {
                max-height: 200px;
                overflow-y: auto;
            }
            table {
                width: 100%;
                tbody {
                    tr {
                        td {
                            padding: 15px 0px;
                            border-bottom: 1px solid #d6d6d6;
                        }
                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &.modal-form--product {
        .table {
            // &__container{}
            &__search {
                display: flex;
                justify-content: center;
                gap: 12px;
                padding: 12px;
                input {
                    padding: 0px 10px !important;
                    border-radius: 4px;
                }
                button {
                    padding: 20px 5px;
                    img {
                        display: block;
                    }
                }
            }
            &__body {
                padding: 10px;
                max-height: 400px;
                overflow-y: scroll;
                margin-top: 12px;
                width: 100%;
                table {
                    width: 100%;
                    thead {
                        tr {
                            th {
                                padding: 10px 10px;
                                background-color: #d6d6d6;
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                                padding: 20px 10px;
                                border-bottom: 1px solid #d6d6d6;
                            }
                            &:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                        // @media screen and (max-width: 850px) {
                        //     td .info .title-code .title-code-content p {
                        //         max-width: 175px !important;
                        //     }
                        // }
                    }
                }
            }
        }
    }
}
.condition {
    &__container {
        width: 100%;
        margin: 10px 0px;
        max-height: 200px;
        overflow-y: auto;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        .input-group {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 5px;
            margin: 10px 0px;
            div {
                .ant-select {
                    border: 1px solid #d0d6e5 !important;
                    border-radius: 4px;
                    padding: 5px;
                }
                input {
                    font-size: 14px;
                    border: 1px solid #d0d6e5;
                    border-radius: 4px;
                    padding: 15px 5px;
                }
            }
        }
    }
}
.action-btn__container {
    display: flex;
    gap: 12px;
    button {
        span {
            color: black;
        }
    }
}
