.main-requests-page{
  height: calc(100vh - 85.58px);
  padding: 20px 0px;
  .content-main-requests-page{
    height: 100%;
    display: flex;
    gap: 20px;
    .left-content-main-requests-page{
      width: 250px;
      background: #fff;
      .filter-requests{
        padding: 12px;
        border-bottom: 1px solid #e6f4ff;
        .input-group{
          width: 100%;
          min-height: 42px!important;
          .ant-select{
            height: 42px;
            width: 100%;
          }
        }
        .ant-select-selector{
          height: 42px
        }
      }
      .content-flooroom{
        height: calc(100% - 67px);
        overflow-y: scroll;
        .item-floor-room{
          cursor: pointer;
          padding: 12px;
          border-left: 4px solid #fff;
          border-bottom: 1px solid #e6f4ff;
          .title{
            font-size: 16px;
            font-weight: 600;
            line-height: 130%;
          }
          .count{
            font-size: 14px;
            line-height: 130%;
            margin-top: 8px;
          }
          &:hover{
            border-left: 4px solid #0e72ed;
          }
        }
        .item-floor-room-child{
          padding-left: 30px;
        }
        .active{
          border-left: 4px solid #0e72ed;
          .title{
            color: #0e72ed;
          }
        }
      }

    }
    .right-content-main-requests-page{
      width: calc(100% - 250px);
      background: #fff;
      padding: 20px;
      .filter-requests{

      }
      .content-requests{
        //display: grid;
        //align-items: center;
        height: 100%;
        .header-content-requests{
          display: grid;
          grid-template-columns: repeat(6,1fr);
          .item-header{
            border-bottom: 4px solid #ffffff;
            cursor: pointer;
            p{
              padding: 15px 0px;
              text-align: center;
              font-weight: 600;
            }
            &:hover{
              border-bottom: 4px solid #0e72ed;
            }
          }
          .active{
            border-bottom: 4px solid #0e72ed;
          }
        }
        .loadding{
          height: 100%;
          display: grid;
          align-items: center;
        }
        .table-content-requests{
          height: calc(100% - 110px);
          border: 1px solid #e6f4ff;
          overflow-y: auto;
          .item-requests{
            display: flex;
            border-bottom: 1px solid #e6f4ff;
            &:last-child{
              border-bottom: 0px;
            }
            p{
              font-size: 14px;
              line-height: 130%;

            }
            .name-table{
              width: 130px;
              padding: 12px;
              display: grid;
              align-items: center;
              p{
                font-weight: bold;
                //display: -webkit-box;
                //-webkit-box-orient: vertical;
                //-webkit-line-clamp: 2;
                //text-overflow: ellipsis;
                //overflow: hidden;
              }
            }
            .content-item{
              width: calc(100% - 130px);
              display: flex;
              .date{
                display: grid;
                align-items: center;
                width: 180px;
                padding: 12px;
                p{
                  margin-bottom: 8px;
                }
                span{
                  font-size: 12px;
                  padding: 4px 4px;
                  border: 1px solid #0e72ed;
                  border-radius: 8px;
                  text-align: center;
                  color: #0e72ed;
                }
                .staff{
                  color: #58c35a;
                  border: 1px solid #58c35a;
                }
                .pay {
                  color: #f9a501;
                  border: 1px solid #f9a501;
                }
              }
              .name-customer{
                width: 180px;
                padding: 12px;
                display: grid;
                align-items: center;
                p{

                  font-weight: 600;
                  //display: -webkit-box;
                  //-webkit-box-orient: vertical;
                  //-webkit-line-clamp: 2;
                  //text-overflow: ellipsis;
                  //overflow: hidden;
                  //align-items: center;
                }
              }
              .nd{
                display: grid;
                align-items: center;
                width: calc(100% - 540px);
                padding: 12px;
                ul{
                  margin-bottom: 0px;
                  padding-bottom: 0px;
                  li{
                    padding: 0px;
                    span{
                      font-size: 13px;
                      display: block;
                      white-space:unset;
                    }
                    .note-item-order{
                      font-size: 12px;
                      font-style: italic;
                    }
                  }
                }
                .note-order-all{
                  margin-top: 10px;
                  font-size: 14px;
                  font-style: italic;
                }
              }
              .btn-item{
                padding: 12px;
                display: flex;
                gap: 10px;
                width: 180px;
                .btn-cancel{
                  width: 60px;
                  cursor: pointer;
                  p{
                    padding: 12px 0px;
                    text-align: center;
                    border: 1px solid red;
                    border-radius: 8px;
                    color: red;
                  }
                }
                .btn-completed{
                  width: 98px;
                  cursor: pointer;
                  p{
                    padding: 12px 5px;
                    text-align: center;
                    border: 1px solid #0e72ed;
                    color: #0e72ed;
                    border-radius: 8px;
                  }
                  .staff{
                    border: 1px solid #58c35a;
                    color: #58c35a;
                  }
                  .pay{
                    border: 1px solid #f9a501;
                    color: #f9a501;
                  }
                }
              }
            }
            .content-item-completed{
              .date{
                width: 240px;
              }
              .nd{
                width: calc(100% - 420px);
              }
            }
            .content-item-canceled{
              .date{
                width: 190px;
                p{
                  font-size: 12px;
                }
              }
              .nd{
                width: calc(100% - 600px);
              }
              .reason{
                width: 230px;
                display: grid;
                align-items: center;
                padding: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.main-requests-page-mobile{
  display: none;
}
.ant-modal-content{
  .form-group-radio-home{
    .ant-radio-group{
      width: 100%;
      display: block;
      display: grid;
      gap: 15px;
      .ant-radio-wrapper{
        width: 100%;
        span{
          font-weight: 600;
          font-size: 16px;
          .noti{
            display: block;
            font-size: 14px;
            font-style: italic;
            font-weight: 400;
            color: #6D727C;
          }
        }
      }
    }
  }
  .form-group-select{
    .input-group{
      min-height: 44px!important;
      .ant-select{
        height: 44px;
        width: 100%;
        input{
          padding-left: 10px;
        }
      }
      .error{
        margin-top: 5px;
        font-size: 14px;
        line-height: 1.5714285714285714;
        color: #ff4d4f;
      }
    }
  }
  .note{
    margin-top: 10px;
    font-size: 14px;
    font-style: italic;
  }
  .form-group-input{
    margin-top: 8px;
    .input-group{
      margin-bottom: 0px;
      .ant-input-affix-wrapper{
        border: 1px solid #D0D6E5;
        border-radius: 8px;
        padding: 12px;
      }
    }

  }
  .btn-cancel{
    margin-top: 20px;
    button{
      width: 100%;
      padding: 12px 16px 12px 12px;
      background: #0E72ED;
      border-radius: 8px;
      color: #fff;
      border: unset;
      &:focus{
        outline: unset;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .main-requests-page {
    display: none;
  }
  .container-requests{
    background: #fff;
    border-top: 1px solid #ecf2f7;
  }
  .ant-modal-content .form-group-radio-home .ant-radio-group .ant-radio-wrapper span .noti{
    white-space:break-spaces;
  }
  .main-requests-page-mobile{
    display: block;
    background: #fff;
    padding: 12px 0px;
    .filter-requests{
      gap: 10px;
      display: grid;
      .input-group{
        .ant-select{
          width: 100%;
          height: 48px;
          background: #0E72ED;
          .ant-select-selector{
            background: #0E72ED;
            span{
              color: #fff;

            }
            &:after{
              color: #fff;
            }
          }
          svg{
            path{
              fill: #fff;
            }
          }
        }
      }
      .input-group:last-child{
        .ant-select{
          width: 100%;
          height: 48px;
          background: #0E72ED1A;
          .ant-select-selector{
            background: #0E72ED1A;
            span{
              color: #0E72ED;
            }
            &:after{
              color: #0E72ED;
            }
          }
          svg{
            path{
              fill: #0E72ED;
            }
          }
        }
      }
    }
    .content-requests{
      .header-content-requests{
        display: grid;
        grid-template-columns: 0.6fr 1fr 1fr 1fr;
        gap: 8px;
        padding: 14px 0px;
        .item-header{
          background: #D0D6E5;
          border-radius: 6px;
          text-align: center;
          p{
            padding: 6px 0px;
            font-size: 12px;
          }
        }
        .active{
          background: #005CF7;
          p{
            color: #fff;
          }
        }
      }
      .header-content-requests-2{
        padding-top: 0px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      .table-content-requests{
        .item-requests{
          border-bottom: 1px solid #f2f2f7;
          padding: 12px 0px;
          .name-table{
            p{
              font-size: 16px;
              font-weight: 500;
              line-height: 19.2px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
          .reason{
            margin-top: 6px;
            p{
              color: red;
              font-size: 14px;

            }
          }
          .name-customer-date{
            margin-top: 6px;
            display: flex;
            gap: 10px;
            p{
              font-size: 14px;
              font-weight: 400;
              line-height: 16.41px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #6F767E;
              position: relative;
              padding-left: 15px;
              img{
                position: absolute;
                top: 0px;
                left: 0px;
              }
            }
            .date{

            }
            .name-customer{

            }
          }
          .nd{
            margin-top: 5px;
            ul{
              margin-bottom: 0px;
              list-style-type: none;
              padding-left: 0px;
              li{
                span{
                  white-space: unset;
                  font-size: 13px;
                }
                .note-item-order{
                  display: block;
                  font-size: 12px;
                  font-style: italic;
                }
              }
            }
            p{
              font-size: 14px;
            }
          }
          .bottom-requests{
            margin-top: 6px;
            display: flex;
            .type-item-requests{
              width: calc(100% - 106px);
              display: grid;
              align-items: center;
              span{
                display: unset;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.4px;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: #242133;
                border: 0.5px solid #005CF7;
                background: #005CF71A;
                border-radius: 4px;
                padding: 3px 6px;
                width: 150px;
              }
              .pay{
                background: #F0BD061A;
                border: 0.5px solid #F0BD06
              }
              .staff{
                background: #58C35A1A;
                border: 0.5px solid #58C35A
              }
            }
            .btn-item{
              width: 106px;
              display: flex;
              gap: 20px;
            }
          }
        }
      }
    }
  }
}