.custom-modal {
  .modal-content {
    text-align: center;
    padding: 20px;
    border: none;

    .images-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .food-image {
        width: 120px;
        height: auto;
        margin: 0 10px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .modal-text {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #061020;
    }

    .button-container {
      display: flex;
      justify-content: center;
      gap: 10px;

      .close-btn {
        width: 160px;
        height: 44px;
        background-color: #054A9F;
        border: none;
        color: #FFFFFF;

        &:hover {
          background-color: #0E72ED;
          border-color: #1890ff;
        }
      }

      .create-btn {
        width: 160px;
        height: 44px;
        background-color: #0E72ED;
        border: none;
        color: #fff;

        &:hover {
          background-color: #40a9ff;
        }
      }
    }
  }
}