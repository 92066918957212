.page-files {
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
  }

  .mailbox-controls {
    width: calc(100% - 300px);
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 20px;
    }

    button {
      height: 46px;
      width: 46px;
      border: unset;
      border-radius: 8px;

      i {
        color: #fff;
      }

      span {
        color: #fff;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .reload {
      background: #ffa426;
    }

    .remove-file {

      background: #fc544b;
    }

    .upload {
      width: 100px;
      background: #0E72ED;
      position: relative;

      input {
        cursor: pointer !important;
        height: 100%;
        top: 0px;
        left: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
        z-index: 1000;
        cursor: pointer;
      }

      span {
        margin-left: 5px;
      }
    }

    .create-folders {
      width: 130px;
      background: #3bb557;

      span {
        margin-left: 5px;
      }
    }

    .structure {
      display: flex;
      gap: 10px;

      button {
        height: 46px;
        width: 46px;
        border: unset;
        border-radius: 8px;

        i {
          color: #333;
        }

        span {
          color: #fff;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }

  .search {
    width: 300px;
    @media (max-width: 1100px) {
      margin-top: 15px;
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }


    form {
      width: 100%;
      border: #ecf2f7 solid 1px;
      border-radius: 8px;
      background: #fff;
      display: flex;
      padding: 4px;

      input {
        width: calc(100% - 36px);
        border: unset;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.6px;
        letter-spacing: -0.2800000012px;
        text-align: left;
        color: #061020;
        padding-left: 10px !important;

        &:focus {
          outline: unset;
        }
      }

      button {
        width: 36px;
        background: #0E72ED;
        height: 36px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
      }
    }
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 35px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 400;

  .ant-breadcrumb {
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;

    }
  }

  .ant-breadcrumb-link:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #0e72ed;
  }

  .ant-breadcrumb-separator {
    margin: 0 8px;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;

    i {
      margin-left: 5px;
    }
  }
}

.body-information {
  .list-item {
    //display: grid;
    //grid-template-columns: repeat(6, 1fr);
    //grid-gap: 10px;
    .card-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 10px;
      //@media (max-width: 1450px) {
      //  grid-template-columns: repeat(4, 1fr);
      //}
      @media (max-width: 1150px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .loading_data {
      grid-column: 1 / -1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .file-card {
      width: 100%;
      height: 310px;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      overflow: hidden;
      //position: relative;

      .file-image {
        height: 160px;
        //width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;

        .image-wrapper {
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .zoom-image {
          transition: transform 0.3s ease;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          display: block;
        }

        .zoom-image:hover {
          transform: scale(1.1);
        }

        .file-checkbox {
          position: absolute;
          top: 10px;
          left: 10px;
          z-index: 1;
        }
      }



      .file-content {
        background-color: #f4f4f4;
        padding: 10px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .file-info {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .file-name {
            color: #666;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
              color: #0e72ed;
            }
          }

          .file-size {
            color: #999;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

          }

          .file-date {
            color: #999;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

          }

        }

        .file-actions {
          position: relative;
          display: inline-block;
          text-align: center;
          align-items: center;

          .action-button {
            border: none;
            border-radius: 4px;
            height: 36px;
            width: 100%; // Adjust width as needed
            background-color: #0e72ed;
            color: white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: #5566e6;
            }
          }

          .dropdown-menu {
            position: relative;
            top: 100%;
            left: 0;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            margin-top: 5px;
            z-index: 1000;
            width: 100%;

            .dropdown-item {
              padding: 8px 16px;
              cursor: pointer;

              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }

      }
    }
  }
}

.ant-dropdown-menu{
  width: 100%;
}
