.CloseMoneyControl-container {
  .line {
    height: 1px;
    background-color: #E9F4FF;
  }

  .table-borderless {
    width: 780px;
    height: 140px;

    .header-table {
      font-size: 16px;
      font-weight: 600;

      .text-header-table {
        text-align: right;
      }
    }

    .body-table {
      font-size: 16px;
      font-weight: 400;

      .text-money {
        text-align: left;
        vertical-align: middle;
      }

      .count-money {
        text-align: right;
        vertical-align: middle;
      }

      .text-count-money {
        .input-group {
          display: flex;
          align-items: center;
          gap: 20px;

          .form-control {
            width: 245px;
            height: 44px;
            border-radius: 8px;

          }

          .input-group-text {
            border: 1px solid #0e72ed !important;
            border-radius: 8px;
            background-color: #ffffff;
            padding: 0;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 44px;
          }
        }

      }

      .input-group-money {
        border-radius: 8px;

        .input-group {
          display: flex;
          align-items: center;
          height: 44px;
          border-radius: 8px;
          position: relative;

          .form-control {
            padding-right: 40px;
            height: 44px;
          }

          .btn-close {
            position: absolute;
            right: 10px;
            padding: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  .form-group {
    padding: 0 10px 0 10px;
    .form-control {
      background-color: #F6F9FC;
      height: 112px;
      margin-top: 8px;

    }
  }

  .modal-footer {
    border: none;
    .btn-warning {
      background-color: #F97414;
      width: 200px;
      height: 44px;
      border-color: #F97414;
      color: #FFFFFF;
      padding: 16px 10px 16px 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #e66b1a;
        border-color: #e66b1a;
      }
    }
    .btn-secondary {
      background-color: #6D727C;
      width: 200px;
      height: 44px;
      border-color: #6D727C;
      color: #FFFFFF;
      padding: 16px 10px 16px 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #5c616a;
        border-color: #5c616a;
      }
    }
  }
}

.modalCloseMoney{
  &.disable{
    pointer-events: none;
  }
}