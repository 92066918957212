@import "../../../../../public/assets/css/variables.scss";
.mainContentStoreBranch {
    // background: #fff;
    // margin: 20px 0px;
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    .header {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: end;
        &__title {
            width: 232px;
            line-height: 44px;
            font-size: 20px;
            font-weight: 600;
            color: #0e72ed;
        }
        &__search {
            // background-color: white;
            background-color: #f6f9fc;
            border: 1px solid #ecf2f7;
            border-radius: 8px;
            padding: 4px 12px;
            display: flex;
            align-items: center;
            // gap: 24px;
            width: 300px;
            height: 44px;
            input {
                border: none;
                margin-left: 4px;
                flex: 1;
                background-color: transparent;
                &:focus {
                    border: none;
                    outline: none;
                }
            }
            button {
                background-color: transparent;
                border-radius: 8px;
                outline: none;
                border: none;
                width: 32px;
                height: 32px;
                margin: 0px;
                padding: 0px;
                img {
                    display: block;
                }
            }
        }
        .btn-clear-filter {
            height: 44px;
            background: #0e72ed;
            border-radius: 8px;
            width: 95px;
            display: flex;
            align-items: center;
            border: unset;
            justify-content: center;
            padding: 8px 16px 8px 12px;
            color: white;
        }
        .btn-add-modal {
            height: 44px;
            margin-left: auto;
            margin-right: 2px;
        }
        .ant-select-single {
            height: 44px;
            width: 180px;
            .ant-select-selection-search {
                padding-left: 11px;
            }
        }
    }
    .body-page {
        display: flex;
        margin-top: 20px;
        gap: 16px;
        .filter-tab-wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .filter-tab {
                width: 232px;
                height: fit-content;
                background-color: white;
                padding: 8px 0px;
                border-radius: 8px;
                button.filter-collapse-btn {
                    width: 100%;
                    background-color: transparent;
                    border: none;
                    display: flex;
                    justify-content: space-between;
                    color: black;
                    span {
                        color: black;
                        font-weight: 600;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
                input[type="radio"] {
                    appearance: none;
                    background: transparent;
                    position: relative;
                }
                input[type="radio"]::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: "";
                    text-align: center;
                    background: #fff;
                    display: block;
                    pointer-events: none;
                    opacity: 1;
                    color: black;
                    border: 1.5px solid #6d727c;
                }
                input[type="radio"] + label {
                    line-height: 24px;
                    margin: 0 8px 0 8px;
                }
                input[type="radio"]:checked::after {
                    content: "\2713";
                    color: #fff;
                    background: $primary-color;
                    border: 1.5px solid $primary-color;
                    font-size: 12px;
                }
                input[type="radio"],
                input[type="radio"]::after {
                    width: 16px;
                    height: 16px;
                    font-size: 16px;
                    border-radius: 50%;
                    line-height: 14px;
                    vertical-align: middle;
                }
                .filter-tab__body {
                    padding: 0px 10px;
                    .filter-tab__option {
                        padding: 8px 0px;
                        display: flex;
                        align-items: center;
                        label,
                        input {
                            cursor: pointer;
                        }
                    }
                }
                .filter-action-btn {
                    margin-left: 10px;
                }
            }
        }
        .content-table {
            flex: 1;
            .table-responsive {
                padding: 0;
            }
            table {
                thead {
                    span.sucess-status {
                        padding: 3px 10px 3px 10px;
                        gap: 4px;
                        border-radius: 12px;
                        background: #58c35a;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 14.4px;
                        text-align: center;
                    }
                    tr {
                        th {
                            padding: 12px 10px;
                        }
                    }
                }

                tbody {
                    .name {
                        display: flex;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 8px;
                            object-position: center;
                            object-fit: cover;
                        }
                        span {
                            margin-left: 12px;
                            font-size: 16px;
                            font-weight: 600;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            flex: 1;
                        }
                    }
                    .action-table {
                        button.ant-btn-text {
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            &:hover {
                                background-color: white;
                            }
                        }
                    }
                    .item {
                        gap: 12px;
                        .image {
                            img {
                                width: 40px;
                                height: 40px;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                        .info {
                            ul {
                                gap: 8px;
                                list-style-type: none;
                                padding-left: 0;
                                margin-bottom: 0;
                                li {
                                    font-size: 14px;
                                }
                            }
                            span {
                                color: #959393;
                                font-size: 14px;
                            }
                        }
                    }
                    .imageStaff {
                        position: relative;
                        height: 85px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            border-radius: 50%;
                            margin-bottom: 0;
                            transition: all 0.3s ease;
                            &:nth-child(1) {
                                top: 50%;
                                left: 44%;
                                transform: translate(-50%, -50%);
                                z-index: 10;
                            }
                            &:nth-child(2) {
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                z-index: 20;
                            }
                            &::hover {
                                z-index: 100;
                            }
                        }

                        .hiddenmember {
                            position: absolute;
                            top: 50%;
                            left: 56%;
                            transform: translate(-50%, -50%);
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                            border-radius: 50%;
                            background-color: #aeb5c7;
                            z-index: 30;
                            span {
                                color: white;
                                font-weight: 600;
                                cursor: pointer;
                            }
                        }

                        .avatar {
                            border-radius: 50%;
                            color: #e3eaef;
                            display: inline-block;
                            font-size: 16px;
                            font-weight: 300;
                            margin: 0;
                            position: relative;
                            vertical-align: middle;
                            line-height: 1.28;
                            height: 45px;
                            width: 45px;
                            &:not(:first-child) {
                                margin-left: -1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: white;
}

// Modal storebranch
.mainContentCreateStoreBranch {
    margin: 20px 0;
    .form-create-edit {
        padding: 24px 0;

        .storebranchForm {
            padding: 24px;
            background-color: white;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            &--body {
                font-size: 14px;
                display: flex;
                flex-direction: column;
                gap: 18px;
            }
            &--btn {
                .addNewStaff {
                    border: none;
                    outline: none;
                    padding: 10px 30px;
                    border: 1px solid $primary-color;
                    background: #fff;
                    border-radius: 8px;
                }
            }
        }

        .storebranchRadio {
            background-color: white;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .button-create {
            font-size: 14px !important;
            padding: 12px 24px 12px 28px !important;
            border-radius: 8px;
            background: $primary-color;
            color: #fff;
            border: none;
            outline: none;
        }
    }
}

.item-selected {
    .ant-select-selector {
        cursor: pointer !important;
        input {
            pointer-events: none !important;
            caret-color: transparent !important;
        }
    }
}

.item-checked
    .ant-form-item
    .ant-row
    .ant-col
    .ant-form-item-control-input
    .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 8px;
    input {
        width: 20px;
        height: 20px;
    }
}

.ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
    border-color: white;
}
.ant-input-affix-wrapper-focused {
    &:focus-within {
        border-color: white;
        box-shadow: none;
    }
}
.mainContentStoreBranch{
    .title{
        width: 200px;
    }
    .header{
        width: calc(100% - 200px);
    }
}
@media screen and (max-width:1280px){
    .mainContentStoreBranch {
        .title{
            width: 100%;
        }
        .header{
            width: 100%;
            justify-content: left;

        }
    }
}
@media screen and (max-width:1200px){

}
@media screen and (max-width:1100px){
    .mainContentStoreBranch {
        .header{
            width: 100%;
            .header__filter{
                width: 100%;
                display: grid!important;
                grid-template-columns: repeat(2,1fr);
                .header__search{
                    width: 100%;
                }
                .ant-select{
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width:992px){
    .mainContentStoreBranch .body-page{
        overflow: scroll;
    }
    .mainContentCreateStoreBranch{
        .ant-row{
            display: grid;
            gap: 12px;
            .ant-col-12{
                flex: 100%;
                max-width: 100%;
            }
        }
    }
}