.TransferTable{
  .ant-modal-content{
    width: 600px;
    padding: 10px 30px 20px;
    margin: auto;
    position: relative;
  }
  .header{
    font-size: 24px;
    color: #0e72ed;
  }
  .line {
    height: 1px;
    background-color: #E9F4FF;
  }
  .line2{
    height: 1px;
    background-color: #E9F4FF;
    margin-top: 30px;
  }
  .transfer-table-content{
    .transfer-table-content__input{
      font-weight: 600;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .transfer-title{
        font-size: 16px;
      }
      .ant-input-outlined {
        height: 40px;
      }
      .ant-select-single {
        height: 40px;
        .ant-select-selection-search-input {
          height: 40px;
        }
      }
    }

  }
  .ant-modal-footer{
    margin-top: 20px;
    display: flex;
    text-align: center;
    align-items: center;
    button {
      width: 50%;
    }
    .ant-btn-default{
      height: 50px;
      border-radius: 10px;
      background-color: #BABABA;
      color: #FFFFFF;
      font-size: 20px;
    }
    .ant-btn-default:hover {
      background-color: #9e9e9e;
      color: #f0f0f0;
    }
    .ant-btn-primary {
      height: 50px;
      border-radius: 10px;
      background-color: #0e72ed;
      font-size: 20px;
    }
    .ant-btn-primary:hover {
      background-color: #0056b3;
      color: #e6f7ff;
    }
  }

  .ant-modal .ant-modal-close{
    top: -31px;
    right: 8px;
  }
}