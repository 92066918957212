.payment-separation-container {
  display: flex;
  flex-direction: column;

  .payment-separation-body {
    .separation-title {
      display: flex;
      padding: 8px 20px;
      align-items: center;

      .button-return {
        .ant-btn {
          background-color: #FFFFFF;
          width: 120px;
          height: 45px;
          display: flex;
          gap: 10px;
          align-items: center;
          text-align: center;
          transition: background-color 0.3s;
        }

        .ant-btn:hover {
          background-color: #0E72ED;
        }
      }

      .title-page {
        width: calc(100% - 186px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
      }
    }

    .session3 {
      display: flex;

      .leftSession3 {
        width: 300px;
        @media screen and (max-width: 992px) {
          width: 200px;
          background: #fff;
        }

        .content {
          background: #fff;
          @media screen and (max-width: 992px) {
            width: 100%;
          }

          ul {
            padding-left: 0px;
            list-style-type: none;

            li {
              list-style-type: none;
              border-bottom: 2px solid #ECF2F7;

              button {
                padding: 16px;
                height: auto !important;
                line-height: 130%;
                display: block;
                text-decoration: unset;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  padding-left: 28px;
                  display: block;

                  img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }

                  p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000011920929px;
                    text-align: left;
                    color: #061020;
                    position: relative;
                    display: block;
                    margin-bottom: 0px;
                  }
                }
              }

              .btnModalCustomer {
                @media screen and (max-width: 992px) {
                  width: 100%;
                }
                padding: 16px;
                height: auto !important;
                line-height: 130%;
                display: block;
                text-decoration: unset;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  padding-left: 28px;
                  display: block;

                  img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }

                  p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19.6px;
                    letter-spacing: -0.2800000011920929px;
                    text-align: left;
                    color: #061020;
                    position: relative;
                    display: block;
                    margin-bottom: 0px;
                  }
                }
              }

              .btnModalCustomer {
                background: unset;
                border: unset !important;
                box-shadow: unset;

                &:hover {
                  background: unset !important;
                }

                &:focus {
                  background: unset !important;
                }
              }

              .btnNotePay {
                background: unset;
                border: unset !important;
                box-shadow: unset;

                &:hover {
                  background: unset !important;
                }

                &:focus {
                  background: unset !important;
                }

                p {
                  margin-top: 12px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #6D727C;
                  margin-bottom: 0px;
                }

                .hidden {
                  display: none;
                }
              }

              .imageBank {
                padding: 16px 16px 8px;
                height: auto !important;
                line-height: 130%;
                display: block;
                text-decoration: unset;
                margin-bottom: 0px;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  display: block;
                }
              }

              .upload-form {
                padding-bottom: 16px;
                width: 90%;
                margin: auto;

                .upload {
                  height: 116px !important;

                  a {
                    height: 90px !important;
                    padding: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      max-height: 100%;
                      max-width: 100%;
                    }
                  }
                }
              }

              .hidden {
                display: none;
              }
            }
          }

        }
      }

      .centerSession3 {
        width: calc(100% - 600px);
        @media screen and (max-width: 992px) {
          width: calc(100% - 400px);
        }

        .contentFull {
          border: 2px solid #BABABA;

          .content1 {
            height: 120px;
            background: #fff;
            display: grid;
            align-items: center;
            border-bottom: 2px solid #BABABA;

            .nd {
              .priceTotal {
                @media screen and (max-width: 992px) {
                  font-size: 32px;
                }
                font-size: 48px;
                font-weight: 500;
                line-height: 55.2px;
                letter-spacing: -1.440000057220459px;
                text-align: center;
                color: #088C3D;
                margin-bottom: 0px;
              }

              .notePay {
                @media screen and (max-width: 992px) {
                  font-size: 14px;
                }
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: center;
                color: #061020;
              }
            }

            .nd2 {
              @media screen and (max-width: 992px) {
                padding: 0px 10px;
                display: grid;
              }
              padding: 32px 20px;
              display: flex;

              .leftNd {
                @media screen and (max-width: 992px) {
                  width: 100%;
                }
                width: 50%;

                .text1 {
                  @media screen and (max-width: 992px) {
                    font-size: 14px;
                    margin-bottom: 0px;
                  }
                  margin-bottom: 0px;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 24.2px;
                  letter-spacing: -0.02em;
                  text-align: left;
                  color: #061020;
                  margin-bottom: 8px;
                }

                .text2 {
                  margin-bottom: 0px;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  letter-spacing: -0.03200000151991844px;
                  text-align: left;
                  color: #061020;
                }
              }

              .rightNd {
                @media screen and (max-width: 992px) {
                  width: 100%;
                }
                width: 50%;

                .text3 {
                  @media screen and (max-width: 992px) {
                    font-size: 14px;
                    text-align: left;
                  }
                  margin-bottom: 0px;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 24.2px;
                  letter-spacing: -0.02em;
                  text-align: right;
                  color: #061020;
                }
              }
            }

            .hidden {
              display: none;
            }
          }

          .content2 {
            height: calc(100vh - 306.19px);
            background: #ECF2F7;

            .listNumber {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 2px;
              height: 100%;
              align-items: center;
              justify-content: center;

              button {
                border: unset;
                display: grid;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                font-weight: 400;
                line-height: 29.05px;
                letter-spacing: -1.440000057220459px;
                text-align: left;
                color: #061020;
                height: 100%;
                background: #fff;
                text-decoration: unset;
              }

              .payClick {
                background: #0E72ED;
                color: #fff;
              }
            }
          }
        }

      }

      .rightSession3 {
        width: 300px;
        @media screen and (max-width: 992px) {
            width: 200px;
        }
          .content {
          background: #fff;

          ul {
            padding-left: 0px;
            list-style-type: none;
            margin-bottom: 0px;

            li {
              list-style-type: none;
              border-bottom: 2px solid #ECF2F7;

              p {
                @media screen and (max-width: 992px) {
                  padding: 16px 5px;
                }
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 600;
                line-height: 19.6px;
                letter-spacing: -0.20999999344348907px;
                text-align: left;
                color: #061020;
                padding: 16px 16px;
              }

              button {
                border: unset;
                width: 100%;
                background: unset;
                height: auto;
                padding: 16px;
                height: auto !important;
                line-height: 130%;
                display: block;
                text-decoration: unset;

                span {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  padding-left: 28px;
                  display: block;

                  img {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                  }
                }
              }

              .summary {
                font-size: 14px;
                font-weight: 600;
                line-height: 19.6px;
                letter-spacing: -0.20999999344348907px;
                text-align: left;
                color: #061020;
                padding-left: 0px;
              }
            }
          }

          #contentPayType {
            height: calc(100vh - 400.57px);
            overflow: auto;
            display: block;

            gap: 2px;
            //background: #ECF2F7;
            .item {
              @media screen and (max-width: 992px) {
                padding: 28px 5px;
              }
              display: flex;
              padding: 28px 20px;
              align-items: center;
              background: #fff;
              border-bottom: 2px solid #ECF2F7;

              p {
                margin-bottom: 0px;
              }

              .text {
                @media screen and (max-width: 992px) {
                  font-size: 12px;
                  width: 20px;
                  //display: none;
                }
                width: 90px;
                font-size: 14px;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                display: grid;
                height: 100%;
                align-items: center;
              }

              .text-mobile {
                @media screen and (max-width: 992px) {
                  display: block;
                  width: 32px;
                }
                display: none;
                font-size: 12px;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                display: grid;
                height: 100%;
                align-items: center;
              }

              .price {
                @media screen and (max-width: 992px) {
                  width: calc(100% - 70px);
                  font-size: 12px;
                  overflow: hidden;
                }
                height: 100%;
                width: calc(100% - 138px);
                color: #061020;
                font-size: 14px;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.2800000011920929px;
                text-align: right;
                display: grid;
                align-items: center;
              }

              .delete {
                @media screen and (max-width: 992px) {
                  width: 38px;
                }
                background: unset;
                border: unset;
                width: 48px;
                text-align: right;
              }
            }

            .itemActive {
              background: #ECF2F7;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .payment .session3 {
    .leftSession3 {
      width: 200px;
      background: #fff;

      .content ul li .btnModalCustomer {
        width: 100%;
      }
    }

    .rightSession3 {
      width: 200px;

      .content ul li p {
        padding: 16px 5px;
      }

      .content #contentPayType .item {
        padding: 28px 5px;

        .text {
          font-size: 12px;
          width: 80px;
          display: none;
        }

        .text-mobile {
          display: block;
          width: 32px;
        }

        .delete {
          width: 38px;
        }

        .price {
          width: calc(100% - 70px);
          font-size: 12px;
          overflow: hidden;
        }
      }
    }

    .centerSession3 {
      width: calc(100% - 400px);

      .contentFull {
        .content1 {
          .nd {
            .priceTotal {
              font-size: 32px;
            }

            .notePay {
              font-size: 14px;
            }
          }

          .nd2 {
            padding: 0px 10px;
            display: grid;

            .leftNd {
              width: 100%;

              .text1 {
                font-size: 14px;
                margin-bottom: 0px;
              }
            }

            .rightNd {
              width: 100%;

              .text3 {
                font-size: 14px;
                text-align: left;
              }
            }
          }
        }
      }
    }

  }
  .payment .session3 .centerSession3 .contentFull .content2 .listNumber button {
    font-size: 18px;
  }
}