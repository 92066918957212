@charset "UTF-8";
.mainContentRoom {
  margin-top: 20px;
}
.mainContentRoom .body-page {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.mainContentRoom .body-page .filter-tab-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab {
  width: 232px;
  height: fit-content;
  background-color: white;
  padding: 8px 0px;
  border-radius: 8px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab button.filter-collapse-btn {
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  color: black;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab button.filter-collapse-btn span {
  color: black;
  font-weight: 600;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab button.filter-collapse-btn:focus {
  outline: none;
  box-shadow: none;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio] {
  appearance: none;
  background: transparent;
  position: relative;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio]::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  text-align: center;
  background: #fff;
  display: block;
  pointer-events: none;
  opacity: 1;
  color: black;
  border: 1.5px solid #6D727C;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio] + label {
  line-height: 24px;
  margin: 0 8px 0 8px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio]:checked::after {
  content: "✓";
  color: #fff;
  background: #0e72ed;
  border: 1.5px solid #0e72ed;
  font-size: 12px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio],
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab input[type=radio]::after {
  width: 16px;
  height: 16px;
  font-size: 16px;
  border-radius: 50%;
  line-height: 14px;
  vertical-align: middle;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-tab__body {
  padding: 0px 10px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-tab__body .ant-select {
  height: 44px;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-tab__body .filter-tab__option {
  padding: 8px 0px;
  display: flex;
  align-items: center;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-tab__body .filter-tab__option label, .mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-tab__body .filter-tab__option input {
  cursor: pointer;
}
.mainContentRoom .body-page .filter-tab-wrapper .filter-tab .filter-action-btn {
  margin-left: 10px;
}
.mainContentRoom .body-page .content-table {
  background-color: #FFFFFF;
  border-radius: 8px;
  flex: 1;
}
.mainContentRoom .body-page .content-table .header {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 10px 10px 0 10px;
  justify-content: space-between;
}
.mainContentRoom .body-page .content-table .header__filter {
  display: flex;
  align-items: center;
  gap: 12px;
}
.mainContentRoom .body-page .content-table .header__filter .filter-tab__body .ant-select {
  height: 44px;
  width: 220px;
}
.mainContentRoom .body-page .content-table .header__title {
  width: 232px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 600;
  color: #0e72ed;
}
.mainContentRoom .body-page .content-table .header__search {
  display: flex;
  align-items: center;
  height: 44px;
  border: 1px solid #ECF2F7;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  width: 300px;
  background-color: #F6F9FC;
}
.mainContentRoom .body-page .content-table .header__search input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: #F6F9FC;
}
.mainContentRoom .body-page .content-table .header .btn-add-modal {
  align-self: stretch;
  margin-left: auto;
  margin-right: 2px;
}
.mainContentRoom .body-page .content-table table tbody .name {
  display: flex;
}
.mainContentRoom .body-page .content-table table tbody .name img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-position: center;
  object-fit: cover;
}
.mainContentRoom .body-page .content-table table tbody .name span {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}
.mainContentRoom .body-page .content-table table tbody .action-table button.ant-btn-text {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.mainContentRoom .body-page .content-table table tbody .action-table button.ant-btn-text:hover {
  background-color: white;
}
.mainContentRoom .pagination li {
  padding: 0px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContentRoom .pagination li.disabled {
  pointer-events: none;
}
.mainContentRoom .pagination li.disabled a {
  color: #d0d6e5;
  border-color: #d0d6e5;
}
.mainContentRoom .pagination li a {
  text-decoration: none;
}
.mainContentRoom .pagination li.previous a i::before {
  margin-right: 1px;
}
.mainContentRoom .pagination li.next a i::before {
  margin-left: 1px;
}
.mainContentRoom .pagination li i::before {
  display: block;
}
.mainContentRoom .pagination__container {
  margin: 20px 0px 12px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.mainContentRoom .pagination__info {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
}

.ant-modal-body > .item-form {
  margin-top: 12px;
  margin-bottom: 20px;
}
.ant-modal-body > .upload-form {
  margin-top: 10px;
}

form .field-title {
  padding: 12px 0px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
form .ant-form-item {
  border-radius: 8px;
  margin-bottom: 0px;
}
form .ant-form-item .ant-form-item-row {
  display: block;
}
form .ant-form-item .ant-form-item-row .ant-form-item-label {
  text-align: start;
}
form .ant-form-item .ant-form-item-row .ant-form-item-label label {
  height: auto;
  color: #494C5D;
  font-weight: 500;
}
form .ant-form-item .ant-form-item-row .ant-form-item-label label.ant-form-item-required::before {
  display: none;
}
form .ant-form-item .ant-form-item-row .ant-form-item-label label.ant-form-item-required::after {
  content: "*";
  color: #ff0d0d;
}
form .ant-form-item .ant-form-item-row .ant-form-item-control {
  text-align: start;
}
form .ant-form-item .ant-form-item-row .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content textarea, form .ant-form-item .ant-form-item-row .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content input {
  border: none;
  margin: 0;
  outline: 0px;
}
form .ant-form-item .ant-form-item-row .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ant-select-selector {
  width: 100%;
}
.ant-form-item-control-input-content {
  height: fit-content;
}

.msg-error {
  color: #ff0d0d !important;
}

span.active {
  background-color: #58c35a;
  border-radius: 12px;
  padding: 5.5px 10px 4.5px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
}

span.inactive {
  background-color: #888888;
  border-radius: 12px;
  padding: 5.5px 10px 4.5px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
}

