@import "../../../../../../public/assets/css/variables.scss";
.mainContentCreateProduct {
    background: #fff;
    margin: 20px 0px;
    padding: 8px 12px;
    .title-create-product {
        padding: 12px 0px;
        a {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #0e72ed;
            text-decoration: unset;
        }
    }
    .form-create-product {
        max-width: 100% !important;
        display: grid;
        gap: 30px;
        .session-create {
            gap: 20px;
            display: flex;
            .session-create-left {
                position: relative;
                width: 35%;
                .session-create-left-content {
                    position: sticky;
                    top: 0px;
                    left: 0px;
                }
                .title {
                    padding: 0px 0px 4px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: -0.10000000149011612px;
                    text-align: left;
                    color: #061020;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                }
                ul {
                    padding-left: 0px;
                    list-style-type: unset;
                    li {
                        a {
                            display: block;
                            text-decoration: unset;
                            //box-shadow: 0 2px 6px #acb5f6;
                            padding: 12px 15px;
                            color: #054a9f;
                            &:hover {
                                background-color: #f6f7fe;
                                color: #054a9f;
                                border-radius: 0.25rem;
                            }
                        }
                    }
                    .active {
                        a {
                            border-radius: 0.25rem;
                            color: #fff;
                            background-color: #054a9f;
                            &:hover {
                                color: #fff;
                                background-color: #054a9f;
                            }
                        }
                    }
                }
            }

            .session-create-right {
                width: 65%;
                .session-create-content {
                    display: grid;
                    gap: 10px;
                }
            }
            .session-create-variant-right {
                .upload-form .upload {
                    height: 60px;
                }
                .image-attributes {
                    .upload-form .upload input {
                        height: 60px;
                    }
                    .atom-rli-bounding-box .atom-indicator{
                        width: 28px;
                        height: 28px;
                    }
                    .atom-rli-bounding-box .atom-indicator .electron-orbit{
                        width: 25px;
                        height: 25px;
                    }
                }
                .image {
                    overflow: unset;
                }
            }
        }
    }
    form {
        display: grid;
        gap: 30px;
        .label-allow-order {
            input {
                margin-right: 10px;
            }
        }
        .editAttributes {
            background: unset;
            height: auto;
            border: unset;
        }
        .item-form-inventory {
            display: grid;
            gap: 10px;
            .item-store {
                display: grid;
                grid-template-columns: 1.5fr 1fr;
                p {
                    display: grid;
                    align-items: center;
                    padding: 0px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: -0.1000000015px;
                    text-align: left;
                    color: #061020;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                }
                .qty {
                    display: flex;
                    justify-content: end;
                    gap: 10px;
                    .minus {
                        display: grid;
                        align-items: center;
                        button {
                            width: 30px;
                            height: 30px;
                            background-color: #fc544b !important;
                            color: #fff;
                            border: unset;
                            border-radius: 8px;
                            font-size: 20px;
                            line-height: 0px;
                        }
                    }
                    .plus {
                        display: grid;
                        align-items: center;
                        button {
                            width: 30px;
                            height: 30px;
                            background-color: #47c363 !important;
                            color: #fff;
                            border: unset;
                            border-radius: 8px;
                            font-size: 20px;
                            line-height: 0px;
                        }
                    }
                    input {
                        font-size: 14px;
                        padding: 10px 15px;
                        height: 42px;
                        text-align: center;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        &:focus {
                            outline: unset;
                        }
                    }
                }
            }
        }
    }

}

.accordion {
    margin-top: 16px;
    .accordion-item {
        // margin-top: 16px;
        .accordion-header {
            button {
                font-size: 16px;
                font-weight: 700;
                color: #6c757d;
                background-color: #f9f9f9;
                border: none;
                outline: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 15px;
                span {
                    color: white;
                    background-color: #fc544b;
                }
                &::after {
                    display: none;
                }
                &:not(:first-child) {
                    border-top: 1px solid #6c757d;
                }
            }
            .accordion-button:not(.collapsed) {
                background-color: $primary-color;
                color: white;
                font-size: 16px;
                font-weight: 700;
                box-shadow: 0 2px 6px #acb5f6;
                span {
                    box-shadow: 0 2px 6px #fd9b96;
                    background-color: #fc544b;
                }
            }
        }
        .accordion-collapsed {
            .accordion-body {
                padding: 1rem 1rem;
            }
        }
    }
}

.no-border-body {
    border: none !important;
}

.item-selected {
    .ant-select-selector {
        cursor: pointer !important;
        input {
            pointer-events: none !important;
            caret-color: transparent !important;
        }
    }
}
.editAttributes{
    width: 200px;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #054a9f!important;
    display: grid;
    justify-content: center;
    padding: 8px 0px;
}

.formGroup {
    .button {
        button {
            border: none;
            outline: none;
            padding: 0.3rem 0.8rem;
            color: #fff;
            font-weight: 600;
            box-shadow: 0 2px 6px #acb5f6;
            background-color: $primary-color;
            border-color: $primary-color;
            border-radius: 0.25rem;
            max-width: 143px;
            width: 143px;
            transition: all 0.15s ease-in-out;
            &:hover {
                background-color: #394eea;
            }
        }
    }
}
.product-variant{
    .mainContentCreateProduct .form-create-product .session-create-variant{
        display: block;
        .session-create-left{
            width: 100%;
        }
        .session-create-right{
            width: 100%;
        }
    }
}
@media screen and (max-width:1140px){
    .mainContentCreateProduct .form-create-product .session-create-variant{
        display: block;
        .session-create-left{
            width: 100%;
        }
        .session-create-right{
            width: 100%;
        }
    }
    .mainContentCreateProduct .table-responsive table{
        width: 100%;
    }
}
@media screen and (max-width:992px){
    .mainContentEditProduct .form-create-product .session-create {
        display: grid;
        .session-create-left{
            width: 100%;
        }
        .session-create-right{
            width: 100%;
        }
    }
    .mainContentEditProduct .grid-1-2-3{
        grid-template-columns: 1.5fr 2fr 1fr;
    }
}
@media screen and (max-width:550px){
    .mainContentEditProduct .grid-1-2-3{
        grid-template-columns:  1fr;
    }
}