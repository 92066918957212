.button-all{
  margin-top: 24px;
  padding: 10px 0px;
  span{
    box-shadow: 0 2px 6px #82d3f8;
    background-color: #3abaf4;
    border-color: #3abaf4;
    color: #fff;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
}
.list-permissions{
  margin-bottom: 24px;
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 24px;
  .item-permissions{
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 12px;
    .title-permissions{
      font-size: 22px;
      font-weight: 500;
    }
    .radio-button {
      display: flex;
      align-items: center;
      margin-right: 10px;
      position: relative;
      padding: 5px 0px;
      cursor: pointer;
      width: 100%;
      transition: 0.3s;
      &:hover .radio-tick{
        transition: 0.3s;
        border: 1px solid #0E72ED;
      }
      p{
        width: calc(100% - 16px);
        margin-bottom: 0px;
        font-size: 14px;
      }
    }

    .radio-button input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .radio-tick {
      width: 16px;
      height: 16px;
      border: 1px solid rgb(217,217,217);
      margin-right: 10px;
      border-radius: 4px;
    }

    .radio-button input[type="checkbox"]:checked + .radio-tick::before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border: unset;
      font-size: 10px;
      height: 16px;
    }


    .radio-button input[type="checkbox"]:checked ~ .radio-tick {
      background-color: #0E72ED;
      border: 1px solid #0E72ED;
    }
  }
}
.submitFormPermissions{
  width: 160px;
  padding: 16px 12px 16px 12px;
  gap: 4px;
  border-radius: 8px;
  background: #0E72ED;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  letter-spacing: -0.2800000012px;
  text-align: center;
  color: #FFFFFF;
  border: unset;
}
@media screen and (max-width:1140px){
  .list-permissions{
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width:992px){
  .list-permissions {
    gap: 12px;
    .item-permissions .title-permissions{
      font-size: 16px;
    }
  }
}