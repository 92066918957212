@import "../../../../../../public/assets/css/variables.scss";
.customer-debt-page{
  .filter-search-hidden{
    display: none;
  }
  .session2{
    //position: relative;
    padding: 8px 20px;
    display: flex;
    a{
      //left: 32px;
      //top: 50%;
      //transform: translate(0%,-50%);
      //position: absolute;
      padding: 12px 10px;
      background: #FFFFFF;
      border: 1px solid #ECF2F7;
      border-radius: 8px;
      text-decoration: unset;
      span{
        position: relative;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        letter-spacing: -0.20999999344348907px;
        text-align: left;
        color: #061020;
        padding-left: 20px;
        img{
          position: absolute;
          top: 4px;
          left: 0px;
        }
      }
    }
    p{
      display: grid;
      align-items: center;
      width: calc(100% - 186px);
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #061020;
      margin-bottom: 0px;
    }
  }
  .session3-debt-detail{
    .seachDebt{
      display: flex;
      .price_debt{
        display: grid;
        align-items: center;
        width: 300px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        text-align: left;
        color: #000000;
        margin-bottom: 2px;
      }
      .form-search-debt{
        width: calc(100% - 300px);
        justify-content: end;
        display: flex;
        gap: 15px;
        .transaction_type{
          width: 200px;
          height: 44px;
          padding: 0px 12px;
          border-radius: 8px;
          background: #ECF2F7;
          border: 1px solid #ECF2F7;
          &:focus{
            outline: unset;
          }
        }
      }
    }
  }
  .session3{
    height: calc(100vh - 186px);
    background: #fff;
    padding: 24px 16px;
    display: block;
    .seachDebt{
      padding-bottom: 24px;
      form{
        justify-content: end;
        display: flex;
        .form-search-debt{
          input{
            width: 250px;
            height: 44px;
            padding: 0px 12px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background: #ECF2F7;
            border: 1px solid #ECF2F7;
            &:focus{
              outline: unset;
            }
          }
        }
        .btnSubmit{
          width: 44px;
          height: 44px;
          button{
            background: $primary-color;
            width: 100%;
            height: 100%;
            border: unset;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            &:focus{
              outline: unset;
              border: unset;
            }
          }
        }
      }
    }
    .contentSession{
      .table-responsive{
        padding: 0px;
      }
      height: 100%;
      .heightTable{
        height: calc(100% - 119.5px);
        overflow: auto;
      }
      .contentCustomer::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
      }

      /* Track */
      .contentCustomer::-webkit-scrollbar-track {
        background: unset;
        border-radius: 10px;
      }

      /* Handle */
      .contentCustomer::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }

      /* Handle on hover */
      .contentCustomer::-webkit-scrollbar-thumb:hover {
        background: #888;
        border-radius: 10px;
      }
      .tableDesign{
        margin-bottom: 0px;
        thead{
          position: sticky;
          top: 0px;
          left: 0px;
          width: 100%;
          border-bottom: 0px;
          tr{
            border-bottom: 0px;
            background: #F6F8FA;
            th{
              height: 60px;
              vertical-align: middle;
              border-bottom: 0px!important;
              background-color: #F6F8FA!important;
              text-transform: unset!important;
              font-size: 14px;
              font-weight: 600;
              line-height: 100%;
              letter-spacing: -0.20999999344348907px;
              text-align: left;
              color: #757575;
              padding: 0px 10px!important;
              input[type=checkbox] {
                appearance: none;
                background: transparent;
                position: relative;
              }
              input[type=checkbox]::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                text-align: center;
                background: #fff;
                display: block;
                pointer-events: none;
                opacity: 1;
                color: black;
                border: 1px solid #BABABA;
              }
              input[type=checkbox] + label {
                line-height: 24px;
                margin: 0 15px 0 15px;
              }
              input[type=checkbox]:hover::after {
                //content: '';
                //background: #1E80EF;
                //opacity: .3;
              }
              input[type=checkbox]:checked::after {
                content: '\2713';
                color: #fff;
                background: $primary-color;
                border: 1px solid $primary-color;
                font-size: 18px;
              }
              //input[type=checkbox]:checked:hover::after {
              //    opacity: 1;
              //}
              input[type=checkbox],
              input[type=checkbox]::after {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 20px;
                vertical-align: middle;
                border-radius: 4px;
              }
              * {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
        tbody{
          tr{
            border-bottom: 1px solid #ECF2F7;
            cursor: pointer;
            &:hover{
              background: unset;
            }
            td{
              padding: 18px 10px!important;
              line-height: 130%;
              vertical-align: middle;
              .contentTd{

              }
              .text{
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: center;
                color: #061020;
              }
              .phone{
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: left;
                color: #000000;
                margin-bottom: 0px;
              }
              .name{
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
                color: #BABABA;
                margin-bottom: 0px;
              }
              .debt{
                padding: 3px 10px 2px 10px;
                border-radius: 12px;
                background: #F32E1B;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: center;
                color: #FFFFFF;
                text-decoration: unset;
              }
              .payDebt{
                padding: 10px;
                border-radius: 12px;
                background: $primary-color;
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: center;
                color: #FFFFFF;
                text-decoration: unset;
              }
              .textSuccess{
                font-size: 12px;
                font-weight: 500;
                line-height: 14.4px;
                text-align: center;
                color: #fff;
                padding: 3px 10px 2px 10px;
                border-radius: 12px;
                background: #58C35A;
                display: block;
                width: 110px;
              }
              input[type=checkbox] {
                appearance: none;
                background: transparent;
                position: relative;
              }
              input[type=checkbox]::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                text-align: center;
                background: #fff;
                display: block;
                pointer-events: none;
                opacity: 1;
                color: black;
                border: 1px solid #BABABA;
              }
              input[type=checkbox] + label {
                line-height: 24px;
                margin: 0 15px 0 15px;
              }
              input[type=checkbox]:hover::after {
                //content: '';
                //background: #1E80EF;
                //opacity: .3;
              }
              input[type=checkbox]:checked::after {
                content: '\2713';
                color: #fff;
                background: $primary-color;
                border: 1px solid $primary-color;
                font-size: 18px;
              }
              //input[type=checkbox]:checked:hover::after {
              //    opacity: 1;
              //}
              input[type=checkbox],
              input[type=checkbox]::after {
                width: 20px;
                height: 20px;
                font-size: 20px;
                line-height: 20px;
                vertical-align: middle;
                border-radius: 4px;
              }
              .name{
                display: flex;
                gap: 3px;
                align-items: center;
                .image{
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img{
                    max-width: 100%;
                    border-radius: 50%;
                  }
                }
                .text{
                  text-align: left;
                  width: calc(100% - 32px);
                }
              }
              .info{
                display: grid;
                .code_order{
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19.36px;
                  letter-spacing: -0.029999999329447746px;
                  text-align: left;
                  color: #000000;
                  margin-bottom: 2px;
                }
                .name_customer{
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                  text-align: left;
                  color: #BABABA;
                  margin-bottom: 8px;
                }
                .price{
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                  text-align: left;
                  color: #6D727C;
                  margin-bottom: 0px;
                }
              }
            }
            .td-link{
              cursor: pointer;
            }
            &:hover{
              background: #ECF2F7;
            }
          }
        }
        .flexItem{
          display: flex;
          .image{
            display: grid;
            align-items: center;
            width: 44px;
            height: 44px;
            img{
              max-width: 100%;
              max-height: 100%;
              border-radius: 50%;
            }
          }
          .info{
            padding-left: 10px;
            width: calc(100% - 44px);
            display: grid;
            align-items: center;
            .contentInfo{
              display: grid;
              gap: 2px;
              p{
                margin-bottom: 0px;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: #BABABA;
                strong{
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19.36px;
                  letter-spacing: -0.029999999329447746px;
                  text-align: left;
                  color: #000000;
                }
              }
            }
          }
        }
        .btnEdit{
          background-color: unset!important;
          border: unset!important;
          box-shadow: unset!important;
          padding: 0px 5px;
          &:after{
            content: unset;
          }
          &:focus{
            background-color: unset!important;
            svg{
              path{
                fill: $primary-color;
              }
            }
          }
          &:active{
            background-color: unset!important;
            svg{
              path{
                fill: $primary-color;
              }
            }
          }
          &:hover{
            background-color: unset!important;
            svg{
              path{
                fill: $primary-color;
              }
            }
          }
        }
        .btn-delete{
          padding: 0px 5px;
          &:hover{
            background-color: unset!important;
            svg{
              path{
                fill: #ED3724;
              }
            }
          }
        }
        .btn-primary:focus:active, .btn-primary.disabled:focus:active{
          background-color: unset!important;
        }
      }
      .paginations{
        padding: 1rem 0px 0px;
        .pagination{
          margin-bottom: 0px;
        }
      }

    }
  }
  :where(.css-dev-only-do-not-override-14i19y2).ant-picker .ant-picker-input >input{
    font-size: 16px ;
  }
  :where(.css-dev-only-do-not-override-14i19y2).ant-picker{
    padding: 8.5px 11px 8.5px;
    background: #ECF2F7;
    border: 1px solid #ECF2F7;
  }
}
