@import "../../../../../../public/assets/css/variables.scss";
.payment-debt-page{
  .session1{
    display: flex;
    background: #FFFFFF;
    padding: 14px 32px 14px 32px;
    gap: 20px;
    align-items: center;
    position: relative;
    h1{
      font-size: 28px;
      font-weight: 600;
      line-height: 33.89px;
      letter-spacing: -1.440000057220459px;
      text-align: center;
      color: #061020;
      margin-bottom: 0px;
      line-height: 100%;
    }
    .menupos{
      position: absolute;
      top: 50%;
      right: 38px;
      transform: translate(0,-50%);
      .contentMenupos{
        position: relative;
        button{
          line-height: 100%;
          padding: 0px;
          border: unset;
          background: unset;
          box-shadow: unset;
          &:focus{
            background-color: unset!important;
          }
          &:hover{
            background-color: unset!important;
          }
          &:after{
            content: unset;
          }
          i{
            font-size: 25px;
            color: #000000;
          }
        }
        .dropdown-menu{
          top: -23px !important;
          left: -200px !important;
          box-shadow: 0px 0px 20px 0px #00000052;
          padding: 24px 20px 32px 20px;
          gap: 16px;
          border-radius: 12px;
          width: 185px;
          transform:unset!important;
          a{
            padding: 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.029999999329447746px;
            text-align: left;
            color: #061020;
            display: block;
            padding-bottom: 16px;
            border-bottom: 1px solid #ECF2F7;
            margin-bottom: 16px;
            &:hover{
              background: unset;
            }
            &:last-child{
              padding-bottom: 0px;
              border-bottom: 0px;
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .session2{
    //position: relative;
    padding: 8px 20px;
    display: flex;
    a{
      //left: 32px;
      //top: 50%;
      //transform: translate(0%,-50%);
      //position: absolute;
      padding: 12px 10px;
      background: #FFFFFF;
      border: 1px solid #ECF2F7;
      border-radius: 8px;
      text-decoration: unset;
      span{
        position: relative;
        font-size: 14px;
        font-weight: 600;
        line-height: 19.6px;
        letter-spacing: -0.20999999344348907px;
        text-align: left;
        color: #061020;
        padding-left: 20px;
        img{
          position: absolute;
          top: 4px;
          left: 0px;
        }
      }
    }
    p{
      display: grid;
      align-items: center;
      width: calc(100% - 186px);
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
      letter-spacing: -0.02em;
      text-align: center;
      color: #061020;
      margin-bottom: 0px;
    }
  }
  .session3{
    display: flex;
    .leftSession3{
      width: 300px;
      .content{
        background: #fff;
        ul{
          padding-left: 0px;
          list-style-type: none;
          li{
            list-style-type: none;
            border-bottom: 2px solid #ECF2F7;
            button{
              padding: 16px;
              height: auto!important;
              line-height: 130%;
              display: block;
              text-decoration: unset;
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                position: relative;
                padding-left: 28px;
                display: block;
                img{
                  position: absolute;
                  top: 0px;
                  left: 0px;
                }
                p{
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  display: block;
                  margin-bottom: 0px;
                }
              }
            }
            .btnModalCustomer{
              padding: 16px;
              height: auto!important;
              line-height: 130%;
              display: block;
              text-decoration: unset;
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                position: relative;
                padding-left: 28px;
                display: block;
                img{
                  position: absolute;
                  top: 0px;
                  left: 0px;
                }
                p{
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  position: relative;
                  display: block;
                  margin-bottom: 0px;
                }
              }
            }
            .btnModalCustomer{
              background: unset;
              border: unset!important;
              box-shadow: unset;
              &:hover{
                background: unset!important;
              }
              &:focus{
                background: unset!important;
              }
            }
            .btnNotePay{
              background: unset;
              border: unset!important;
              box-shadow: unset;
              &:hover{
                background: unset!important;
              }
              &:focus{
                background: unset!important;
              }
              p{
                margin-top: 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #6D727C;
                margin-bottom: 0px;
              }
              .hidden{
                display: none;
              }
            }
            .imageBank{
              padding: 16px 16px 8px;
              height: auto!important;
              line-height: 130%;
              display: block;
              text-decoration: unset;
              margin-bottom: 0px;
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                position: relative;
                display: block;
              }
            }
            .upload-form{
              padding-bottom: 16px;
              width: 90%;
              margin: auto;
              .upload{
                height: 116px!important;
                a{
                  height: 90px!important;
                  padding: 0px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img{
                    max-height: 100%;
                    max-width: 100%;
                  }
                }
              }
            }
            .hidden{
              display: none;
            }
          }
        }

      }
    }
    .centerSession3{
      width: calc(100% - 600px);
      .contentFull{
        border: 2px solid #BABABA;
        .content1{
          height: 120px;
          background: #fff;
          display: grid;
          align-items: center;
          border-bottom: 2px solid #BABABA;
          .nd{
            .priceTotal{
              font-size: 48px;
              font-weight: 500;
              line-height: 55.2px;
              letter-spacing: -1.440000057220459px;
              text-align: center;
              color: #088C3D;
              margin-bottom: 0px;
            }
            .notePay{
              margin-bottom: 0px;
              font-size: 16px;
              font-weight: 500;
              line-height: 19.36px;
              letter-spacing: -0.029999999329447746px;
              text-align: center;
              color: #061020;
            }
          }
          .nd2{
            padding: 32px 20px;
            display: flex;
            .leftNd{
              width: 50%;
              .text1{
                margin-bottom: 0px;
                font-size: 20px;
                font-weight: 500;
                line-height: 24.2px;
                letter-spacing: -0.02em;
                text-align: left;
                color: #061020;
                margin-bottom: 8px;
              }
              .text2{
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.03200000151991844px;
                text-align: left;
                color: #061020;
              }
            }
            .rightNd{
              width: 50%;
              .text3{
                margin-bottom: 0px;
                font-size: 20px;
                font-weight: 500;
                line-height: 24.2px;
                letter-spacing: -0.02em;
                text-align: right;
                color: #061020;
              }
            }
          }
          .hidden{
            display: none;
          }
        }
        .content2{
          height: calc(100vh - 306.19px);
          background: #ECF2F7;
          .listNumber{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 2px;
            height: 100%;
            align-items: center;
            justify-content: center;
            button{
              border: unset;
              display: grid;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-weight: 400;
              line-height: 29.05px;
              letter-spacing: -1.440000057220459px;
              text-align: left;
              color: #061020;
              height: 100%;
              background: #fff;
              text-decoration: unset;
            }
            .payClick{
              background: $primary-color;
              color: #fff;
            }
          }
        }
      }

    }
    .rightSession3{
      width: 300px;
      .content{
        background: #fff;
        ul{
          padding-left: 0px;
          list-style-type: none;
          margin-bottom: 0px;
          li{
            list-style-type: none;
            border-bottom: 2px solid #ECF2F7;
            p{
              margin-bottom: 0px;
              font-size: 14px;
              font-weight: 600;
              line-height: 19.6px;
              letter-spacing: -0.20999999344348907px;
              text-align: left;
              color: #061020;
              padding: 16px 16px;
            }
            button{
              border: unset;
              width: 100%;
              background: unset;
              height: auto;
              padding: 16px;
              height: auto!important;
              line-height: 130%;
              display: block;
              text-decoration: unset;
              span{
                font-size: 14px;
                font-weight: 400;
                line-height: 19.6px;
                letter-spacing: -0.2800000011920929px;
                text-align: left;
                color: #061020;
                position: relative;
                padding-left: 28px;
                display: block;
                img{
                  position: absolute;
                  top: 0px;
                  left: 0px;
                }
              }
            }
            .summary{
              font-size: 14px;
              font-weight: 600;
              line-height: 19.6px;
              letter-spacing: -0.20999999344348907px;
              text-align: left;
              color: #061020;
              padding-left: 0px;
            }
          }
        }
        #contentPayType{
          height: calc(100vh - 400.57px);
          overflow: auto;
          display: block;

          gap: 2px;
          //background: #ECF2F7;
          .item{
            display: flex;
            padding: 28px 20px;
            align-items: center;
            background: #fff;
            border-bottom: 2px solid #ECF2F7;
            p{
              margin-bottom: 0px;
            }
            .text{
              width: 90px;
              font-size: 14px;
              font-weight: 400;
              line-height: 130%;
              letter-spacing: -0.2800000011920929px;
              text-align: left;
              color: #061020;
              display: grid;
              height: 100%;
              align-items: center;
            }
            .text-mobile{
              display: none;
            }
            .price{
              height: 100%;
              width: calc(100% - 138px);
              color: #061020;
              font-size: 14px;
              font-weight: 400;
              line-height: 130%;
              letter-spacing: -0.2800000011920929px;
              text-align: right;
              display: grid;
              align-items: center;
            }
            .delete{
              background: unset;
              border: unset;
              width: 48px;
              text-align: right;
            }
          }
          .itemActive{
            background: #ECF2F7;
          }
        }
      }
    }
  }
  #notePay{
    height: 100vh;
    overflow: hidden;
    .modal-dialog{
      height: 100vh;
      overflow: hidden;
      width: 800px;
      max-width: 800px;
      padding-top: 1rem;
      .modal-content{
        border-radius: 20px;
        .modal-body{
          position: relative;
          padding: 0px;
          .close{
            position: relative;
            top: 34px;
            right: 34px;
            opacity: 1;
            &:focus{
              outline: unset;
            }
          }
          .contentModal{
            padding: 32px 40px;
            .headerModel{
              grid-template-columns: 1fr;
              gap: 20px;
              display: grid;
              border-bottom: 1px solid #ECF2F7;
              padding-bottom: 24px;
              margin-bottom: 24px;
              p{
                font-size: 24px;
                font-weight: 600;
                line-height: 29.05px;
                letter-spacing: -1.440000057220459px;
                text-align: left;
                color: #000000;
                margin-bottom: 0px;
              }
            }
            .contentCustomer{
              display: grid;
              gap: 24px;
              padding-bottom: 24px;
              margin-bottom: 24px;
              border-bottom: 1px solid #ECF2F7;
              .inlineForm{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px;
              }
              .form-customer{
                position: relative;
                .error{
                  position: absolute;
                  bottom: -22px;
                  left: 0px;
                  color: #FF0000;
                  margin-bottom: 0px;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                }
                .hidden{
                  display: none;
                }
                label{
                  display: block;
                  margin-bottom: 8px;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 19.6px;
                  letter-spacing: -0.2800000011920929px;
                  text-align: left;
                  color: #061020;
                  span{
                    color: #FF0000;
                  }

                }
                textarea{
                  border: 1px solid #ECF2F7;
                  background: #F6F9FC;
                  height: 200px;
                  border-radius: 8px;
                  padding: 12px;
                  resize: unset;
                  width: 100%;
                  &:focus{
                    outline: unset;
                  }
                }
              }
            }
            .footerCustomer{
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 12px;
              .btnCloseNote{
                text-decoration: unset;
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: left;
                color: #FFFFFF;
                background: #BABABA;
                border-radius: 100px;
              }
              .btnAddNotePay{
                text-decoration: unset;
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: left;
                color: #FFFFFF;
                background: $primary-color;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
  }
  #vietQr{
    height: 100vh;
    overflow: hidden;
    .modal-dialog{
      height: 100vh;
      overflow: hidden;
      width: 800px;
      max-width: 800px;
      padding-top: 1rem;
      .modal-content{
        border-radius: 20px;
        .modal-body{
          position: relative;
          padding: 0px;
          .close{
            position: relative;
            top: 34px;
            right: 34px;
            opacity: 1;
            &:focus{
              outline: unset;
            }
          }
          .contentModal{
            padding: 32px 40px;
            .headerModel{
              grid-template-columns: 1fr;
              gap: 20px;
              display: grid;
              border-bottom: 1px solid #ECF2F7;
              padding-bottom: 24px;
              margin-bottom: 24px;
              p{
                font-size: 24px;
                font-weight: 600;
                line-height: 29.05px;
                letter-spacing: -1.440000057220459px;
                text-align: left;
                color: #000000;
                margin-bottom: 0px;
              }
            }
            .contentVietAr{
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 24px;
              padding-bottom: 24px;
              margin-bottom: 24px;
              border-bottom: 1px solid #ECF2F7;
              height: 400px;

              img{
                max-height: 100%;
                max-width: 100%;
              }
            }
            .footerCustomer{
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 12px;
              .btnCloseVietQr{
                text-decoration: unset;
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: left;
                color: #FFFFFF;
                background: #BABABA;
                border-radius: 100px;
              }
              .btnSave{
                text-decoration: unset;
                width: 100%;
                height: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                letter-spacing: -0.029999999329447746px;
                text-align: left;
                color: #FFFFFF;
                background: $primary-color;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
  }
  .modalNote{
    .ant-modal-content{
      padding: 32px 40px;
      border-radius: 20px;
      .ant-modal-close{
        top: 33px;
        right: 42px;
        .ant-modal-close-x{
          font-size: 26px;
          svg{
            path{
              fill: #000;
            }
            width: 26px;
          }
        }
      }
      .ant-modal-header{
        .ant-modal-title{
          font-size: 24px;
          font-weight: 500;
          line-height: 29.05px;
          letter-spacing: -1.4400000572px;
          text-align: left;
          color: #000000;
          padding: 0px;
          text-transform: unset!important;
        }
        border-bottom: 1px solid #ECF2F7;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
      .ant-modal-body{

        .item-form{
          border: unset;
          padding: 0px;
        }
        .form-modal{
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid #ECF2F7;
          textarea{
            border: 1px solid #ECF2F7;
            background: #F6F9FC;
            height: 200px;
            border-radius: 8px;
            padding: 12px;
            resize: unset;
            width: 100%;
          }
        }
        .form-modal-image{
          width: 250px;
          margin: 0px auto 24px;
          img{
            max-width: 100%;
          }
        }
        .footer-note{
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
          .btnCloseNote{
            text-decoration: unset;
            width: 100%;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            text-align: left;
            color: #FFFFFF;
            background: #BABABA;
            border-radius: 100px;
          }
          .btnAddNotePay{
            text-decoration: unset;
            width: 100%;
            height: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            letter-spacing: -0.0299999993px;
            text-align: left;
            color: #FFFFFF;
            background: $primary-color;
            border-radius: 100px;
          }
        }
      }
    }
  }
}
@media screen and (max-width:992px){
  .payment-debt-page .session3 {
    .leftSession3{
      width: 200px;
      background: #fff;
      .content ul li .btnModalCustomer{
        width: 100%;
      }
    }
    .rightSession3{
      width: 200px;
      .content ul li p{
        padding: 16px 5px;
      }
      .content #contentPayType .item{
        padding: 28px 5px;
        .text{
          font-size: 12px;
          width: 80px;
          display: none;
        }

        .text-mobile{
          display: block;
          width: 32px;
        }
        .delete{
          width: 38px;
        }
        .price{
          width: calc(100% - 70px);
          font-size: 12px;
          overflow: hidden;
        }
      }
    }
    .centerSession3{
      width: calc(100% - 400px);
      .contentFull {
        .content1 {
          .nd {
            .priceTotal{
              font-size: 32px;
            }
            .notePay{
              font-size: 14px ;
            }
          }
          .nd2 {
            padding: 0px 10px;
            display: grid;
            .leftNd {
              width: 100%;
              .text1{
                font-size: 14px;
                margin-bottom: 0px  ;
              }
            }
            .rightNd {
              width: 100%;
              .text3{
                font-size: 14px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .payment-debt-page .session3 .centerSession3 .contentFull .content2 .listNumber button{
    font-size: 18px ;
  }
}