.ModalAddEditCustomer{
    .ant-modal-content{
        padding: 10px 30px 20px;
    }
    .ant-modal-title{
        border-bottom: 1px solid #ECF2F7;
    }
    .ant-modal-footer{
        padding-top: 10px;
        border-top: 1px solid #ECF2F7;
    }
    .custom-ok-button{
        text-decoration: unset;
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 19.36px;
        letter-spacing: -0.0299999993px;
        text-align: left;
        color: #FFFFFF;
        background: #0E72ED;
        border-radius: 4px;
        border: none;
        outline: none;
        
    }
    .divider-col2{
        display: flex;
        align-items: center;
        column-gap: 24px;
    }
    .disabled-modal{
        pointer-events: none;
    }
}