.product-variant{
  .mainContentCreateProduct .form-create-product .session-create{
    display: block;
    .session-create-left{
      width: 100%;
    }
    .session-create-right{
      width: 100%;
    }
  }
}
.btn-printPro{
    box-shadow: 0 2px 6px #ffc473;
    background-color: #ffa426;
    border-color: #ffa426;
    color: #fff;
    margin-left: 8px;
    &.deActive{
      pointer-events: none;
      background-color: gray;
      border-color: gray;
      box-shadow: 0 2px 6px #fff;
    }
  &:hover{
    background-color: #ffa426 !important;
    border-color: #ffa426 !important;
    color: #fff !important;
    opacity: 80%;
  }
}

.print-componentPro{
  .print-container{
    &.nonePrint{
      display: none;
    }
  
    @media print {
      &.nonePrint {
          display: block;
      }
    }
      padding: 30px;
      background-color: #FFF;
      .print-date{
        margin-bottom: 20px;
      }
    .qrcode-container{
        display: flex;
        align-items: center;
        gap: 25px;
        flex-wrap: wrap;
        margin-bottom: 30px;
  
      .Boximage-qrcode{
        width: 100px;
        height: 100px;
        img{
          width: 100%;
          height: 100%;
          border-radius: 2px;
        }
      }
    }
    .barcode-container{
      display: flex;
      align-items: center;
      row-gap: 50px;
      column-gap: 25px;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
}
@page {
  size: auto;
  margin: 12mm;
}