.blueColor {
    background: #00a3ff;
}
.redColor {
    background: #f32e1b;
}
.greenColor {
    background-color: #58c35a;
}

.DetailOrderPosContainer {
    .filter-search {
        width: 310px;
        border-radius: 8px;
        background: #fff;
        display: flex;
        padding: 4px;
        input {
            width: calc(100% - 36px);
            border: unset;
            border: unset;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            letter-spacing: -0.2800000011920929px;
            text-align: left;
            color: #061020;
            padding-left: 10px;
            &:focus {
                outline: unset;
            }
        }
        button {
            width: 36px;
            background: #0e72ed;
            height: 36px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: unset;
        }
    }

    .BoxFilterSessionDetails .left .ant-picker {
        margin-bottom: 3px;
    }

    .BoxFilterSessionDetails .left .item-selected .ant-select {
        height: 43px;
        .ant-select-selector {
            padding: 0 27.8px;
        }
    }

    .contentListPosOrder {
        .table-responsive {
            .table {
                thead {
                    tr {
                        th {
                            padding-left: 10px;
                            padding-right: 10px;
                            align-content: center;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            .type {
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 14.4px;
                                text-align: center;
                                color: #fff;
                                padding: 4px 10px 4px 10px;
                                gap: 4px;
                                border-radius: 12px;
                                width: 85px;
                                white-space: nowrap;
                            }
                            .btn-see-more {
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 14.4px;
                                text-align: center;
                                color: #fff;
                                padding: 4px 14px 4px 14px;
                                gap: 4px;
                                border-radius: 12px;
                                white-space: nowrap;
                                background-color: #0e72ed;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                            }
                            &:last-child {
                                text-align: -webkit-center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ViewDetailOrderPosContainer {
    background-color: white;
    padding: 20px;
    .title {
        line-height: 44px;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .leftViewDetail {
        overflow: auto;
        .LayoutSessionDetails {
            padding: 0;
            display: inline-block;
            .title {
                display: none;
            }
            .DetailOrderPosContainer {
                .BoxFilterSessionDetails {
                    padding: 8px 0;
                    gap: 6px;
                    flex-direction: column;
                    align-items: flex-start;
                    background-color: #f4f6f9;
                    padding: 12px;
                    .right {
                        .remove-filter {
                            span {
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .contentListPosOrder {
                    .table-responsive {
                        height: 420px;
                        overflow-y: auto;
                        .table {
                            thead {
                                tr {
                                    th {
                                        // padding-bottom: 21px;
                                        height: 60px;
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .LayoutSessionDetails-head {
                background-color: #f4f6f9;
                .LayoutSessionDetails-back {
                    background-color: white;
                }
            }
        }
    }

    .rightViewDetail {
        padding: 0 20px;
        height: 600px;
        overflow-y: auto;

        .itemViewOrderPos {
            background-color: white;
            border-bottom: 1px solid #ccc;
            padding: 12px 0;
            .leftItem {
                .name {
                    font-weight: 600;
                }
                .detail {
                    margin-bottom: 0;
                }
            }

            .rightItem {
                font-weight: 600;
                font-size: 16px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.totalAmount {
    text-align: right;
    padding: 0 40px;
    background-color: white;
    .VAT {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        color: #6d727c;
    }
    .total {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
    }
}
