.payment-separation-container {
  display: flex;
  flex-direction: column;
}
.payment-separation-container .payment-separation-body .separation-title {
  display: flex;
  padding: 8px 20px;
  align-items: center;
}
.payment-separation-container .payment-separation-body .separation-title .button-return .ant-btn {
  background-color: #FFFFFF;
  width: 120px;
  height: 45px;
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: center;
  transition: background-color 0.3s;
}
.payment-separation-container .payment-separation-body .separation-title .button-return .ant-btn:hover {
  background-color: #0E72ED;
}
.payment-separation-container .payment-separation-body .separation-title .title-page {
  width: calc(100% - 186px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
.payment-separation-container .payment-separation-body .session3 {
  display: flex;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 {
  width: 300px;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .leftSession3 {
    width: 200px;
    background: #fff;
  }
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content {
  background: #fff;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .leftSession3 .content {
    width: 100%;
  }
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul {
  padding-left: 0px;
  list-style-type: none;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li {
  list-style-type: none;
  border-bottom: 2px solid #ECF2F7;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li button {
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li button span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li button span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li button span p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer {
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer {
    width: 100%;
  }
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer span p {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer {
  background: unset;
  border: unset !important;
  box-shadow: unset;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer:hover {
  background: unset !important;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnModalCustomer:focus {
  background: unset !important;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnNotePay {
  background: unset;
  border: unset !important;
  box-shadow: unset;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnNotePay:hover {
  background: unset !important;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnNotePay:focus {
  background: unset !important;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnNotePay p {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #6D727C;
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .btnNotePay .hidden {
  display: none;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .imageBank {
  padding: 16px 16px 8px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .imageBank span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  display: block;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .upload-form {
  padding-bottom: 16px;
  width: 90%;
  margin: auto;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .upload-form .upload {
  height: 116px !important;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .upload-form .upload a {
  height: 90px !important;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .upload-form .upload a img {
  max-height: 100%;
  max-width: 100%;
}
.payment-separation-container .payment-separation-body .session3 .leftSession3 .content ul li .hidden {
  display: none;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 {
  width: calc(100% - 600px);
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 {
    width: calc(100% - 400px);
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull {
  border: 2px solid #BABABA;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 {
  height: 120px;
  background: #fff;
  display: grid;
  align-items: center;
  border-bottom: 2px solid #BABABA;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd .priceTotal {
  font-size: 48px;
  font-weight: 500;
  line-height: 55.2px;
  letter-spacing: -1.4400000572px;
  text-align: center;
  color: #088C3D;
  margin-bottom: 0px;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd .priceTotal {
    font-size: 32px;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd .notePay {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.0299999993px;
  text-align: center;
  color: #061020;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd .notePay {
    font-size: 14px;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 {
  padding: 32px 20px;
  display: flex;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 {
    padding: 0px 10px;
    display: grid;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd {
    width: 100%;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text1 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #061020;
  margin-bottom: 8px;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text1 {
    font-size: 14px;
    margin-bottom: 0px;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text2 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.0320000015px;
  text-align: left;
  color: #061020;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd {
  width: 50%;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd {
    width: 100%;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd .text3 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: right;
  color: #061020;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd .text3 {
    font-size: 14px;
    text-align: left;
  }
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content1 .hidden {
  display: none;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content2 {
  height: calc(100vh - 306.19px);
  background: #ECF2F7;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content2 .listNumber {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content2 .listNumber button {
  border: unset;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29.05px;
  letter-spacing: -1.4400000572px;
  text-align: left;
  color: #061020;
  height: 100%;
  background: #fff;
  text-decoration: unset;
}
.payment-separation-container .payment-separation-body .session3 .centerSession3 .contentFull .content2 .listNumber .payClick {
  background: #0E72ED;
  color: #fff;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 {
  width: 300px;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 {
    width: 200px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content {
  background: #fff;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li {
  list-style-type: none;
  border-bottom: 2px solid #ECF2F7;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #061020;
  padding: 16px 16px;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li p {
    padding: 16px 5px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li button {
  border: unset;
  width: 100%;
  background: unset;
  height: auto;
  padding: 16px;
  height: auto !important;
  line-height: 130%;
  display: block;
  text-decoration: unset;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li button span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  position: relative;
  padding-left: 28px;
  display: block;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li button span img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content ul li .summary {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: -0.2099999934px;
  text-align: left;
  color: #061020;
  padding-left: 0px;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType {
  height: calc(100vh - 400.57px);
  overflow: auto;
  display: block;
  gap: 2px;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item {
  display: flex;
  padding: 28px 20px;
  align-items: center;
  background: #fff;
  border-bottom: 2px solid #ECF2F7;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item {
    padding: 28px 5px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item p {
  margin-bottom: 0px;
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .text {
  width: 90px;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  display: grid;
  height: 100%;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .text {
    font-size: 12px;
    width: 20px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .text-mobile {
  display: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.2800000012px;
  text-align: left;
  color: #061020;
  display: grid;
  height: 100%;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .text-mobile {
    display: block;
    width: 32px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .price {
  height: 100%;
  width: calc(100% - 138px);
  color: #061020;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.2800000012px;
  text-align: right;
  display: grid;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .price {
    width: calc(100% - 70px);
    font-size: 12px;
    overflow: hidden;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .delete {
  background: unset;
  border: unset;
  width: 48px;
  text-align: right;
}
@media screen and (max-width: 992px) {
  .payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .item .delete {
    width: 38px;
  }
}
.payment-separation-container .payment-separation-body .session3 .rightSession3 .content #contentPayType .itemActive {
  background: #ECF2F7;
}

@media screen and (max-width: 992px) {
  .payment .session3 .leftSession3 {
    width: 200px;
    background: #fff;
  }
  .payment .session3 .leftSession3 .content ul li .btnModalCustomer {
    width: 100%;
  }
  .payment .session3 .rightSession3 {
    width: 200px;
  }
  .payment .session3 .rightSession3 .content ul li p {
    padding: 16px 5px;
  }
  .payment .session3 .rightSession3 .content #contentPayType .item {
    padding: 28px 5px;
  }
  .payment .session3 .rightSession3 .content #contentPayType .item .text {
    font-size: 12px;
    width: 80px;
    display: none;
  }
  .payment .session3 .rightSession3 .content #contentPayType .item .text-mobile {
    display: block;
    width: 32px;
  }
  .payment .session3 .rightSession3 .content #contentPayType .item .delete {
    width: 38px;
  }
  .payment .session3 .rightSession3 .content #contentPayType .item .price {
    width: calc(100% - 70px);
    font-size: 12px;
    overflow: hidden;
  }
  .payment .session3 .centerSession3 {
    width: calc(100% - 400px);
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd .priceTotal {
    font-size: 32px;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd .notePay {
    font-size: 14px;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd2 {
    padding: 0px 10px;
    display: grid;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd {
    width: 100%;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd2 .leftNd .text1 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd {
    width: 100%;
  }
  .payment .session3 .centerSession3 .contentFull .content1 .nd2 .rightNd .text3 {
    font-size: 14px;
    text-align: left;
  }
  .payment .session3 .centerSession3 .contentFull .content2 .listNumber button {
    font-size: 18px;
  }
}

